import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { BigNumber, Contract } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import config from 'src/adrastia.config';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatUnits, parseUnits, commify } from '@ethersproject/units';
import { Box } from '@mui/material';
import { useContractFunction, useEthers } from '@usedapp/core';
import { ManagedSlopedOracleMutationComputer } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import {
  bigNumberMaxValidator,
  bigNumberMinValidator,
  bigNumberValidator,
} from 'src/forms/validation/big-number';
import { default as abi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/ManagedSlopedOracleMutationComputer.sol/ManagedSlopedOracleMutationComputer.json';
import {
  MAX_BASE,
  MAX_KINK,
  MAX_SLOPE,
  MIN_BASE,
  MIN_KINK,
  MIN_SLOPE,
} from 'src/constants/sloped-oracle-mutation-computer';
import { Interface } from '@ethersproject/abi';
import KinkedSlopeChart from 'src/components/KinkedSlopeChart';

const contractInterface = new Interface(abi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type CurrentValues = {
  base?: BigNumber;
  baseSlope?: BigNumber;
  kink?: BigNumber;
  kinkSlope?: BigNumber;
};

type SetSlopeConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  contractAddress: string;
  token: string;
  inputDecimals: number;
  inputRatePrefix?: string;
  inputRateSuffix?: string;
  decimals: number;
  ratePrefix?: string;
  rateSuffix?: string;
  defaultOneXScalar: BigNumber;
  scalar: BigNumber;
  values?: CurrentValues;
};

const steps = ['Define parameters', 'Review and submit'];

export default function SetSlopeConfigDialog({
  dialogOpen,
  networkName,
  contractAddress,
  token,
  inputDecimals,
  inputRatePrefix,
  inputRateSuffix,
  decimals,
  ratePrefix,
  rateSuffix,
  defaultOneXScalar,
  scalar,
  values,
}: SetSlopeConfigDialogProps) {
  const theme = useTheme();

  const contract = useMemo(() => {
    return new Contract(contractAddress, contractInterface) as ManagedSlopedOracleMutationComputer;
  }, [contractAddress]);

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const setSlopeConfig = useContractFunction(contract, 'setSlopeConfig');

  const { enqueueSnackbar } = useSnackbar();

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [base, setBase] = useState<BigNumber>();
  const [baseSlope, setBaseSlope] = useState<BigNumber>();
  const [kink, setKink] = useState<BigNumber>();
  const [kinkSlope, setKinkSlope] = useState<BigNumber>();

  var baseDecimals = decimals;

  if (!scalar?.eq(0)) {
    if (scalar.lt(defaultOneXScalar)) {
      const scalarDec = Math.floor(Math.log10(scalar.toNumber()));
      const defaultOneXScalarDec = Math.floor(Math.log10(defaultOneXScalar.toNumber()));

      baseDecimals = decimals + (defaultOneXScalarDec - scalarDec); // Scale up decimals b/c values will later be scaled down
    } else if (scalar.gt(defaultOneXScalar)) {
      const scalarDec = Math.floor(Math.log10(scalar.toNumber()));
      const defaultOneXScalarDec = Math.floor(Math.log10(defaultOneXScalar.toNumber()));

      baseDecimals = decimals - (scalarDec - defaultOneXScalarDec); // Scale down decimals b/c values will later be scaled up
    }
  }

  const slopeDecimals = baseDecimals - inputDecimals;

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          base: Yup.mixed()
            .test(
              'bigNumber',
              'Base should be a number with at most ' + baseDecimals + ' decimal places.',
              bigNumberValidator(baseDecimals)
            )
            .test(
              'min',
              'Base cannot be less than ' + formatUnits(MIN_BASE, baseDecimals) + '.',
              bigNumberMinValidator(MIN_BASE, baseDecimals)
            )
            .test(
              'max',
              'Base cannot be greater than ' + formatUnits(MAX_BASE, baseDecimals) + '.',
              bigNumberMaxValidator(MAX_BASE, baseDecimals)
            )
            .notOneOf([''], 'Base is required.')
            .required('Base is required.'),
          baseSlope: Yup.mixed()
            .test(
              'bigNumber',
              'Base slope should be a number with at most ' + slopeDecimals + ' decimal places.',
              bigNumberValidator(slopeDecimals)
            )
            .test(
              'min',
              'Base slope cannot be less than ' + formatUnits(MIN_SLOPE, slopeDecimals) + '.',
              bigNumberMinValidator(MIN_SLOPE, slopeDecimals)
            )
            .test(
              'max',
              'Base slope cannot be greater than ' + formatUnits(MAX_SLOPE, slopeDecimals) + '.',
              bigNumberMaxValidator(MAX_SLOPE, slopeDecimals)
            )
            .notOneOf([''], 'Base slope is required.')
            .required('Base slope is required.'),
          kinkSlope: Yup.mixed()
            .test(
              'bigNumber',
              'Kink slope should be a number with at most ' + slopeDecimals + ' decimal places.',
              bigNumberValidator(slopeDecimals)
            )
            .test(
              'min',
              'Kink slope cannot be less than ' + formatUnits(MIN_SLOPE, slopeDecimals) + '.',
              bigNumberMinValidator(MIN_SLOPE, slopeDecimals)
            )
            .test(
              'max',
              'Kink slope cannot be greater than ' + formatUnits(MAX_SLOPE, slopeDecimals) + '.',
              bigNumberMaxValidator(MAX_SLOPE, slopeDecimals)
            )
            .notOneOf([''], 'Kink slope is required.')
            .required('Kink slope is required.'),
          kink: Yup.mixed()
            .test(
              'bigNumber',
              'Kink should be a number with at most ' + inputDecimals + ' decimal places.',
              bigNumberValidator(inputDecimals)
            )
            .test(
              'min',
              'Kink cannot be less than ' + formatUnits(MIN_KINK, inputDecimals) + '.',
              bigNumberMinValidator(MIN_KINK, inputDecimals)
            )
            .test(
              'max',
              'Kink cannot be greater than ' + formatUnits(MAX_KINK, inputDecimals) + '.',
              bigNumberMaxValidator(MAX_KINK, inputDecimals)
            )
            .notOneOf([''], 'Kink is required.')
            .required('Kink is required.'),
        })
        .test('something-changes', 'No changes detected.', (formValues) => {
          var newBase;
          try {
            newBase =
              formValues.base != null
                ? parseUnits(formValues.base.toString(), baseDecimals)
                : undefined;
          } catch (e) {
            // Our BigNumberValidator will catch this
            return true;
          }

          var newBaseSlope;
          try {
            newBaseSlope =
              formValues.baseSlope != null
                ? parseUnits(formValues.baseSlope.toString(), slopeDecimals)
                : undefined;
          } catch (e) {
            // Our BigNumberValidator will catch this
            return true;
          }

          var newKinkSlope;
          try {
            newKinkSlope =
              formValues.kinkSlope != null
                ? parseUnits(formValues.kinkSlope.toString(), slopeDecimals)
                : undefined;
          } catch (e) {
            // Our BigNumberValidator will catch this
            return true;
          }

          var newKink;
          try {
            newKink =
              formValues.kink != null
                ? parseUnits(formValues.kink.toString(), inputDecimals)
                : undefined;
          } catch (e) {
            // Our BigNumberValidator will catch this
            return true;
          }

          if (newBase == null || newBaseSlope == null || newKinkSlope == null || newKink == null) {
            // Our BigNumberValidator will catch this
            return true;
          }

          if (
            values?.base == null ||
            values?.baseSlope == null ||
            values?.kinkSlope == null ||
            values?.kink == null
          ) {
            return true;
          }

          return (
            !newBase.eq(values.base) ||
            !newBaseSlope.eq(values.baseSlope) ||
            !newKinkSlope.eq(values.kinkSlope) ||
            !newKink.eq(values.kink)
          );
        })
        .required(),
    [baseDecimals, inputDecimals, slopeDecimals]
  );
  const defaultValues = useMemo(
    () => ({
      base: formatUnits(values?.base ?? 0, baseDecimals),
      baseSlope: formatUnits(values?.baseSlope ?? 0, slopeDecimals),
      kinkSlope: formatUnits(values?.kinkSlope ?? 0, slopeDecimals),
      kink: formatUnits(values?.kink ?? 0, inputDecimals),
    }),
    [values, baseDecimals, inputDecimals, slopeDecimals]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const base = parseUnits(data.base.toString(), baseDecimals);
    const baseSlope = parseUnits(data.baseSlope.toString(), slopeDecimals);
    const kinkSlope = parseUnits(data.kinkSlope.toString(), slopeDecimals);
    const kink = parseUnits(data.kink.toString(), inputDecimals);

    // Set the values
    setBase(base);
    setBaseSlope(baseSlope);
    setKinkSlope(kinkSlope);
    setKink(kink);

    handleNext();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      setSlopeConfig.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && setSlopeConfig?.state?.status === 'Mining') {
      setSlopeConfig.resetState();

      dialogOpen.onFalse();
    }
  }, [setSlopeConfig?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Set slope config</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  <Alert severity="warning" variant="outlined">
                    These values are relative to the rate config scalar. Avoid changing the scalar
                    after setting these values.
                  </Alert>
                  <Alert severity="info" variant="outlined">
                    Note that the sloped value is calculated before applying the rate config.
                  </Alert>
                </Box>
                <RHFTextField
                  fullWidth
                  name="base"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Base"
                  helperText="The base rate (y-intercept)."
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="baseSlope"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Base slope"
                  helperText="The base slope."
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="kinkSlope"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Kink slope"
                  helperText="The additional slope after the kink."
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="kink"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Kink"
                  helperText="The kink point (x-coordinate) where the kink slope is applied."
                  InputProps={{
                    startAdornment:
                      inputRatePrefix != null ? (
                        <InputAdornment position="start">{inputRatePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {inputRateSuffix && (
                          <InputAdornment position="end">{inputRateSuffix}</InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />

                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>Base</TableCell>
                      <TableCell>{base?.toString()}</TableCell>
                      <TableCell>
                        {(ratePrefix ?? '') +
                          commify(formatUnits(base ?? 0, baseDecimals)) +
                          (rateSuffix ?? '')}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Base slope</TableCell>
                      <TableCell>{baseSlope?.toString()}</TableCell>
                      <TableCell>
                        {(ratePrefix ?? '') +
                          commify(formatUnits(baseSlope ?? 0, slopeDecimals)) +
                          (rateSuffix ?? '')}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Kink slope</TableCell>
                      <TableCell>{kinkSlope?.toString()}</TableCell>
                      <TableCell>
                        {(ratePrefix ?? '') +
                          commify(formatUnits(kinkSlope ?? 0, slopeDecimals)) +
                          (rateSuffix ?? '')}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Kink</TableCell>
                      <TableCell>{kink?.toString()}</TableCell>
                      <TableCell>
                        {(inputRatePrefix ?? '') +
                          commify(formatUnits(kink ?? 0, inputDecimals)) +
                          (inputRateSuffix ?? '')}
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Box marginTop={theme.spacing(4)} marginBottom={theme.spacing(4)}>
                  <KinkedSlopeChart
                    base={Number(formatUnits(base ?? 0, baseDecimals))}
                    baseSlope={Number(formatUnits(baseSlope ?? 0, slopeDecimals))}
                    kink={Number(formatUnits(kink ?? 0, inputDecimals))}
                    kinkSlope={Number(formatUnits(kinkSlope ?? 0, slopeDecimals))}
                    xLabelPrefix={inputRatePrefix}
                    xLabelSuffix={inputRateSuffix}
                    yLabelPrefix={ratePrefix}
                    yLabelSuffix={rateSuffix}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          setSlopeConfig.send(token, base!, baseSlope!, kink!, kinkSlope!);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
