import { parseUnits } from '@ethersproject/units';
import { BigNumberish } from 'ethers';

export function bigNumberValidator(decimals: number) {
  return (value: any) => {
    if (value === '') {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      parseUnits(value.toString(), decimals);

      return true;
    } catch (error) {
      return false;
    }
  };
}

export function bigNumberMinValidator(min: BigNumberish, decimals: number) {
  return (value: any) => {
    if (value === '') {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      const bn = parseUnits(value.toString(), decimals);

      return bn.gte(min);
    } catch (error) {
      return false;
    }
  };
}

export function bigNumberMaxValidator(max: BigNumberish, decimals: number) {
  return (value: any) => {
    if (value === '') {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      const bn = parseUnits(value.toString(), decimals);

      return bn.lte(max);
    } catch (error) {
      return false;
    }
  };
}

export function bigNumberEqualsValidator(max: BigNumberish, decimals: number) {
  return (value: any) => {
    if (value === '') {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      const bn = parseUnits(value.toString(), decimals);

      return bn.eq(max);
    } catch (error) {
      return false;
    }
  };
}

export function bigNumberNotEqualsValidator(max: BigNumberish, decimals: number) {
  return (value: any) => {
    if (value === '') {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      const bn = parseUnits(value.toString(), decimals);

      return !bn.eq(max);
    } catch (error) {
      return false;
    }
  };
}
