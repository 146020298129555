import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps, Link, Typography, useTheme } from '@mui/material';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  titleOverride?: string;
  desc?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  contentTitle?: string;
  contentSubtitle?: string;
  contentSubtitleLink?: string;
  contentSubtitleLinkTarget?: string;
}

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  subtitleLink?: string;
  subtitleLinkTarget?: string;
}

interface PageContentProps {
  children: ReactNode;
}

function PageHeader({ title, subtitle, subtitleLink, subtitleLinkTarget }: PageHeaderProps) {
  const theme = useTheme();

  return (
    <>
      {title && (
        <header>
          <Box
            sx={{
              paddingTop: theme.spacing(8),
              paddingBottom: theme.spacing(8),
              backgroundColor: theme.palette.background.pageHeader,
              color: theme.palette.primary.contrastText,
              marginBottom: theme.spacing(4),
            }}
          >
            <Typography variant="h1" align="center">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle1" align="center">
                {subtitleLink ? (
                  <Link
                    href={subtitleLink}
                    target={subtitleLinkTarget}
                    sx={{
                      color: 'inherit',
                    }}
                    component={RouterLink}
                  >
                    {subtitle}
                  </Link>
                ) : (
                  <>{subtitle}</>
                )}
              </Typography>
            )}
          </Box>
        </header>
      )}
    </>
  );
}

function PageContent({ children }: PageContentProps) {
  return <Box sx={{ paddingTop: 0, paddingBottom: 0 }}>{children}</Box>;
}

const Page = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      title,
      titleOverride,
      desc,
      keywords,
      ogTitle,
      ogDescription,
      ogImage,
      contentTitle,
      contentSubtitle,
      contentSubtitleLink,
      contentSubtitleLinkTarget,
      meta,
      ...other
    },
    ref
  ) => (
    <>
      <Helmet>
        <title>{titleOverride || `${title} | Adrastia`}</title>
        {desc && <meta name="description" content={desc} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        <PageHeader
          title={contentTitle}
          subtitle={contentSubtitle}
          subtitleLink={contentSubtitleLink}
          subtitleLinkTarget={contentSubtitleLinkTarget}
        />
        <PageContent>{children}</PageContent>
      </Box>
    </>
  )
);

export default Page;
