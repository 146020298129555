import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { BigNumber, Contract } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import config, { DataPointsConfig, prudentiaGenericDataPoints } from 'src/adrastia.config';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatUnits, parseUnits, commify } from '@ethersproject/units';
import { MAX_OFFSET, MAX_RATE, MAX_SCALAR, MIN_OFFSET } from 'src/constants/rate-controller';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { MutatedValueComputer } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import {
  bigNumberMaxValidator,
  bigNumberMinValidator,
  bigNumberNotEqualsValidator,
  bigNumberValidator,
} from 'src/forms/validation/big-number';
import Markdown from 'src/components/markdown';
import CopyConfigDialog from '../copy-config-dialog';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type CurrentValues = {
  min?: BigNumber;
  max?: BigNumber;
  scalar?: BigNumber;
  offset?: BigNumber;
};

type ComputerSetRateConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  token: string;
  rateComputer?: MutatedValueComputer;
  decimals: number;
  defaultOneXScalar: number;
  ratePrefix?: string;
  rateSuffix?: string;
  values?: CurrentValues;
  dataPoints?: DataPointsConfig;
};

const steps = ['Define parameters', 'Review and submit'];

export default function ComputerSetRateConfigDialog({
  dialogOpen,
  networkName,
  token,
  rateComputer,
  decimals,
  defaultOneXScalar,
  ratePrefix,
  rateSuffix,
  values,
  dataPoints,
}: ComputerSetRateConfigDialogProps) {
  const theme = useTheme();

  const copyConfigDialogOpen = useBoolean();
  const [copyingConfig, setCopyingConfig] = useState(false);
  const [copyFromContract, setCopyFromContract] = useState<string>();
  const [copyFromToken, setCopyFromToken] = useState<string>();
  const copyFromController = useMemo(() => {
    if (copyFromContract == null || rateComputer == null) {
      return undefined;
    }

    return new Contract(copyFromContract, rateComputer?.interface, rateComputer?.signer);
  }, [copyFromContract, rateComputer]);
  const copiedConfigCall = useCall(
    copyingConfig === true &&
      copyFromToken != null &&
      copyFromController != null && {
        contract: copyFromController,
        method: 'getConfig',
        args: [copyFromToken],
      },
    {
      chainId: config.chains[networkName]?.chainId,
    }
  );

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const setConfig = useContractFunction(rateComputer, 'setConfig');

  const { enqueueSnackbar } = useSnackbar();

  const rateLabel = dataPoints?.rate?.label ?? prudentiaGenericDataPoints.rate!.label;

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [min, setMin] = useState<BigNumber>();
  const [max, setMax] = useState<BigNumber>();
  const [scalar, setScalar] = useState<BigNumber>();
  const [offset, setOffset] = useState<BigNumber>();

  const scalarDecimals = Math.log10(defaultOneXScalar);

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          min: Yup.mixed()
            .test(
              'bigNumber',
              'Min should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Min cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Min cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .required('Min is required.'),
          max: Yup.mixed()
            .test(
              'bigNumber',
              'Max should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Max cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Max cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .required('Max is required.'),
          scalar: Yup.mixed()
            .test(
              'bigNumber',
              'Scalar should be a number with at most ' + scalarDecimals + ' decimal places.',
              bigNumberValidator(scalarDecimals)
            )
            .test('min', 'Scalar cannot be negative.', bigNumberMinValidator(0, scalarDecimals))
            .test('zero', 'Scalar cannot be zero.', bigNumberNotEqualsValidator(0, scalarDecimals))
            .test(
              'max',
              'Scalar cannot be greater than ' + formatUnits(MAX_SCALAR, scalarDecimals) + '.',
              bigNumberMaxValidator(MAX_SCALAR, scalarDecimals)
            )
            .required('Scalar is required.'),
          offset: Yup.mixed()
            .test(
              'bigNumber',
              'Offset should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test(
              'min',
              'Offset cannot be less than ' + formatUnits(MIN_OFFSET, decimals) + '.',
              bigNumberMinValidator(MIN_OFFSET, decimals)
            )
            .test(
              'max',
              'Offset cannot be greater than ' + formatUnits(MAX_OFFSET, decimals) + '.',
              bigNumberMaxValidator(MAX_OFFSET, decimals)
            )
            .required('Offset is required.'),
        })
        .required()
        .test('min-lte-max', 'Min must be less than or equal to max.', (values) => {
          if (values.min == null || values.max == null) {
            return false;
          }

          try {
            const min = parseUnits(values.min.toString(), decimals);
            const max = parseUnits(values.max.toString(), decimals);

            return min.lte(max);
          } catch (e) {
            // One of the other validators will catch this
          }

          return true;
        }),
    [decimals, scalarDecimals]
  );
  const defaultValues = useMemo(
    () => ({
      min: formatUnits(values?.min ?? 0, decimals),
      max: formatUnits(values?.max ?? 0, decimals),
      scalar: formatUnits(values?.scalar ?? 0, scalarDecimals),
      offset: formatUnits(values?.offset ?? 0, decimals),
    }),
    [values, decimals, scalarDecimals]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const min = parseUnits(data.min.toString(), decimals);
    const max = parseUnits(data.max.toString(), decimals);
    const scalar = parseUnits(data.scalar.toString(), scalarDecimals);
    const offset = parseUnits(data.offset.toString(), decimals);

    // Set the values
    setMin(min);
    setMax(max);
    setScalar(scalar);
    setOffset(offset);

    handleNext();
  });

  const handleConfigCopying = useCallback(() => {
    if (copyingConfig) {
      if (copiedConfigCall?.value?.[0] != null) {
        const copiedMin = formatUnits(copiedConfigCall.value[0].min, decimals);
        const copiedMax = formatUnits(copiedConfigCall.value[0].max, decimals);
        const copiedScalar = formatUnits(copiedConfigCall.value[0].scalar, scalarDecimals);
        const copiedOffset = formatUnits(copiedConfigCall.value[0].offset, decimals);

        formMethods.setValue('min', copiedMin);
        formMethods.setValue('max', copiedMax);
        formMethods.setValue('scalar', copiedScalar);
        formMethods.setValue('offset', copiedOffset);

        formMethods.trigger();

        enqueueSnackbar('Successfully copied the config.', {
          variant: 'success',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setCopyingConfig(false);
      } else if (copiedConfigCall?.error != null) {
        console.log('Failed to copy config: ', copiedConfigCall.error);

        enqueueSnackbar('Failed to copy config. See console for more details.', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setCopyingConfig(false);
      }
    }
  }, [copyingConfig, copiedConfigCall]);

  useEffect(() => {
    handleConfigCopying();
  }, [copyingConfig, copiedConfigCall]);

  function copyConfigCallback(value: { contract: string; token: string }) {
    setCopyFromContract(value.contract);
    setCopyFromToken(value.token);
    setCopyingConfig(true);
  }

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      setConfig.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && setConfig?.state?.status === 'Mining') {
      setConfig.resetState();

      dialogOpen.onFalse();
    }
  }, [setConfig?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  const globalAlerts = (
    <>
      <Alert severity="info">
        {rateLabel}s are formatted to whole units with {decimals} decimals.
      </Alert>
    </>
  );

  const markdownFormula = `
  \`\`\`tsx
  output = (input * ${commify(formatUnits(scalar ?? 0, scalarDecimals))}) + ${commify(
    formatUnits(offset ?? 0, decimals)
  )}
  output = min(output, ${commify(formatUnits(max ?? 0, decimals))})
  output = max(output, ${commify(formatUnits(min ?? 0, decimals))})
  \`\`\`
  `;

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Set {rateLabel.toLowerCase()} computer config</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {globalAlerts}
                </Box>
                <RHFTextField
                  fullWidth
                  name="min"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Min"
                  helperText={`The minimum ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('min', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('min');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="max"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max"
                  helperText={`The maximum ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('max', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('max');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="scalar"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Scalar"
                  helperText="The fractal amount to multiply by the input value."
                  InputProps={{
                    endAdornment: (
                      <>
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('scalar', formatUnits(MAX_SCALAR, scalarDecimals));
                            formMethods.trigger('scalar');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="offset"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Offset"
                  helperText={`The ${rateLabel.toLowerCase()} amount to add to the scaled input value.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('offset', formatUnits(MIN_OFFSET, decimals));
                            formMethods.trigger('offset');
                          }}
                          tabIndex={-1}
                        >
                          Min
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('offset', formatUnits(MAX_OFFSET, decimals));
                            formMethods.trigger('offset');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={copyConfigDialogOpen.onTrue} variant="outlined" color="inherit">
                  Copy From
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Box
                  rowGap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {globalAlerts}
                </Box>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>Min</TableCell>
                      <TableCell>{min?.toString()}</TableCell>
                      <TableCell>
                        {min?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix + commify(formatUnits(min ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max</TableCell>
                      <TableCell>{max?.toString()}</TableCell>
                      <TableCell>
                        {max?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix + commify(formatUnits(max ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Scalar</TableCell>
                      <TableCell>{scalar?.toString()}</TableCell>
                      <TableCell>
                        {commify(formatUnits(scalar ?? 0, scalarDecimals)) + 'x'}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Offset</TableCell>
                      <TableCell>{offset?.toString()}</TableCell>
                      <TableCell>
                        {ratePrefix + commify(formatUnits(offset ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Typography
                  marginTop={theme.spacing(4)}
                  marginBottom={theme.spacing(1)}
                  variant="body1"
                >
                  Formatted formula:
                </Typography>
                <Markdown children={markdownFormula} />
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          setConfig.send(token, max!, min!, offset!, scalar!);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
      <CopyConfigDialog
        dialogOpen={copyConfigDialogOpen}
        callback={copyConfigCallback}
        values={{
          contract: rateComputer?.address,
          token: token,
        }}
      />
    </Dialog>
  );
}
