import { ethers } from 'ethers';

export function addressValidator(allowEmpty = false) {
  return (value: any) => {
    if (value === '' && allowEmpty) {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      ethers.utils.getAddress(value);

      return true;
    } catch (error) {
      return false;
    }
  };
}
