// @mui
import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  useTheme,
} from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';

import { Navigate, useParams } from 'react-router';
import config from 'src/adrastia.config';
import ControllerFeed from 'src/components/prices/ControllerFeed';
import { Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Box } from '@mui/material';
import { PATH_DISCORD } from 'src/routes/paths';

export default function ControllerFeeds() {
  const theme = useTheme();
  const lightMode = theme.palette.mode === 'light';
  const { themeStretch } = useSettings();

  const deployInfoPopover = usePopover();

  var { networkName } = useParams();

  if (!networkName || !config.chains[networkName]) {
    return <Navigate to="/404" replace={true} />;
  }

  const oracles = config.chains[networkName].controllers;

  return (
    <Page
      title={'Controllers | ' + config.chains[networkName].name || '404'}
      contentTitle={config.chains[networkName].name + ' Controllers'}
    >
      {oracles && (
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert icon={<Iconify icon="mdi:wand" />} severity="info">
                Reach out to us on{' '}
                <Link
                  href={PATH_DISCORD}
                  target="_blank"
                  color={lightMode ? 'primary.dark' : 'primary.light'}
                  fontWeight={600}
                >
                  Discord
                </Link>{' '}
                or by email at{' '}
                <Link
                  href="mailto:support@adrastia.io"
                  target="_blank"
                  color={lightMode ? 'primary.dark' : 'primary.light'}
                  fontWeight={600}
                >
                  support@adrastia.io
                </Link>{' '}
                if you'd like us to deploy a controller for your project.
                <IconButton
                  color="primary"
                  aria-label="info"
                  size="small"
                  sx={{
                    paddingX: theme.spacing(0.5),
                    paddingY: 0,
                    margin: 0,
                  }}
                  onClick={deployInfoPopover.onOpen}
                >
                  <Iconify icon="mdi:information-outline" />
                </IconButton>
                <CustomPopover
                  open={deployInfoPopover.open}
                  onClose={deployInfoPopover.onClose}
                  arrow="bottom-center"
                >
                  <Typography variant="body2" component="div">
                    We have solutions for:
                    <Box marginLeft={theme.spacing(2)} padding={theme.spacing(0.5)}>
                      <ul>
                        <li>Supply and borrow caps</li>
                        <li>Interest rates</li>
                        <li>Collateral factors</li>
                        <li>And more!</li>
                      </ul>
                    </Box>
                  </Typography>
                </CustomPopover>
              </Alert>
            </Grid>
            <>
              {Object.keys(oracles).map(
                (key) =>
                  !oracles[key].hide && (
                    <Grid
                      item
                      xl={oracles[key].tokens.length == 1 ? 4 : 12}
                      md={oracles[key].tokens.length == 1 ? 6 : 12}
                      xs={12}
                      key={key}
                    >
                      <ControllerFeed
                        chainName={networkName!}
                        oracleAddress={key}
                        oracleConfig={oracles[key]}
                      />
                    </Grid>
                  )
              )}
            </>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
