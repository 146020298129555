import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

function numberWithCommas(x: string | number) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}

export function getNonZeroDecimalPosition(v: string) {
  var v2 = v.replace(/\.(0+)?/, '');
  return v2.length !== v.length ? v.length - v2.length : -1;
}

export const parsePrice = (price: BigNumber, decimals: number) => {
  return niceNumber(parseFloat(formatUnits(price, decimals)), Math.min(6, decimals));
};

export const niceNumber = (num: number, decimals: number = 6) => {
  const smallNumberDecimals = Math.max(0, getNonZeroDecimalPosition(num.toString()) + decimals - 1);

  return numberWithCommas(num.toFixed(num >= 1 ? Math.max(decimals, 0) : smallNumberDecimals));
};

export const niceNumber2 = (num: number, digits: number = 4) => {
  const smallNumberDecimals = Math.max(0, getNonZeroDecimalPosition(num.toString()) + digits - 1);
  const numberDigits = num.toString().split('.')[0].length;

  return numberWithCommas(
    num.toFixed(num >= 1 ? Math.max(digits - numberDigits, 0) : smallNumberDecimals)
  );
};
