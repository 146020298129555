import { alpha, useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const linkHoverShadow = (color: string, isLight: boolean) =>
  `0 0 0 2px ${alpha(color, isLight ? 0.48 : 0.64)}`;

type Props = {
  selectedIndex?: number;
  nextIndex?: number;
  index: number;
  rate: number | undefined;
  ratePrefix?: string;
  rateSuffix?: string;
};

export default function HistoricalRatesItem({
  selectedIndex,
  nextIndex,
  index,
  rate,
  ratePrefix,
  rateSuffix,
}: Props) {
  const theme = useTheme();

  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  const selected = selectedIndex === index;

  const shadow = selected ? linkHoverShadow(theme.palette.primary.main, isLight) : undefined;

  const indexDisplay = selected ? (
    <em>Index: {index} (in use)</em>
  ) : (
    <>Index: {index + (index === nextIndex ? ' (next)' : '')}</>
  );

  return (
    <Card
      sx={{
        boxShadow:
          (shadow ? shadow + ', ' : '') +
            (theme.components?.MuiCard?.styleOverrides?.root as any)?.boxShadow || '',
      }}
    >
      <CardContent>
        <Typography variant="caption">{indexDisplay}</Typography>
        <Typography
          component="div"
          variant="h4"
          marginTop={theme.spacing(1)}
          sx={{ fontFamily: theme.typography.body1.fontFamily, fontWeight: 500 }}
        >
          Rate: {(ratePrefix ?? '') + rate + (rateSuffix ?? '')}
        </Typography>
      </CardContent>
    </Card>
  );
}
