import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Contract } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import config from 'src/adrastia.config';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { default as accessControlAbi } from '@openzeppelin-v4/contracts/build/contracts/AccessControlEnumerable.json';
import { Interface } from '@ethersproject/abi';
import { IAccessControl } from 'typechain/openzeppelin-v4';
import { useSnackbar } from 'notistack';
import { addressValidator } from 'src/forms/validation/address';
import { ROLES } from 'src/constants/roles';
import AddressDisplay from 'src/components/AddressDisplay';
import { AddressZero } from '@ethersproject/constants';

const accessControlInterface = new Interface(accessControlAbi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type GrantRoleDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  contractAddress: string;
  networkName: string;
};

const steps = ['Define parameters', 'Review and submit'];

const OPTIONS = Object.keys(ROLES)
  .map((role) => ({
    value: role,
    label: ROLES[role].name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export default function GrantRoleDialog({
  dialogOpen,
  contractAddress,
  networkName,
}: GrantRoleDialogProps) {
  const contract = useMemo(
    () => new Contract(contractAddress, accessControlInterface) as IAccessControl,
    [contractAddress]
  );

  const theme = useTheme();

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const grantRole = useContractFunction(contract, 'grantRole');

  const { enqueueSnackbar } = useSnackbar();

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [address, setAddress] = useState<string>();
  const [role, setRole] = useState<string>();

  const adminRole = useCall(
    role && {
      contract: contract,
      method: 'getRoleAdmin',
      args: [role],
    },
    {
      chainId: config.chains[networkName!]?.chainId,
    }
  );
  const adminRoleLabel = useMemo(() => {
    if (adminRole?.value?.[0] == null) {
      return 'Loading...';
    }

    const matchedRole = ROLES[adminRole?.value?.[0]];
    if (matchedRole == null) {
      return 'Unsupported';
    }

    return matchedRole.name;
  }, [adminRole]);
  const supportedRole = adminRoleLabel !== 'Unsupported';

  const hasAdminRoleCall = useCall(
    adminRole?.value?.[0] &&
      account && {
        contract: contract,
        method: 'hasRole',
        args: [adminRole?.value?.[0], account!],
      },
    {
      chainId: config.chains[networkName!]?.chainId,
    }
  );
  const hasAdminRole = {
    loading: hasAdminRoleCall?.value?.[0] == null,
    value: hasAdminRoleCall?.value?.[0],
  };

  const addressAlreadyHasRoleCall = useCall(
    role &&
      address && {
        contract: contract,
        method: 'hasRole',
        args: [role, address!],
      },
    {
      chainId: config.chains[networkName!]?.chainId,
    }
  );
  const addressAlreadyHasRole = {
    loading: addressAlreadyHasRoleCall?.value?.[0] == null,
    value: addressAlreadyHasRoleCall?.value?.[0],
  };

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          address: Yup.mixed()
            .test('address', 'Address should be a valid EVM address.', addressValidator())
            .required('Address is required.'),
          role: Yup.mixed<any>()
            .nullable()
            .notOneOf([''], 'Role is required.')
            .required('Role is required.'),
        })
        .required(),
    []
  );
  const defaultValues = useMemo(
    () => ({
      address: '',
      role: null,
    }),
    []
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Set the values
    setAddress(data.address as string);
    setRole(data.role.value as string);

    handleNext();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      grantRole.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && grantRole?.state?.status === 'Mining') {
      grantRole.resetState();

      dialogOpen.onFalse();
    }
  }, [grantRole?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Grant role</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                <RHFAutocomplete
                  fullWidth
                  name="role"
                  label="Role"
                  helperText="The role to grant."
                  options={OPTIONS}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="address"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Address"
                  helperText="The address to grant the role to."
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {ROLES[formMethods.getValues('role')?.value]?.canBeOpen === true && (
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue('address', AddressZero);
                              formMethods.trigger('address');
                            }}
                            tabIndex={-1}
                          >
                            Anyone
                          </Button>
                        )}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('address', account ?? '');
                            formMethods.trigger('address');
                          }}
                          tabIndex={-1}
                        >
                          Me
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {!supportedRole ? (
                    <Alert severity="error" variant="outlined">
                      The role is not supported.
                    </Alert>
                  ) : (
                    !hasAdminRole.loading &&
                    !hasAdminRole.value && (
                      <Alert severity="error" variant="outlined">
                        You do not have the required admin role to grant this role.
                      </Alert>
                    )
                  )}
                  {addressAlreadyHasRole.value && (
                    <Alert severity="warning" variant="outlined">
                      The address already has the role.
                    </Alert>
                  )}
                </Box>
                <Box
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                  marginLeft={theme.spacing(2)}
                >
                  <Typography variant="body2">
                    <strong>Admin role</strong>: {adminRoleLabel}
                  </Typography>
                </Box>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>Role</TableCell>
                      <TableCell>{role}</TableCell>
                      <TableCell>{ROLES[role!]?.name}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>
                        <AddressDisplay
                          address={address as string}
                          chainId={config.chains[networkName]?.chainId}
                          target="_blank"
                          resolveAddress
                          hideAddressIfResolved
                        />
                      </TableCell>
                      <TableCell>{'<<<'}</TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          grantRole.send(role!, address!);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    disabled={hasAdminRole.loading || !hasAdminRole.value || !supportedRole}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
