import { BigNumber } from 'ethers';

export const MIN_BASE = BigNumber.from(10).pow(127).mul(-1);
export const MAX_BASE = BigNumber.from(10).pow(127).sub(1);

export const MIN_SLOPE = BigNumber.from(10).pow(63).mul(-1);
export const MAX_SLOPE = BigNumber.from(10).pow(63).sub(1);

export const MIN_KINK = BigNumber.from(0);
export const MAX_KINK = BigNumber.from(10).pow(128).sub(1);
