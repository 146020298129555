// @mui
import { Card, CardProps, CardContent, useTheme } from '@mui/material';
// components
import { ApexOptions } from 'apexcharts';
import { niceNumber2 } from 'src/utils/formatPrices';
import { DataPointsConfig } from 'src/adrastia.config';
import Chart, { useChart } from '../chart';
import { th } from 'date-fns/locale';
import GetFontValue from 'src/utils/getFontValue';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  series: {
    name: string;
    data: {
      x: number; // timestamp
      y: number; // price
    }[];
  }[];
  ratePrefix?: string;
  rateSuffix?: string;
  dataPointsConfig: DataPointsConfig;
  annotationsX?: {
    [x: number]: {
      label: string;
      txHash?: string;
    };
  };
}

export default function LiquidityHistoryChart({
  title,
  series,
  ratePrefix,
  rateSuffix,
  dataPointsConfig,
  annotationsX,
}: Props) {
  const theme = useTheme();

  const yaxis = [];
  const yaxisDecoration = [];

  var min = Math.min.apply(
    null,
    series[0].data.map((d) => d.y).concat(series[1].data.map((d) => d.y))
  );
  var max = Math.max.apply(
    null,
    series[0].data.map((d) => d.y).concat(series[1].data.map((d) => d.y))
  );

  min -= Math.abs(min) * 0.01;
  max += Math.abs(max) * 0.01;

  yaxis.push({
    labels: {
      formatter: (value: number) => {
        return (ratePrefix ?? '') + niceNumber2(value, 6) + (rateSuffix ?? '');
      },
    },
    forceNiceScale: true,
    min: min,
    max: max,
  });

  yaxisDecoration.push({
    labels: {
      show: false,
    },
    forceNiceScale: true,
    min: min,
    max: max,
  });

  const downloadFileName = 'adrastia_' + title.replace(/\s/g, '_').toLowerCase();

  const body1 = GetFontValue('body1');

  const chartOptions: ApexOptions = {
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      labels: {
        datetimeUTC: false,
        format: 'MMM dd, yy',
        showDuplicates: false,
      },
    },
    yaxis: yaxis[0],
    annotations: {
      xaxis:
        annotationsX == null
          ? undefined
          : Object.keys(annotationsX).map((x) => {
              return {
                x: x,
                borderColor: theme.palette.primary.main,
                label: {
                  borderColor: theme.palette.primary.main,
                  orientation: 'horizontal',
                  text: annotationsX[Number(x)].label,
                  style: {
                    fontFamily: theme.typography.body1.fontFamily,
                    fontSize: body1.fontSize + 'px',
                    fontWeight: body1.fontWeight,
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                },
              };
            }),
    },
    stroke: {
      curve: 'stepline',
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: 'MMM dd, yy HH:mm',
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: yaxisDecoration[0],
        },
      },
    ],
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        export: {
          csv: {
            filename: downloadFileName,
            columnDelimiter: ',',
            headerCategory: 'Time',
            categoryFormatter(timestamp: any) {
              return new Date(timestamp).toISOString();
            },
          },
          svg: {
            filename: downloadFileName,
          },
          png: {
            filename: downloadFileName,
          },
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
    },
    title: {
      text: title,
    },
  };

  const chart = useChart(chartOptions);

  return useMemo(() => {
    return <Chart options={chart} series={series} type="line" height={512} />;
  }, [JSON.stringify(chart), JSON.stringify(series)]);
}
