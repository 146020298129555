import { Card, CardContent, CardHeader } from '@mui/material';
import AccessControlCardContent from './AccessControlCardContent';

type AccessControlCardProps = {
  contractAddress: string;
  networkName: string;
};

export default function AccessControlCard({
  contractAddress,
  networkName,
}: AccessControlCardProps) {
  return (
    <>
      <Card>
        <CardHeader
          title="Access control"
          sx={{
            textAlign: 'center',
          }}
        />
        <CardContent>
          <AccessControlCardContent contractAddress={contractAddress} networkName={networkName} />
        </CardContent>
      </Card>
    </>
  );
}
