import { ethers } from 'ethers';

export const VOID_ROLE = '0x0000000000000000000000000000000000000000000000000000000000000000';

export const ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('ADMIN_ROLE'));
export const CONFIG_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('CONFIG_ADMIN_ROLE'));
export const TARGET_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('TARGET_ADMIN_ROLE'));
export const UPDATER_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('UPDATER_ADMIN_ROLE'));
export const UPDATER = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('ORACLE_UPDATER_ROLE'));
export const RATE_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('RATE_ADMIN_ROLE'));
export const UPDATE_PAUSE_ADMIN = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('UPDATE_PAUSE_ADMIN_ROLE')
);

/******************************************************************************************************************
 * AUTOMATOS PROTOCOL
 *****************************************************************************************************************/

export const PROTOCOL_ADMIN = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('PROTOCOL_ADMIN_ROLE')
);

/******************************************************************************************************************
 * AUTOMATOS FACTORY
 *****************************************************************************************************************/

export const FACTORY_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('FACTORY_ADMIN_ROLE'));

export const FACTORY_MANAGER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('FACTORY_MANAGER_ROLE')
);

export const FACTORY_REGISTRY_DEPLOYER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('FACTORY_REGISTRY_DEPLOYER_ROLE')
);

/******************************************************************************************************************
 * AUTMATOS REGISTRY
 *****************************************************************************************************************/

export const REGISTRY_ADMIN = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('REGISTRY_ADMIN_ROLE')
);

export const REGISTRY_FINANCE_MANAGER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('REGISTRY_FINANCE_MANAGER_ROLE')
);

export const REGISTRY_MANAGER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('REGISTRY_MANAGER_ROLE')
);

export const REGISTRY_POOL_DEPLOYER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('REGISTRY_POOL_DEPLOYER_ROLE')
);

/******************************************************************************************************************
 * AUTOMATOS WORKER
 *****************************************************************************************************************/

export const WORKER_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('WORKER_ADMIN_ROLE'));

export const WORKER_MANAGER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('WORKER_MANAGER_ROLE')
);

export const WORKER = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('WORKER_ROLE'));

/******************************************************************************************************************
 * AUTOMATOS POOL
 *****************************************************************************************************************/

export const POOL_ADMIN = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('POOL_ADMIN_ROLE'));

export const POOL_MANAGER = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('POOL_MANAGER_ROLE'));

export const POOL_WORK_MANAGER = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes('POOL_WORK_MANAGER_ROLE')
);

export type RolesConfig = {
  [key: string]: {
    name: string;
    canBeOpen?: boolean;
  };
};

export const ROLES: RolesConfig = {
  [ADMIN]: {
    name: 'Admin',
  },
  [CONFIG_ADMIN]: {
    name: 'Config Admin',
  },
  [TARGET_ADMIN]: {
    name: 'Target Admin',
  },
  [UPDATER_ADMIN]: {
    name: 'Updater Admin',
  },
  [UPDATER]: {
    name: 'Updater',
    canBeOpen: true,
  },
  [RATE_ADMIN]: {
    name: 'Rate Admin',
  },
  [UPDATE_PAUSE_ADMIN]: {
    name: 'Update Pause Admin',
  },
  [PROTOCOL_ADMIN]: {
    name: 'Protocol Admin',
  },
  [FACTORY_ADMIN]: {
    name: 'Factory Admin',
  },
  [FACTORY_MANAGER]: {
    name: 'Factory Manager',
  },
  [FACTORY_REGISTRY_DEPLOYER]: {
    name: 'Factory Registry Deployer',
  },
  [REGISTRY_ADMIN]: {
    name: 'Registry Admin',
  },
  [REGISTRY_FINANCE_MANAGER]: {
    name: 'Registry Finance Manager',
  },
  [REGISTRY_MANAGER]: {
    name: 'Registry Manager',
  },
  [REGISTRY_POOL_DEPLOYER]: {
    name: 'Registry Pool Deployer',
  },
  [WORKER_ADMIN]: {
    name: 'Worker Admin',
  },
  [WORKER_MANAGER]: {
    name: 'Worker Manager',
  },
  [WORKER]: {
    name: 'Worker',
  },
  [POOL_ADMIN]: {
    name: 'Pool Admin',
  },
  [POOL_MANAGER]: {
    name: 'Pool Manager',
  },
  [POOL_WORK_MANAGER]: {
    name: 'Pool Work Manager',
  },
};
