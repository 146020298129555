import { Theme, alpha } from '@mui/material/styles';
import { listClasses } from '@mui/material/List';

import { paper } from '../css';

// ----------------------------------------------------------------------

const customShadow = (color: string, isLight: boolean) =>
  `0 0 0 2px ${alpha(color, isLight ? 0.32 : 0.64)}`;

export default function Popover(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          ...paper({ theme, dropdown: true }),
          boxShadow:
            customShadow(theme.palette.grey[600], isLight) +
              ', ' +
              (theme.components?.MuiCard?.styleOverrides?.root as any)?.boxShadow || '',
          [`& .${listClasses.root}`]: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  };
}
