// ----------------------------------------------------------------------

import { PATH_APP, PATH_BLOG, PATH_DOCS, PATH_PAGE, PATH_STATUS } from 'src/routes/paths';

export const navConfig = [
  { title: 'Home', path: '/' },
  { title: 'About', path: PATH_PAGE.about },
  { title: 'App', path: PATH_APP.root },
  { title: 'Blog', path: PATH_BLOG },
  { title: 'Docs', path: PATH_DOCS },
  { title: 'Status', path: PATH_STATUS },
];
