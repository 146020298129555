import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Contract } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import config, { DataPointsConfig, prudentiaGenericDataPoints } from 'src/adrastia.config';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { ManagedHistoricalRatesComputer } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import { Interface } from '@ethersproject/abi';

import { default as abi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/ManagedHistoricalRatesComputer.sol/ManagedHistoricalRatesComputer.json';
import { AddressZero } from '@ethersproject/constants';
import { addressValidator } from 'src/forms/validation/address';
import { MAX_INDEX } from 'src/constants/historical-rates-computer';
import AddressDisplay from 'src/components/AddressDisplay';
import { PATH_APP } from 'src/routes/paths';

const contractInterface = new Interface(abi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type CurrentValues = {
  rateProvider?: string;
  index?: number;
  highAvailability?: boolean;
  isUsingDefaultConfig?: boolean;
};

type HistoricalRatesComputerSetConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  contractAddress: string;
  token: string;
  actualToken: string;
  values?: CurrentValues;
  dataPoints?: DataPointsConfig;
};

const steps = ['Define parameters', 'Review and submit'];

export default function HistoricalRatesComputerSetConfigDialog({
  dialogOpen,
  networkName,
  contractAddress,
  token,
  actualToken,
  values,
  dataPoints,
}: HistoricalRatesComputerSetConfigDialogProps) {
  const theme = useTheme();

  const contract = useMemo(() => {
    return new Contract(contractAddress, contractInterface) as ManagedHistoricalRatesComputer;
  }, [contractAddress]);

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const setConfigFunc = useContractFunction(contract, 'setConfig');
  const revertToDefaultConfigFunc = useContractFunction(contract, 'revertToDefaultConfig');

  const { enqueueSnackbar } = useSnackbar();

  const rateLabel = dataPoints?.rate?.label ?? prudentiaGenericDataPoints.rate!.label;

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [rateProvider, setRateProvider] = useState<string>();
  const [index, setIndex] = useState<number>();
  const [highAvailability, setHighAvailability] = useState<boolean>();
  const [useDefaultConfig, setUseDefaultConfig] = useState<boolean>();

  const defaultConfigCall = useCall(
    {
      contract: contract,
      method: 'getConfig',
      args: [AddressZero],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const defaultConfig = defaultConfigCall?.value?.[0];

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          rateProvider: Yup.mixed()
            .test('address', 'Rate controller should be a valid address.', addressValidator())
            .required('Rate controller is required.'),
          index: Yup.number()
            .typeError('Index should be a number.')
            .min(0, 'Index cannot be negative')
            .max(MAX_INDEX, 'Index cannot be greater than ' + MAX_INDEX + '.')
            .required('Index is required.'),
          highAvailability: Yup.boolean().required('High availability is required.'),
          useDefaultConfig: Yup.boolean().required('Use default config is required.'),
        })
        .required()
        .test('something-changes', 'No changes detected.', (formValues) => {
          const { rateProvider, index, highAvailability, useDefaultConfig } = formValues;

          if (token === AddressZero) {
            // Setting default config. Check for change in config.
            return (
              rateProvider != values?.rateProvider ||
              index != values?.index ||
              highAvailability != values?.highAvailability
            );
          } else {
            // Setting a token specific config.

            if (useDefaultConfig && values?.isUsingDefaultConfig) {
              // We're already using the default config
              return false;
            } else {
              if (!useDefaultConfig && values?.isUsingDefaultConfig) {
                // We're switching from default config to custom config
                return true;
              } else if (useDefaultConfig && !values?.isUsingDefaultConfig) {
                // We're switching from custom config to default config
                return true;
              } else {
                // We're using a custom config
                return (
                  rateProvider != values?.rateProvider ||
                  index != values?.index ||
                  highAvailability != values?.highAvailability
                );
              }
            }
          }

          return true;
        }),
    [values]
  );
  const defaultValues = useMemo(
    () => ({
      rateProvider: values?.rateProvider ?? '',
      index: values?.index ?? 0,
      highAvailability: values?.highAvailability ?? false,
      useDefaultConfig:
        token === AddressZero
          ? true
          : defaultConfigCall?.error != null
          ? false
          : values?.isUsingDefaultConfig ?? true,
    }),
    [values, defaultConfigCall, token]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const rateProvider =
      data.useDefaultConfig && token !== AddressZero
        ? defaultConfig?.rateProvider ?? ''
        : data.rateProvider;
    const index =
      data.useDefaultConfig && token !== AddressZero ? defaultConfig?.index ?? 0 : data.index;
    const highAvailability =
      data.useDefaultConfig && token !== AddressZero
        ? defaultConfig?.highAvailability ?? false
        : data.highAvailability;

    const useDefaultConfig = data.useDefaultConfig;

    // Set the values
    setRateProvider(rateProvider as string);
    setIndex(index);
    setHighAvailability(highAvailability);
    setUseDefaultConfig(useDefaultConfig);

    handleNext();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      setConfigFunc.resetState();
      revertToDefaultConfigFunc.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && setConfigFunc?.state?.status === 'Mining') {
      setConfigFunc.resetState();

      dialogOpen.onFalse();
    }
  }, [setConfigFunc?.state]);
  useEffect(() => {
    if (dialogOpen.value === true && revertToDefaultConfigFunc?.state?.status === 'Mining') {
      revertToDefaultConfigFunc.resetState();

      dialogOpen.onFalse();
    }
  }, [revertToDefaultConfigFunc?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        Set {token === AddressZero ? 'default ' : ' '}historical {rateLabel.toLowerCase()}s config
      </DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                {token !== AddressZero && (
                  <Box marginTop={theme.spacing(1)} marginBottom={theme.spacing(2)}>
                    <Typography variant="body2">
                      <strong>Default rate controller</strong>:{' '}
                      {defaultConfigCall?.error != null
                        ? 'Not set'
                        : defaultConfig == null
                        ? 'Loading...'
                        : (
                            <AddressDisplay
                              component={'span'}
                              address={defaultConfig?.rateProvider}
                              chainId={config.chains[networkName]?.chainId}
                              link={
                                PATH_APP.controllers +
                                '/' +
                                networkName +
                                '/' +
                                defaultConfig?.rateProvider +
                                '/' +
                                actualToken
                              }
                              target="_blank"
                              resolveAddress
                            />
                          ) ?? '-'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Default index</strong>:{' '}
                      {defaultConfigCall?.error != null
                        ? 'Not set'
                        : defaultConfig == null
                        ? 'Loading...'
                        : defaultConfig?.index ?? '-'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Default high availability</strong>:{' '}
                      {defaultConfigCall?.error != null
                        ? 'Not set'
                        : defaultConfig == null
                        ? 'Loading...'
                        : defaultConfig?.highAvailability?.toString() ?? '-'}
                    </Typography>
                  </Box>
                )}
                <RHFTextField
                  fullWidth
                  name="rateProvider"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Rate controller"
                  helperText={`The source of historical ${rateLabel.toLowerCase()}s.`}
                  disabled={token !== AddressZero && formMethods.getValues()?.useDefaultConfig}
                />
                <RHFTextField
                  fullWidth
                  name="index"
                  type="number"
                  margin="normal"
                  variant="outlined"
                  label="Index"
                  helperText={`The ${rateLabel.toLowerCase()} buffer index to use, with 0 being the most recent.`}
                  disabled={token !== AddressZero && formMethods.getValues()?.useDefaultConfig}
                />
                <RHFCheckbox
                  name="highAvailability"
                  label="Use high availability"
                  helperText={`Select to use the oldest available ${rateLabel.toLowerCase()} if the requested ${rateLabel.toLowerCase()} is not available.`}
                  disabled={token !== AddressZero && formMethods.getValues()?.useDefaultConfig}
                />
                <RHFCheckbox
                  name="useDefaultConfig"
                  label={
                    'Use default config' + (defaultConfigCall?.error != null ? ' (not set)' : '')
                  }
                  helperText="Select to use the default config."
                  disabled={token === AddressZero || defaultConfigCall?.error != null}
                />
                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>Rate controller</TableCell>
                      <TableCell>{rateProvider}</TableCell>
                      <TableCell>
                        <AddressDisplay
                          address={rateProvider!}
                          chainId={config.chains[networkName]?.chainId}
                          link={
                            PATH_APP.controllers +
                            '/' +
                            networkName +
                            '/' +
                            rateProvider +
                            '/' +
                            actualToken
                          }
                          target="_blank"
                          resolveAddress
                        />
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Index</TableCell>
                      <TableCell>{index}</TableCell>
                      <TableCell>{index}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>High availability</TableCell>
                      <TableCell>{highAvailability?.toString()}</TableCell>
                      <TableCell>{highAvailability?.toString()}</TableCell>
                    </StyledTableRow>
                    {token !== AddressZero && (
                      <StyledTableRow>
                        <TableCell>Use default config</TableCell>
                        <TableCell>{useDefaultConfig?.toString()}</TableCell>
                        <TableCell>{useDefaultConfig?.toString()}</TableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          if (token !== AddressZero) {
                            // Check if we're reverting to the default target
                            if (useDefaultConfig) {
                              revertToDefaultConfigFunc.send(token);
                            } else {
                              setConfigFunc.send(token, {
                                rateProvider: rateProvider!,
                                index: index!,
                                highAvailability: highAvailability!,
                              });
                            }
                          } else {
                            // Setting the default target
                            setConfigFunc.send(AddressZero, {
                              rateProvider: rateProvider!,
                              index: index!,
                              highAvailability: highAvailability!,
                            });
                          }
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
