import config from 'src/adrastia.config';
import HistoricalRatesQueue from './historical-rates-queue';
import { default as abi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/IHistoricalRates.sol/IHistoricalRates.json';
import { Interface } from '@ethersproject/abi';
import { IHistoricalRates } from 'typechain/adrastia-periphery';
import { Contract } from 'ethers';
import { Call, useCall, useCalls } from '@usedapp/core';
import { formatUnits, parseUnits } from '@ethersproject/units';
import ReactPlaceholder from 'react-placeholder';

const contractInterface = new Interface(abi.abi);

type Props = {
  networkName: string;
  contractAddress: string;
  token: string;
  selectedIndex?: number;
  highAvailability?: boolean;
};

export default function HistoricalRatesDisplay({
  networkName,
  contractAddress,
  token,
  selectedIndex,
  highAvailability,
}: Props) {
  const contract = new Contract(contractAddress, contractInterface) as IHistoricalRates;

  const chainId = config.chains[networkName]?.chainId;

  const oracle = config.chains[networkName]?.controllers?.[contractAddress];

  const ratePrefix = oracle?.display?.ratePrefix;
  const rateSuffix = oracle?.display?.rateSuffix;

  const displayDecimals = oracle?.display?.decimals ?? 0;

  const ratesCount = useCall(
    {
      contract: contract,
      method: 'getRatesCount',
      args: [token],
    },
    {
      chainId: chainId,
    }
  );

  const ratesCalls = new Array<Call | undefined>(ratesCount?.value?.[0]?.toNumber() ?? 0);
  for (var i = 0; i < ratesCalls.length; ++i) {
    ratesCalls[i] = {
      contract: contract,
      method: 'getRateAt',
      args: [token, i],
    };
  }

  const ratesCall = useCalls(ratesCalls, {
    chainId: chainId,
  });

  const ratesLoading =
    ratesCount?.value?.[0] == null || (ratesCall?.some((rate) => rate?.value?.[0] == null) ?? true);

  const rates =
    (!ratesLoading &&
      ratesCall?.map((rate) => {
        if (rate?.value?.[0] != null) {
          const parsedRate = formatUnits(rate.value[0].current, displayDecimals);

          return Number(parsedRate);
        }

        return undefined;
      })) ||
    [];

  var selectedIndexToUse = selectedIndex;
  if (selectedIndexToUse != null && highAvailability && selectedIndexToUse >= rates.length) {
    selectedIndexToUse = rates.length - 1;
  }

  let nextIndex = selectedIndex ? selectedIndex - 1 : undefined;

  return (
    <ReactPlaceholder
      showLoadingAnimation={true}
      type="rect"
      ready={!ratesLoading}
      style={{
        width: '100%',
        height: '280px',
      }}
      delay={0}
    >
      <HistoricalRatesQueue
        rates={rates}
        ratePrefix={ratePrefix}
        rateSuffix={rateSuffix}
        selectedIndex={selectedIndexToUse}
        nextIndex={nextIndex}
      />
    </ReactPlaceholder>
  );
}
