const ROOTS_APP = '/app';

export const PATH_PAGE = {
  about: '/about',
  page404: '/404',
};

export const PATH_APP = {
  root: ROOTS_APP,
  dataFeeds: `${ROOTS_APP}/oracles`,
  updaters: `${ROOTS_APP}/updaters`,
  controllers: `${ROOTS_APP}/controllers`,
  automatos: `${ROOTS_APP}/automatos`,
};

export const PATH_DOCS = 'https://docs.adrastia.io';

export const PATH_BLOG = 'https://blog.adrastia.io';

export const PATH_TWITTER = 'https://twitter.com/AdrastiaOracle';

export const PATH_DISCORD = 'https://discord.gg/sZP9SUBuSC';

export const PATH_GITHUB = 'https://github.com/adrastia-oracle';

export const PATH_STATUS = 'https://status.adrastia.io';
