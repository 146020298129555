// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';

import { BigNumber, ethers, Contract, utils } from 'ethers';

import { Navigate, useParams } from 'react-router';

import config, { dexPriceAndLiquidity, prudentiaGenericDataPoints } from 'src/adrastia.config';

import { useLogs, useCall, shortenIfAddress, useBlockNumbers, useEthers } from '@usedapp/core';

// Adrastia Core v3 ABI imports
import { default as updateableAbi } from 'adrastia-core-v3/artifacts/contracts/interfaces/IUpdateable.sol/IUpdateable.json';
import { default as erc165Abi } from '@openzeppelin-v4/contracts/build/contracts/ERC165.json';
import { default as accessControlEnumerableAbi } from '@openzeppelin-v4/contracts/build/contracts/AccessControlEnumerable.json';
import { default as erc20Abi } from '@openzeppelin-v4/contracts/build/contracts/IERC20Metadata.json';

// Adrastia Core v4 ABI imports
import { default as oracleAbi } from 'adrastia-core-v4/artifacts/contracts/interfaces/IOracle.sol/IOracle.json';

// Adrastia Periphery ABI imports
import { default as rateControllerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/RateController.sol/RateController.json';
import { default as capControllerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/controllers/CapController.sol/CapController.json';
import { default as pidControllerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/controllers/PidController.sol/PidController.json';
import { default as erc20MutationComputerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/Erc20MutationComputer.sol/Erc20MutationComputer.json';
import { default as oracleMutationComputerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/OracleMutationComputer.sol/OracleMutationComputer.json';
import { default as slopedOracleMutationComputerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/SlopedOracleMutationComputer.sol/SlopedOracleMutationComputer.json';
import { default as historicalRatesComputerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/computers/HistoricalRatesComputer.sol/HistoricalRatesComputer.json';

// Adrastia Core v3 type imports
import { IUpdateable } from 'typechain/adrastia-core-v3/interfaces';

// Adrastia Periphery type imports
import {
  RateController,
  SlopedOracleMutationComputer,
  CapController,
  PidController,
  Erc20MutationComputer,
  OracleMutationComputer,
  HistoricalRatesComputer,
} from 'typechain/adrastia-periphery';

import { commify, formatUnits, isAddress } from 'ethers/lib/utils';
import ReactPlaceholder from 'react-placeholder/lib';
import { IAccessControlEnumerable, IERC165 } from 'typechain/openzeppelin-v4';
import { parsePrice } from 'src/utils/formatPrices';
import { fDuration } from 'src/utils/format-time';
import { PATH_APP } from 'src/routes/paths';
import LiquidityHistoryChart from 'src/components/prices/LiquidityHistoryChart';
import AddressDisplay from 'src/components/AddressDisplay';
import { IPERIODIC_ID, IUPDATEABLE_ID } from 'src/constants/interface-ids';
import { DEFAULT_ONE_X_SCALAR } from 'src/constants/rate-controller';
import { ADMIN, RATE_ADMIN, UPDATE_PAUSE_ADMIN } from 'src/constants/roles';
import { useBoolean } from 'src/hooks/use-boolean';
import ControllerSetRateConfigDialog from 'src/dialogs/controllers/set-rate-config';
import ComputerSetRateConfigDialog from 'src/dialogs/computers/set-rate-config';
import ControllerSetPidConfigDialog from 'src/dialogs/controllers/set-pid-config';
import { AddressZero } from '@ethersproject/constants';
import ControllerManuallyPushRateDialog from 'src/dialogs/controllers/manually-push-rate';
import ControllerSetUpdatesPausedDialog from 'src/dialogs/controllers/set-updates-paused';
import SetSlopeConfigDialog from 'src/dialogs/computers/set-slope-config';
import KinkedSlopeChart from 'src/components/KinkedSlopeChart';
import { hi } from 'date-fns/locale';
import HistoricalRatesComputerSetConfigDialog from 'src/dialogs/computers/historical-rates-set-config';
import HistoricalRatesQueue from 'src/components/historical-rates/historical-rates-queue';
import HistoricalRatesDisplay from 'src/components/historical-rates/historical-rates-display';
import { useMemo } from 'react';

const pythiaProtocolMetadataAbi =
  '[{"inputs":[],"name":"ADRASTIA_CORE_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"ADRASTIA_PERIPHERY_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"ADRASTIA_PROTOCOL_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"}]';

// Adrastia Core v3 interfaces
const updateableInterface = new utils.Interface(updateableAbi.abi);
const erc165Interface = new utils.Interface(erc165Abi.abi);
const erc20Interface = new utils.Interface(erc20Abi.abi);
const pythiaProtocolMetadataInterface = new utils.Interface(pythiaProtocolMetadataAbi);
const accessControlEnumerableInterface = new utils.Interface(accessControlEnumerableAbi.abi);
const oracleInterface = new utils.Interface(oracleAbi.abi);

// Adrastia Periphery interfaces
const rateControllerInterface = new utils.Interface(rateControllerAbi.abi);
const capControllerInterface = new utils.Interface(capControllerAbi.abi);
const pidControllerInterface = new utils.Interface(pidControllerAbi.abi);
const erc20MutationComputerInterface = new utils.Interface(erc20MutationComputerAbi.abi);
const oracleMutationComputerInterface = new utils.Interface(oracleMutationComputerAbi.abi);
const slopedOracleMutationComputerInterface = new utils.Interface(
  slopedOracleMutationComputerAbi.abi
);
const historicalRatesComputerInterface = new utils.Interface(historicalRatesComputerAbi.abi);

const MAX_RATE = BigNumber.from(2).pow(64).sub(1);

const parsePriceAsFloat = (price: BigNumber, decimals: number) => {
  return parseFloat(formatUnits(price, decimals));
};

export default function ControllerAssetView() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { account } = useEthers();

  const controllerSetConfigDialogOpen = useBoolean();
  const computerSetConfigDialogOpen = useBoolean();
  const controllerSetPidConfigDialogOpen = useBoolean();
  const controllerManuallyPushRateDialogOpen = useBoolean();
  const controllerSetUpdatesPausedDialogOpen = useBoolean();
  const computerSetSlopeConfigDialogOpen = useBoolean();
  const historicalRatesComputerSetConfigDialogOpen = useBoolean();
  const historicalRatesComputerSetDefaultConfigDialogOpen = useBoolean();

  var { networkName, oracleAddress, assetAddress } = useParams();

  const oracle = config.chains[networkName!].controllers?.[oracleAddress!];

  const permissions = oracle?.permissions ?? {
    setConfig: {
      role: RATE_ADMIN,
    },
    setSlopeConfig: {
      role: RATE_ADMIN,
    },
    manuallyPushRate: {
      role: ADMIN,
    },
    setPidConfig: {
      role: RATE_ADMIN,
    },
    setUpdatesPaused: {
      role: UPDATE_PAUSE_ADMIN,
    },
  };

  const contract = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, rateControllerInterface) as RateController),
    [oracleAddress]
  );

  const updateableContract = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, updateableInterface) as IUpdateable),
    [oracleAddress]
  );

  const contractErc165 = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, erc165Interface) as IERC165),
    [oracleAddress]
  );

  const contractMetadata = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : new Contract(oracleAddress, pythiaProtocolMetadataInterface),
    [oracleAddress]
  );

  const contractErc20MutationComputer = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, erc20MutationComputerInterface) as Erc20MutationComputer),
    [oracleAddress]
  );

  const contractOracleMutationComputer = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, oracleMutationComputerInterface) as OracleMutationComputer),
    [oracleAddress]
  );

  const contractCapController = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, capControllerInterface) as CapController),
    [oracleAddress]
  );

  const contractPidController = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, pidControllerInterface) as PidController),
    [oracleAddress]
  );

  const contractAccessControlEnumerable = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(
            oracleAddress,
            accessControlEnumerableInterface
          ) as IAccessControlEnumerable),
    [oracleAddress]
  );

  const contractSlopedOracleMutationComputer = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(
            oracleAddress,
            slopedOracleMutationComputerInterface
          ) as SlopedOracleMutationComputer),
    [oracleAddress]
  );

  const contractHistoricalRatesComputer = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(
            oracleAddress,
            historicalRatesComputerInterface
          ) as HistoricalRatesComputer),
    [oracleAddress]
  );

  const contractAsset = useMemo(
    () => (assetAddress === undefined ? undefined : new Contract(assetAddress, erc20Interface)),
    [assetAddress]
  );

  const oracleName = useCall(
    contractMetadata && {
      contract: contractMetadata,
      method: 'name',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const chainId = config.chains[networkName!].chainId;

  const blockNumbers = useBlockNumbers();
  const blockNumber = Object.entries(blockNumbers).find((value) => {
    return value[0] == chainId.toString();
  })?.[1];

  const maxFilterBlocks = oracle?.maxFilterBlocks ?? config.chains[networkName!].maxFilterBlocks;
  const fromBlock = useMemo(() => {
    return maxFilterBlocks === undefined
      ? 0
      : blockNumber === undefined
      ? undefined
      : Math.max(0, (blockNumber ?? 0) - maxFilterBlocks);
  }, [chainId, maxFilterBlocks, oracleAddress, assetAddress, !!blockNumber]);

  const toBlock = useMemo(() => {
    return blockNumber;
  }, [chainId, maxFilterBlocks, oracleAddress, assetAddress, !!blockNumber]);

  const logs = useLogs(
    contract &&
      fromBlock !== undefined && {
        contract: contract,
        event: 'RateUpdated',
        args: [assetAddress],
      },
    {
      chainId: config.chains[networkName!].chainId,
      isStatic: true,
      refresh: 'never',
      fromBlock: fromBlock,
      toBlock: toBlock,
    }
  );

  const isPeriodicEip1559 = useCall(
    contractErc165 && {
      contract: contractErc165,
      method: 'supportsInterface',
      args: [IPERIODIC_ID],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const isPeriodic =
    oracle?.type === 'rateController' ||
    oracle?.type === 'capController' ||
    oracle?.type === 'pidController' ||
    isPeriodicEip1559?.value?.[0];

  const isUpdateableEip1559 = useCall(
    contractErc165 && {
      contract: contractErc165,
      method: 'supportsInterface',
      args: [IUPDATEABLE_ID],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const isUpdateable =
    oracle?.type === 'rateController' ||
    oracle?.type === 'capController' ||
    oracle?.type === 'pidController' ||
    isUpdateableEip1559?.value?.[0];

  const secureConsultation = useCall(
    {
      contract: contract!,
      method: 'getRateAt',
      args: [assetAddress!, 0],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const instantConsultation = useCall(
    {
      contract: contract!,
      method: 'computeRate',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const lastUpdateTime = useCall(
    {
      contract: updateableContract!,
      method: 'lastUpdateTime',
      args: [ethers.utils.hexZeroPad(assetAddress!, 32)],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const period = useCall(
    {
      contract: contract!,
      method: 'period',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const assetNameErc20 = useCall(
    assetAddress !== undefined &&
      contractAsset &&
      config.chains[networkName!]?.tokens[assetAddress!] === undefined && {
        contract: contractAsset,
        method: 'name',
        args: [],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const assetSymbolErc20 = useCall(
    assetAddress !== undefined &&
      contractAsset &&
      config.chains[networkName!]?.tokens[assetAddress!] === undefined && {
        contract: contractAsset,
        method: 'symbol',
        args: [],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const rateConfig = useCall(
    {
      contract: contract!,
      method: 'getConfig',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const slopeConfig = useCall(
    {
      contract: contractSlopedOracleMutationComputer!,
      method: 'getSlopeConfig',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const erc20MutationComputerConfig = useCall(
    {
      contract: contractErc20MutationComputer!,
      method: 'getConfig',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const defaultOneXScalar = useCall(
    {
      contract: contractErc20MutationComputer!,
      method: 'defaultOneXScalar',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const decimalsOffset = useCall(
    {
      contract: contractErc20MutationComputer!,
      method: 'decimalsOffset',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const sourceOracle = useCall(
    {
      contract: contractOracleMutationComputer!,
      method: 'oracle',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const updateThreshold = useCall(
    {
      contract: contractCapController!,
      method: 'getChangeThreshold',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const changePrecision = useCall(
    {
      contract: contractCapController!,
      method: 'CHANGE_PRECISION',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const pidData = useCall(
    {
      contract: contractPidController!,
      method: 'pidData',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const inputAndErrorOracle = useCall(
    {
      contract: contractPidController!,
      method: 'getInputAndErrorOracle',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const contractInputAndErrorOracle = useMemo(
    () =>
      inputAndErrorOracle?.value?.[0] != null &&
      new Contract(inputAndErrorOracle?.value?.[0]!, oracleInterface),
    [inputAndErrorOracle?.value?.[0]]
  );
  const inputAndErrorOracleDecimalsCall = useCall(
    inputAndErrorOracle?.value?.[0] != null &&
      contractInputAndErrorOracle && {
        contract: contractInputAndErrorOracle,
        method: 'liquidityDecimals',
        args: [],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const inputAndErrorOracleDecimals = inputAndErrorOracleDecimalsCall?.value?.[0] ?? 0;

  const defaultInputAndErrorOracle = useCall(
    {
      contract: contractPidController!,
      method: 'getInputAndErrorOracle',
      args: [AddressZero],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const contractDefaultInputAndErrorOracle = useMemo(
    () =>
      defaultInputAndErrorOracle?.value?.[0] != null &&
      new Contract(defaultInputAndErrorOracle?.value?.[0]!, oracleInterface),
    [defaultInputAndErrorOracle?.value?.[0]]
  );
  const defaultInputAndErrorOracleDecimals = useCall(
    defaultInputAndErrorOracle?.value?.[0] != null &&
      contractDefaultInputAndErrorOracle && {
        contract: contractDefaultInputAndErrorOracle,
        method: 'liquidityDecimals',
        args: [],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const updatesPaused = useCall(
    {
      contract: contract!,
      method: 'areUpdatesPaused',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const canSetConfigCall = useCall(
    contractAccessControlEnumerable &&
      account !== undefined && {
        contract: contractAccessControlEnumerable,
        method: 'hasRole',
        args: [permissions.setConfig.role, account],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const canSetConfig = canSetConfigCall?.value?.[0] === true;
  const canSetSlopeConfigCall = useCall(
    contractAccessControlEnumerable &&
      account !== undefined && {
        contract: contractAccessControlEnumerable,
        method: 'hasRole',
        args: [permissions.setSlopeConfig.role, account],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const canSetSlopeConfig = canSetSlopeConfigCall?.value?.[0] === true;
  const canSetPidConfig = useCall(
    contractAccessControlEnumerable &&
      account !== undefined && {
        contract: contractAccessControlEnumerable,
        method: 'hasRole',
        args: [permissions.setPidConfig.role, account],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const canManuallyPushRateCall = useCall(
    contractAccessControlEnumerable &&
      account !== undefined && {
        contract: contractAccessControlEnumerable,
        method: 'hasRole',
        args: [permissions.manuallyPushRate.role, account],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const canManuallyPushRate = canManuallyPushRateCall?.value?.[0] === true;
  let canManuallyPushRateWithoutRevert: boolean = rateConfig?.value?.[0] != null;

  if (oracle?.type === 'pidController') {
    if (!pidData?.value?.[0]?.kDDenominator) {
      canManuallyPushRateWithoutRevert = false;
    }
  }

  const canPauseUpdates = useCall(
    contractAccessControlEnumerable &&
      account !== undefined && {
        contract: contractAccessControlEnumerable,
        method: 'hasRole',
        args: [permissions.setUpdatesPaused.role, account],
      },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const historicalRatesComputerConfig = useCall(
    {
      contract: contractHistoricalRatesComputer!,
      method: 'getConfig',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const historicalRatesComputerDefaultConfig = useCall(
    {
      contract: contractHistoricalRatesComputer!,
      method: 'getConfig',
      args: [AddressZero],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const isUsingDefaultHistoricalRatesComputerConfig = useCall(
    {
      contract: contractHistoricalRatesComputer!,
      method: 'isUsingDefaultConfig',
      args: [assetAddress!],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  var usesUpdateThreshold = oracle?.type === 'capController' || updateThreshold?.value?.[0] != null;

  var hasSourceOracle =
    oracle?.type == 'oracleMutationComputer' ||
    oracle?.type === 'slopedOracleMutationComputer' ||
    sourceOracle?.value?.[0] != null;

  var rateConfigType: 'controller' | 'computer' | undefined = undefined;
  var rateConfigDisplay = <></>;
  var slopeConfigDisplay = <></>;
  var pidDataDisplay = <></>;

  if (
    oracle?.type === 'rateController' ||
    oracle?.type === 'capController' ||
    oracle?.type === 'pidController'
  ) {
    rateConfigType = 'controller';
  } else if (
    oracle?.type === 'erc20MutationComputer' ||
    oracle?.type === 'oracleMutationComputer' ||
    oracle?.type === 'slopedOracleMutationComputer' ||
    oracle?.type === 'rateComputer' ||
    oracle?.type === 'historicalRatesComputer'
  ) {
    rateConfigType = 'computer';
  } else if (rateConfig?.value?.[0]?.length == 9) {
    rateConfigType = 'controller';
  } else if (erc20MutationComputerConfig?.value?.[0]?.length == 4) {
    rateConfigType = 'computer';
  }

  const asset = config.chains[networkName!]?.tokens[assetAddress!];

  const assetName = asset?.name || (assetNameErc20?.value?.[0] ?? 'Unknown');
  const assetSymbol = asset?.symbol || (assetSymbolErc20?.value?.[0] ?? assetName);

  const ratePrefix = oracle?.display?.ratePrefix || '';
  const rateSuffix = oracle?.display?.rateSuffix || '';

  const decimals = decimalsOffset?.value?.[0] ?? oracle?.decimals ?? 0;
  const displayDecimals = oracle?.display?.decimals ?? decimals;
  const symbol = oracle?.quoteUnit?.symbol ?? '?';

  const chartLabel =
    assetSymbol +
    (symbol && symbol !== '?' ? '/' + symbol : '') +
    ' ' +
    (oracle?.display?.chartTitle || ' Chart');

  var storedCurrentRate = secureConsultation?.value?.[0]?.current;
  var storedTargetRate = secureConsultation?.value?.[0]?.target;

  var instantCurrentRate = instantConsultation?.value?.[0];
  var instantTargetRate = instantConsultation?.value?.[0];

  const chartSeries = [];

  const dataPoints = oracle?.dataPoints ?? prudentiaGenericDataPoints;

  const rateLabel = dataPoints?.rate?.label ?? prudentiaGenericDataPoints.rate!.label;

  var chartDisplay = undefined;

  if (rateConfigType !== 'computer') {
    if ((logs?.value?.length || 0) > 0) {
      chartSeries.push({
        name: 'Current',
        data: logs!.value!.map((log) => ({
          x: (log.data.timestamp as BigNumber).toNumber() * 1000,
          y: parsePriceAsFloat(log.data.current as BigNumber, displayDecimals ?? 0),
        })),
      });

      chartSeries.push({
        name: 'Target',
        data: logs!.value!.map((log) => ({
          x: (log.data.timestamp as BigNumber).toNumber() * 1000,
          y: parsePriceAsFloat(log.data.target as BigNumber, displayDecimals ?? 0),
        })),
      });

      if (chartSeries.length > 0) {
        chartDisplay = (
          <LiquidityHistoryChart
            title={chartLabel}
            series={chartSeries}
            ratePrefix={ratePrefix}
            rateSuffix={rateSuffix}
            dataPointsConfig={dataPoints}
          ></LiquidityHistoryChart>
        );
      }
    }
  }

  if (rateConfigType === 'controller') {
    const baseRate = rateConfig?.value?.[0].base;
    const minRate = rateConfig?.value?.[0].min;
    const maxRate = rateConfig?.value?.[0].max;
    const maxDecreaseAbsolute = rateConfig?.value?.[0].maxDecrease;
    const maxDecreasePercent = rateConfig?.value?.[0].maxPercentDecrease;
    const maxIncreaseAbsolute = rateConfig?.value?.[0].maxIncrease;
    const maxIncreasePercent = rateConfig?.value?.[0].maxPercentIncrease;
    const rateComputerWeights = rateConfig?.value?.[0].componentWeights;
    const rateComputerAddresses = rateConfig?.value?.[0].components;
    const rateComputers =
      rateComputerWeights &&
      rateComputerAddresses &&
      rateComputerAddresses.length == rateComputerWeights.length &&
      rateComputerAddresses.map((address, index) => {
        return {
          address: address,
          weight: rateComputerWeights[index],
        };
      });

    rateConfigDisplay = (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Rate config
                </Typography>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Base
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={baseRate != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {baseRate == null
                      ? '-'
                      : ratePrefix +
                        (baseRate.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : parsePrice(baseRate, displayDecimals)) +
                        rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Min
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={minRate != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {minRate == null
                      ? '-'
                      : ratePrefix +
                        (minRate.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : parsePrice(minRate, displayDecimals)) +
                        rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxRate != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxRate == null
                      ? '-'
                      : ratePrefix +
                        (maxRate.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : parsePrice(maxRate, displayDecimals)) +
                        rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max increase (absolute)
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxIncreaseAbsolute != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxIncreaseAbsolute == null
                      ? '-'
                      : ratePrefix +
                        (maxIncreaseAbsolute.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : parsePrice(maxIncreaseAbsolute, displayDecimals)) +
                        rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max decrease (absolute)
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxDecreaseAbsolute != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxDecreaseAbsolute == null
                      ? '-'
                      : ratePrefix +
                        (maxDecreaseAbsolute.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : parsePrice(maxDecreaseAbsolute, displayDecimals)) +
                        rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max increase (relative)
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxIncreasePercent != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxIncreasePercent == null ? '-' : maxIncreasePercent / 100 + '%'}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max decrease (relative)
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxDecreasePercent != null || rateConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxDecreasePercent == null ? '-' : maxDecreasePercent / 100 + '%'}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              {oracle?.type !== 'pidController' && (
                <Grid item xs={12} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Computers
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Address</TableCell>
                          <TableCell align="right">Weight</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rateComputers &&
                          rateComputers.map((computer, index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">
                                <AddressDisplay
                                  address={computer.address}
                                  chainId={config.chains[networkName!].chainId}
                                  link={`${PATH_APP.controllers}/${networkName}/${computer.address}/${assetAddress}`}
                                  resolveAddress
                                  hideAddressIfResolved
                                />
                              </TableCell>
                              <TableCell align="right">{computer.weight / 100}%</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {(canSetConfig ||
                canManuallyPushRate === true ||
                canPauseUpdates?.value?.[0] === true) && (
                <Grid item xs={12} alignContent="center" textAlign="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Administration
                  </Typography>
                  {canSetConfig && (
                    <Button
                      sx={{
                        margin: theme.spacing(1),
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        controllerSetConfigDialogOpen.onTrue();
                      }}
                    >
                      Set config
                    </Button>
                  )}
                  {canManuallyPushRate === true && (
                    <Button
                      sx={{
                        margin: theme.spacing(1),
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        controllerManuallyPushRateDialogOpen.onTrue();
                      }}
                      disabled={!canManuallyPushRateWithoutRevert}
                    >
                      Manually push {rateLabel.toLowerCase()}
                    </Button>
                  )}
                  {canPauseUpdates?.value?.[0] === true && (
                    <Button
                      sx={{
                        margin: theme.spacing(1),
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        controllerSetUpdatesPausedDialogOpen.onTrue();
                      }}
                    >
                      {updatesPaused?.value?.[0] === true ? 'Resume updates' : 'Pause updates'}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  } else if (
    rateConfigType === 'computer' &&
    oracle?.type !== 'rateComputer' &&
    oracle?.type !== 'historicalRatesComputer'
  ) {
    const minRate = erc20MutationComputerConfig?.value?.[0]?.min;
    const maxRate = erc20MutationComputerConfig?.value?.[0]?.max;
    const scalar = erc20MutationComputerConfig?.value?.[0]?.scalar;
    const offset = erc20MutationComputerConfig?.value?.[0]?.offset;

    const oneXScalar = defaultOneXScalar?.value?.[0] ?? DEFAULT_ONE_X_SCALAR;

    const scalarDisplay = scalar == null ? '-' : scalar / oneXScalar + 'x';

    rateConfigDisplay = (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Rate config
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Min
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={minRate != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {minRate == null
                      ? '-'
                      : minRate.eq(MAX_RATE)
                      ? 'Max (uint64)'
                      : ratePrefix + parsePrice(minRate, displayDecimals) + rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Max
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={maxRate != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {maxRate == null
                      ? '-'
                      : maxRate.eq(MAX_RATE)
                      ? 'Max (uint64)'
                      : ratePrefix + parsePrice(maxRate, displayDecimals) + rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Scalar
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={scalar != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {scalarDisplay}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Offset
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={offset != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {offset == null
                      ? '-'
                      : ratePrefix + parsePrice(offset, displayDecimals) + rateSuffix}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              {canSetConfig && (
                <Grid item xs={12} alignContent="center" textAlign="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Administration
                  </Typography>
                  <Button
                    sx={{
                      margin: theme.spacing(1),
                    }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      computerSetConfigDialogOpen.onTrue();
                    }}
                  >
                    Set config
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  } else if (oracle?.type === 'historicalRatesComputer') {
    const rateProvider = historicalRatesComputerConfig?.value?.[0]?.rateProvider;
    const rateIndex = historicalRatesComputerConfig?.value?.[0]?.index;
    const highAvailability = historicalRatesComputerConfig?.value?.[0]?.highAvailability;

    rateConfigDisplay = (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Rate config
                </Typography>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Rate controller
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={rateProvider != null || historicalRatesComputerConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {rateProvider == null ? (
                      '-'
                    ) : (
                      <AddressDisplay
                        address={rateProvider}
                        chainId={config.chains[networkName!].chainId}
                        link={`${PATH_APP.controllers}/${networkName}/${rateProvider}/${assetAddress}`}
                        resolveAddress
                        hideAddressIfResolved
                      />
                    )}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Index
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={rateIndex != null || historicalRatesComputerConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {rateIndex == null ? '-' : rateIndex}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  High availability
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={highAvailability != null || historicalRatesComputerConfig?.error != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {highAvailability == null ? '-' : highAvailability?.toString()}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              {canSetConfig && (
                <Grid item xs={12} alignContent="center" textAlign="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Administration
                  </Typography>
                  <Box display="flex" gap={2} alignContent="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={historicalRatesComputerSetConfigDialogOpen.onTrue}
                    >
                      Set config
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={historicalRatesComputerSetDefaultConfigDialogOpen.onTrue}
                    >
                      Set default config
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (oracle?.type === 'slopedOracleMutationComputer' || slopeConfig?.value?.[0] != null) {
    const base = slopeConfig?.value?.[0]?.base;
    const baseSlope = slopeConfig?.value?.[0]?.baseSlope;
    const kink = slopeConfig?.value?.[0]?.kink;
    const kinkSlope = slopeConfig?.value?.[0]?.kinkSlope;

    const scalar = BigNumber.from(
      erc20MutationComputerConfig?.value?.[0]?.scalar ?? DEFAULT_ONE_X_SCALAR
    );
    const scalarDivisor = BigNumber.from(defaultOneXScalar?.value?.[0] ?? DEFAULT_ONE_X_SCALAR);

    const inputDecimals =
      config.chains[networkName!]?.oracles[sourceOracle?.value?.[0] ?? '']?.display?.decimals ??
      decimalsOffset?.value?.[0] ??
      0;
    const inputRatePrefix =
      config.chains[networkName!]?.oracles[sourceOracle?.value?.[0] ?? '']?.display?.ratePrefix ??
      '';
    const inputRateSuffix =
      config.chains[networkName!]?.oracles[sourceOracle?.value?.[0] ?? '']?.display?.rateSuffix ??
      '';

    var baseDecimals = displayDecimals;

    if (!scalar?.eq(0)) {
      if (scalar.lt(scalarDivisor)) {
        const scalarDec = Math.floor(Math.log10(scalar.toNumber()));
        const defaultOneXScalarDec = Math.floor(Math.log10(scalarDivisor.toNumber()));

        baseDecimals = displayDecimals + (defaultOneXScalarDec - scalarDec); // Scale up decimals b/c values will later be scaled down
      } else if (scalar.gt(scalarDivisor)) {
        const scalarDec = Math.floor(Math.log10(scalar.toNumber()));
        const defaultOneXScalarDec = Math.floor(Math.log10(scalarDivisor.toNumber()));

        baseDecimals = displayDecimals - (scalarDec - defaultOneXScalarDec); // Scale down decimals b/c values will later be scaled up
      }
    }

    const slopeDecimals = baseDecimals - inputDecimals;

    const parsedBase = base == null ? base : parsePrice(base, baseDecimals);
    const parsedBaseSlope = baseSlope == null ? baseSlope : parsePrice(baseSlope, slopeDecimals);
    const parsedKink = kink == null ? kink : parsePrice(kink, inputDecimals);
    const parsedKinkSlope = kinkSlope == null ? kinkSlope : parsePrice(kinkSlope, slopeDecimals);

    const baseDisplay = base == null ? '-' : ratePrefix + parsedBase + rateSuffix;
    const baseSlopeDisplay = baseSlope == null ? '-' : ratePrefix + parsedBaseSlope + rateSuffix;
    const kinkDisplay = kink == null ? '-' : inputRatePrefix + parsedKink + inputRateSuffix;
    const kinkSlopeDisplay = kinkSlope == null ? '-' : ratePrefix + parsedKinkSlope + rateSuffix;

    slopeConfigDisplay = (
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Slope config"
            sx={{
              textAlign: 'center',
            }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Base
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={base != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {baseDisplay}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Base slope
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={baseSlope != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {baseSlopeDisplay}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Kink
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={kink != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {kinkDisplay}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                  fontWeight={600}
                >
                  Kink slope
                </Typography>
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  type="textRow"
                  ready={kinkSlope != null}
                  style={{
                    width: '50%',
                    height: theme.typography.body1.fontSize,
                    marginTop: theme.typography.body1.fontSize,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                    {kinkSlopeDisplay}
                  </Typography>
                </ReactPlaceholder>
              </Grid>
              {canSetSlopeConfig && (
                <Grid item xs={12} alignContent="center" textAlign="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Administration
                  </Typography>
                  <Button
                    sx={{
                      margin: theme.spacing(1),
                    }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      computerSetSlopeConfigDialogOpen.onTrue();
                    }}
                  >
                    Set config
                  </Button>
                </Grid>
              )}
              {parsedBase && parsedBaseSlope && parsedKink && parsedKinkSlope && (
                <Grid item xs={12} alignContent="center" textAlign="center">
                  <KinkedSlopeChart
                    base={Number(parsedBase)}
                    baseSlope={Number(parsedBaseSlope)}
                    kink={Number(parsedKink)}
                    kinkSlope={Number(parsedKinkSlope)}
                    xLabelPrefix={inputRatePrefix}
                    xLabelSuffix={inputRateSuffix}
                    yLabelPrefix={ratePrefix}
                    yLabelSuffix={rateSuffix}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (oracle?.type === 'pidController' || pidData?.value?.[0] != null) {
    const integral = pidData?.value?.state?.iTerm;
    const lastInput = pidData?.value?.state?.lastInput;
    const lastError = pidData?.value?.state?.lastError;

    const sourceOracle = inputAndErrorOracle?.value?.[0];
    const kP_N = pidData?.value?.config?.kPNumerator;
    const kP_D = pidData?.value?.config?.kPDenominator;
    const kI_N = pidData?.value?.config?.kINumerator;
    const kI_D = pidData?.value?.config?.kIDenominator;
    const kD_N = pidData?.value?.config?.kDNumerator;
    const kD_D = pidData?.value?.config?.kDDenominator;
    const proportionalOnMeasurement = pidData?.value?.config?.proportionalOnMeasurement;
    const derivativeOnMeasurement = pidData?.value?.config?.derivativeOnMeasurement;
    const transformer = pidData?.value?.config?.transformer;

    const kP =
      kP_N != null && kP_D != null
        ? formatUnits(
            BigNumber.from(kP_N)
              .mul(1_000_000_000)
              .div(kP_D == 0 ? 1 : kP_D),
            9
          )
        : undefined;
    const kPNormalized =
      kP_N != null && kP_D != null
        ? formatUnits(
            BigNumber.from(kP_N)
              ?.mul(1_000_000_000)
              ?.div(kP_D == 0 ? 1 : kP_D) ?? 0,
            9 - (inputAndErrorOracleDecimals - decimals)
          )
        : undefined;
    const kI =
      kI_N != null && kI_D != null
        ? formatUnits(
            BigNumber.from(kI_N)
              .mul(1_000_000_000)
              .div(kI_D == 0 ? 1 : kI_D),
            9
          )
        : undefined;
    const kINormalized =
      kI_N != null && kI_D != null
        ? formatUnits(
            BigNumber.from(kI_N)
              ?.mul(1_000_000_000)
              ?.div(kI_D == 0 ? 1 : kI_D) ?? 0,
            9 - (inputAndErrorOracleDecimals - decimals)
          )
        : undefined;
    const kD =
      kD_N != null && kD_D != null
        ? formatUnits(
            BigNumber.from(kD_N)
              .mul(1_000_000_000)
              .div(kD_D == 0 ? 1 : kD_D),
            9
          )
        : undefined;
    const kDNormalized =
      kD_N != null && kD_D != null
        ? formatUnits(
            BigNumber.from(kD_N)
              ?.mul(1_000_000_000)
              ?.div(kD_D == 0 ? 1 : kD_D) ?? 0,
            9 - (inputAndErrorOracleDecimals - decimals)
          )
        : undefined;

    var lastValueScalar = BigNumber.from(1);
    var lastValueDisplayDecimals =
      inputAndErrorOracleDecimals +
      (oracle?.decimals != null && oracle?.display?.decimals != null
        ? oracle.display.decimals - oracle.decimals
        : 0);
    if (lastValueDisplayDecimals < 0) {
      lastValueScalar = BigNumber.from(10).pow(-lastValueDisplayDecimals);
      lastValueDisplayDecimals = 0;
    }

    const integralDisplay =
      integral == null
        ? '-'
        : (oracle?.display?.ratePrefix ?? '') +
          parsePrice(integral, displayDecimals) +
          (oracle?.display?.rateSuffix ?? '');
    const lastInputDisplay =
      lastInput == null
        ? '-'
        : (oracle?.display?.ratePrefix ?? '') +
          parsePrice(lastInput.mul(lastValueScalar), lastValueDisplayDecimals) +
          (oracle?.display?.rateSuffix ?? '');
    const lastErrorDisplay =
      lastError == null
        ? '-'
        : (oracle?.display?.ratePrefix ?? '') +
          parsePrice(lastError.mul(lastValueScalar), lastValueDisplayDecimals) +
          (oracle?.display?.rateSuffix ?? '');

    pidDataDisplay = (
      <>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    PID config
                  </Typography>
                </Grid>
                <Grid item xs={6} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Value and error oracle
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={sourceOracle != null || inputAndErrorOracle?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {sourceOracle == null ? (
                        '-'
                      ) : (
                        <AddressDisplay
                          address={sourceOracle}
                          chainId={chainId}
                          link={`${PATH_APP.dataFeeds}/${networkName}/${sourceOracle}/${assetAddress}`}
                          resolveAddress
                          hideAddressIfResolved
                        />
                      )}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={6} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Transformer
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={transformer != null || inputAndErrorOracle?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {transformer == null ? (
                        '-'
                      ) : (
                        <AddressDisplay
                          address={transformer}
                          chainId={chainId}
                          resolveAddress
                          hideAddressIfResolved
                        />
                      )}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Proportional gains (kP)
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={kP != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {kP == null ? '-' : kP.toString()}
                    </Typography>
                    <Typography variant="body2" fontWeight={'light'} component="div" align="center">
                      <em>{`Normalized: ${kPNormalized ?? '-'}`}</em>
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Integral gains (kI)
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={kI != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {kI == null ? '-' : kI.toString()}
                    </Typography>
                    <Typography variant="body2" fontWeight={'light'} component="div" align="center">
                      <em>{`Normalized: ${kINormalized ?? '-'}`}</em>
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Derivative gains (kD)
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={kD != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {kD == null ? '-' : kD.toString()}
                    </Typography>
                    <Typography variant="body2" fontWeight={'light'} component="div" align="center">
                      <em>{`Normalized: ${kDNormalized ?? '-'}`}</em>
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={6} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Proportional
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={proportionalOnMeasurement != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {proportionalOnMeasurement == null
                        ? '-'
                        : proportionalOnMeasurement
                        ? 'On measurement'
                        : 'On error'}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={6} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Derivative
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={derivativeOnMeasurement != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {derivativeOnMeasurement == null
                        ? '-'
                        : derivativeOnMeasurement
                        ? 'On measurement'
                        : 'On error'}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                {canSetPidConfig?.value?.[0] === true && (
                  <Grid item xs={12} alignContent="center" textAlign="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Administration
                    </Typography>
                    <Button
                      sx={{
                        margin: theme.spacing(1),
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        controllerSetPidConfigDialogOpen.onTrue();
                      }}
                      disabled={rateConfig?.value?.[0] == null}
                    >
                      Set config
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    PID state
                  </Typography>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Integral
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={integral != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {integralDisplay}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Last input
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={lastInput != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {lastInputDisplay}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                    fontWeight={600}
                  >
                    Last error
                  </Typography>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="textRow"
                    ready={lastError != null || pidData?.error != null}
                    style={{
                      width: '50%',
                      height: theme.typography.body1.fontSize,
                      marginTop: theme.typography.body1.fontSize,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      {lastErrorDisplay}
                    </Typography>
                  </ReactPlaceholder>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </>
    );
  }

  var currentRateDisplaySize = 4;
  var displayTargetRate = true;

  if (
    oracle?.type === 'erc20MutationComputer' ||
    oracle?.type === 'oracleMutationComputer' ||
    oracle?.type === 'slopedOracleMutationComputer' ||
    rateConfigType === 'computer'
  ) {
    currentRateDisplaySize = 12;
    displayTargetRate = false;
  }

  var periodDisplaySize = 12;
  if (usesUpdateThreshold) {
    periodDisplaySize = 6;
  }

  var updateThresholdDisplaySize = 6;
  if (!isPeriodic) {
    updateThresholdDisplaySize = 12;
  }

  const title =
    assetName + (assetSymbol && assetName !== assetSymbol ? ' (' + assetSymbol + ')' : '');
  const oracleTitle = oracle?.name || oracleName?.value?.[0] || shortenIfAddress(oracleAddress);
  const oracleDesc = oracle?.desc || undefined;

  if (
    !networkName ||
    !oracleAddress ||
    !isAddress(oracleAddress) ||
    !assetAddress ||
    !isAddress(assetAddress) ||
    !config.chains[networkName!]
  ) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Page
      title={title + ' | ' + oracleTitle + ' | ' + config.chains[networkName!].name || '404'}
      contentTitle={title}
      contentSubtitle={
        oracleTitle +
        ' on ' +
        config.chains[networkName!].name +
        (oracleDesc ? ' - ' + oracleDesc : '')
      }
      contentSubtitleLink={`${PATH_APP.controllers}/${networkName}/${oracleAddress}`}
    >
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          {rateConfigType === 'controller' && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={currentRateDisplaySize} alignContent="center">
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                        align="center"
                        fontWeight={600}
                      >
                        Current {rateLabel.toLowerCase()} (Stored)
                      </Typography>
                      <ReactPlaceholder
                        showLoadingAnimation={true}
                        type="textRow"
                        ready={storedCurrentRate != null || secureConsultation?.error != null}
                        style={{
                          width: '50%',
                          height: theme.typography.body1.fontSize,
                          marginTop: theme.typography.body1.fontSize,
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={'light'}
                          component="div"
                          align="center"
                        >
                          {storedCurrentRate == null
                            ? '-'
                            : ratePrefix +
                              parsePrice(storedCurrentRate, displayDecimals) +
                              rateSuffix}
                        </Typography>
                      </ReactPlaceholder>
                    </Grid>
                    {displayTargetRate && (
                      <Grid item xs={4} alignContent="center">
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                          align="center"
                          fontWeight={600}
                        >
                          Target {rateLabel.toLowerCase()} (Stored)
                        </Typography>
                        <ReactPlaceholder
                          showLoadingAnimation={true}
                          type="textRow"
                          ready={storedTargetRate != null || secureConsultation?.error != null}
                          style={{
                            width: '50%',
                            height: theme.typography.body1.fontSize,
                            marginTop: theme.typography.body1.fontSize,
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={'light'}
                            component="div"
                            align="center"
                          >
                            {storedTargetRate == null
                              ? '-'
                              : ratePrefix +
                                parsePrice(storedTargetRate, displayDecimals) +
                                rateSuffix}
                          </Typography>
                        </ReactPlaceholder>
                      </Grid>
                    )}
                    {isUpdateable && (
                      <Grid item xs={4} alignContent="center">
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                          align="center"
                          fontWeight={600}
                        >
                          Last update
                        </Typography>
                        <ReactPlaceholder
                          showLoadingAnimation={true}
                          type="textRow"
                          ready={lastUpdateTime?.value !== undefined}
                          style={{
                            width: '50%',
                            height: theme.typography.body1.fontSize,
                            marginTop: theme.typography.body1.fontSize,
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={'light'}
                            component="div"
                            align="center"
                          >
                            <>
                              {lastUpdateTime === undefined || lastUpdateTime.value === undefined
                                ? 'Loading...'
                                : lastUpdateTime.value[0].eq(0)
                                ? 'Never'
                                : fDuration(
                                    Date.now() / 1000 - lastUpdateTime.value[0].toNumber()
                                  ) + ' ago'}
                              {updatesPaused?.value?.[0] === true ? ' (Paused)' : ''}
                            </>
                          </Typography>
                        </ReactPlaceholder>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Instant {rateLabel.toLowerCase()}
                    </Typography>
                    <ReactPlaceholder
                      showLoadingAnimation={true}
                      type="textRow"
                      ready={instantCurrentRate != null || instantConsultation?.error != null}
                      style={{
                        width: '50%',
                        height: theme.typography.body1.fontSize,
                        marginTop: theme.typography.body1.fontSize,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={'light'}
                        component="div"
                        align="center"
                      >
                        {instantCurrentRate == null
                          ? '-'
                          : ratePrefix +
                            parsePrice(instantCurrentRate, displayDecimals) +
                            rateSuffix}
                      </Typography>
                    </ReactPlaceholder>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {(isPeriodic || usesUpdateThreshold) && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    {isPeriodic && (
                      <Grid item xs={periodDisplaySize} alignContent="center">
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                          align="center"
                          fontWeight={600}
                        >
                          Period
                        </Typography>
                        <ReactPlaceholder
                          showLoadingAnimation={true}
                          type="textRow"
                          ready={period?.value != null}
                          style={{
                            width: '50%',
                            height: theme.typography.body1.fontSize,
                            marginTop: theme.typography.body1.fontSize,
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={'light'}
                            component="div"
                            align="center"
                          >
                            {period?.value == null
                              ? 'Loading...'
                              : fDuration(period.value[0]?.toNumber())}
                          </Typography>
                        </ReactPlaceholder>
                      </Grid>
                    )}
                    {usesUpdateThreshold && (
                      <Grid item xs={updateThresholdDisplaySize} alignContent="center">
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                          align="center"
                          fontWeight={600}
                        >
                          Update threshold
                        </Typography>
                        <ReactPlaceholder
                          showLoadingAnimation={true}
                          type="textRow"
                          ready={updateThreshold?.value != null && changePrecision?.value != null}
                          style={{
                            width: '50%',
                            height: theme.typography.body1.fontSize,
                            marginTop: theme.typography.body1.fontSize,
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={'light'}
                            component="div"
                            align="center"
                          >
                            {updateThreshold?.value == null || changePrecision?.value == null
                              ? '-'
                              : (updateThreshold?.value?.[0] * 100) /
                                  changePrecision?.value?.[0].toNumber() +
                                '%'}
                          </Typography>
                        </ReactPlaceholder>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {hasSourceOracle && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} alignContent="center">
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                        align="center"
                        fontWeight={600}
                      >
                        Oracle source
                      </Typography>
                      <ReactPlaceholder
                        showLoadingAnimation={true}
                        type="textRow"
                        ready={sourceOracle?.value != null}
                        style={{
                          width: '50%',
                          height: theme.typography.body1.fontSize,
                          marginTop: theme.typography.body1.fontSize,
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={'light'}
                          component="div"
                          align="center"
                        >
                          {sourceOracle?.value == null ? (
                            'Loading...'
                          ) : (
                            <AddressDisplay
                              address={sourceOracle.value[0]}
                              chainId={chainId}
                              link={`${PATH_APP.dataFeeds}/${networkName}/${sourceOracle.value[0]}/${assetAddress}`}
                              resolveAddress
                              hideAddressIfResolved
                            />
                          )}
                        </Typography>
                      </ReactPlaceholder>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {rateConfigDisplay}
          {pidDataDisplay}
          {slopeConfigDisplay}
          {chartDisplay && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  {chartDisplay}
                  <Typography variant="caption">{`* Data shown is limited to blocks from ${fromBlock} to ${toBlock} (${commify(
                    (blockNumber ?? 0) - (fromBlock ?? 0)
                  )} blocks).`}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {oracle?.type === 'historicalRatesComputer' &&
            historicalRatesComputerConfig?.value?.[0] != null && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Rates queue" sx={{ textAlign: 'center' }} />
                  <CardContent>
                    <HistoricalRatesDisplay
                      networkName={networkName}
                      contractAddress={historicalRatesComputerConfig?.value?.[0].rateProvider}
                      token={assetAddress}
                      selectedIndex={historicalRatesComputerConfig?.value?.[0].index}
                      highAvailability={historicalRatesComputerConfig?.value?.[0].highAvailability}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
        </Grid>
      </Container>
      {rateConfigType === 'controller' && (
        <ControllerSetRateConfigDialog
          dialogOpen={controllerSetConfigDialogOpen}
          networkName={networkName}
          token={assetAddress}
          rateController={contract}
          controllerType={oracle?.type}
          decimals={displayDecimals}
          ratePrefix={ratePrefix}
          rateSuffix={rateSuffix}
          values={{
            base: rateConfig?.value?.[0]?.base,
            min: rateConfig?.value?.[0]?.min,
            max: rateConfig?.value?.[0]?.max,
            maxIncrease: rateConfig?.value?.[0]?.maxIncrease,
            maxDecrease: rateConfig?.value?.[0]?.maxDecrease,
            maxPercentIncrease: rateConfig?.value?.[0]?.maxPercentIncrease,
            maxPercentDecrease: rateConfig?.value?.[0]?.maxPercentDecrease,
            computerWeights: rateConfig?.value?.[0]?.componentWeights,
            computerAddresses: rateConfig?.value?.[0]?.components,
          }}
          dataPoints={dataPoints}
        />
      )}
      {rateConfig?.value?.[0] != null && ( // We can only manually push rate if we have a rate config
        <>
          <ControllerManuallyPushRateDialog
            dialogOpen={controllerManuallyPushRateDialogOpen}
            networkName={networkName}
            token={assetAddress}
            controller={contract}
            decimals={displayDecimals}
            ratePrefix={ratePrefix}
            rateSuffix={rateSuffix}
            dataPoints={dataPoints}
          />
        </>
      )}
      {pidData?.value?.[0] != null &&
        defaultInputAndErrorOracle?.value?.[0] != null &&
        defaultInputAndErrorOracleDecimals?.value?.[0] != null && (
          <ControllerSetPidConfigDialog
            dialogOpen={controllerSetPidConfigDialogOpen}
            networkName={networkName}
            token={assetAddress}
            rateController={contractPidController}
            decimals={decimals}
            defaultInputAndErrorOracle={defaultInputAndErrorOracle?.value?.[0]}
            defaultInputAndErrorOracleDecimals={defaultInputAndErrorOracleDecimals?.value?.[0]}
            values={{
              inputAndErrorOracle: pidData?.value?.config?.inputAndErrorOracle,
              kPNumerator: BigNumber.from(pidData?.value?.config?.kPNumerator),
              kPDenominator: BigNumber.from(pidData?.value?.config?.kPDenominator),
              kINumerator: BigNumber.from(pidData?.value?.config?.kINumerator),
              kIDenominator: BigNumber.from(pidData?.value?.config?.kIDenominator),
              kDNumerator: BigNumber.from(pidData?.value?.config?.kDNumerator),
              kDDenominator: BigNumber.from(pidData?.value?.config?.kDDenominator),
              transformer: pidData?.value?.config?.transformer,
              proportionalOnMeasurement: pidData?.value?.config?.proportionalOnMeasurement,
              derivativeOnMeasurement: pidData?.value?.config?.derivativeOnMeasurement,
            }}
          />
        )}
      {rateConfigType == 'computer' && defaultOneXScalar?.value?.[0] != null && (
        <ComputerSetRateConfigDialog
          dialogOpen={computerSetConfigDialogOpen}
          networkName={networkName}
          token={assetAddress}
          rateComputer={contractErc20MutationComputer}
          decimals={displayDecimals}
          defaultOneXScalar={defaultOneXScalar?.value?.[0]}
          ratePrefix={ratePrefix}
          rateSuffix={rateSuffix}
          values={{
            min: erc20MutationComputerConfig?.value?.[0]?.min,
            max: erc20MutationComputerConfig?.value?.[0]?.max,
            scalar:
              erc20MutationComputerConfig?.value?.[0]?.scalar != null
                ? BigNumber.from(erc20MutationComputerConfig?.value?.[0]?.scalar)
                : undefined,
            offset: erc20MutationComputerConfig?.value?.[0]?.offset,
          }}
          dataPoints={dataPoints}
        />
      )}
      {rateConfigType === 'controller' && (
        <ControllerSetUpdatesPausedDialog
          dialogOpen={controllerSetUpdatesPausedDialogOpen}
          networkName={networkName}
          token={assetAddress}
          controller={contract}
          values={{
            paused: updatesPaused?.value?.[0],
          }}
        />
      )}
      {slopeConfig?.value?.[0] != null &&
        decimalsOffset?.value?.[0] != null &&
        defaultOneXScalar?.value?.[0] != null &&
        erc20MutationComputerConfig?.value?.[0] != null && (
          <SetSlopeConfigDialog
            dialogOpen={computerSetSlopeConfigDialogOpen}
            networkName={networkName}
            contractAddress={oracleAddress}
            token={assetAddress}
            inputDecimals={
              config.chains[networkName]?.oracles[sourceOracle?.value?.[0] ?? '']?.display
                ?.decimals ?? decimalsOffset?.value?.[0]
            }
            inputRatePrefix={
              config.chains[networkName]?.oracles[sourceOracle?.value?.[0] ?? '']?.display
                ?.ratePrefix ?? ''
            }
            inputRateSuffix={
              config.chains[networkName]?.oracles[sourceOracle?.value?.[0] ?? '']?.display
                ?.rateSuffix ?? ''
            }
            decimals={displayDecimals}
            ratePrefix={ratePrefix}
            rateSuffix={rateSuffix}
            defaultOneXScalar={BigNumber.from(defaultOneXScalar?.value?.[0])}
            scalar={BigNumber.from(erc20MutationComputerConfig?.value?.[0]?.scalar)}
            values={{
              base: slopeConfig?.value?.[0]?.base,
              baseSlope: slopeConfig?.value?.[0]?.baseSlope,
              kink: slopeConfig?.value?.[0]?.kink,
              kinkSlope: slopeConfig?.value?.[0]?.kinkSlope,
            }}
          />
        )}
      <HistoricalRatesComputerSetConfigDialog
        dialogOpen={historicalRatesComputerSetConfigDialogOpen}
        networkName={networkName}
        contractAddress={oracleAddress}
        token={assetAddress}
        actualToken={assetAddress}
        values={{
          rateProvider: historicalRatesComputerConfig?.value?.[0]?.rateProvider,
          index: historicalRatesComputerConfig?.value?.[0]?.index,
          highAvailability: historicalRatesComputerConfig?.value?.[0]?.highAvailability,
          isUsingDefaultConfig:
            historicalRatesComputerDefaultConfig?.error != null
              ? false
              : isUsingDefaultHistoricalRatesComputerConfig?.value?.[0] ?? true,
        }}
        dataPoints={dataPoints}
      />
      <HistoricalRatesComputerSetConfigDialog
        dialogOpen={historicalRatesComputerSetDefaultConfigDialogOpen}
        networkName={networkName}
        contractAddress={oracleAddress}
        token={AddressZero}
        actualToken={assetAddress}
        values={{
          rateProvider: historicalRatesComputerDefaultConfig?.value?.[0]?.rateProvider,
          index: historicalRatesComputerDefaultConfig?.value?.[0]?.index,
          highAvailability: historicalRatesComputerDefaultConfig?.value?.[0]?.highAvailability,
          isUsingDefaultConfig: true,
        }}
      />
    </Page>
  );
}
