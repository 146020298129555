import { BigNumber } from 'ethers';

export const DEFAULT_ONE_X_SCALAR = 1_000_000;
export const DEFAULT_100_PERCENT = 10_000;
export const MAX_RATE = BigNumber.from(2).pow(64).sub(1);
export const MAX_PERCENT_INCREASE = BigNumber.from(2).pow(32).sub(1);
export const MAX_PERCENT_DECREASE = BigNumber.from(2).pow(16).sub(1);
export const MAX_COMPONENT_WEIGHT = BigNumber.from(2).pow(16).sub(1);

export const MIN_OFFSET = BigNumber.from(2).pow(63).mul(-1);
export const MAX_OFFSET = BigNumber.from(2).pow(63).sub(1);

export const MAX_SCALAR = BigNumber.from(2).pow(32).sub(1);
