import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { BigNumber, Contract, ethers } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import config, { DataPointsConfig, prudentiaGenericDataPoints } from 'src/adrastia.config';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatUnits, parseUnits, commify } from '@ethersproject/units';
import { DEFAULT_100_PERCENT, MAX_PERCENT_INCREASE, MAX_RATE } from 'src/constants/rate-controller';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { RateController } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import {
  bigNumberMaxValidator,
  bigNumberMinValidator,
  bigNumberValidator,
} from 'src/forms/validation/big-number';
import CopyConfigDialog from '../copy-config-dialog';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type CurrentValues = {
  base?: BigNumber;
  min?: BigNumber;
  max?: BigNumber;
  maxIncrease?: BigNumber;
  maxDecrease?: BigNumber;
  maxPercentIncrease?: number;
  maxPercentDecrease?: number;
  computerWeights?: number[];
  computerAddresses?: string[];
};

type SetRateConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  token: string;
  rateController?: RateController;
  decimals: number;
  ratePrefix?: string;
  rateSuffix?: string;
  controllerType?: string;
  values?: CurrentValues;
  dataPoints?: DataPointsConfig;
};

const steps = ['Define parameters', 'Review and submit'];

// Custom Yup validator for computer addresses - ensures a comma-separated list of addresses
const computerAddressesValidator = (value: string | undefined) => {
  if (value === '') {
    return true;
  }

  if (value == null) {
    return false;
  }

  const addresses = value.split(',').map((address) => address.trim());
  return addresses.every((address) => ethers.utils.isAddress(address));
};

// Custom Yup validator for computer weights - ensures a comma-separated list of numbers
const computerWeightsValidator = (value: string | undefined) => {
  if (value === '') {
    return true;
  }

  if (value == null) {
    return false;
  }

  const weights = value.split(',').map((weight) => weight.trim());
  return weights.every((weight) => {
    try {
      parseUnits(weight, 2);

      return true;
    } catch (e) {
      return false;
    }
  });
};

// Custom Yup validator for computer weights - ensures each weight is between 0 and 100
const computerWeightsRangeValidator = (value: string | undefined) => {
  if (value === '') {
    return true;
  }

  if (value == null) {
    return false;
  }

  const weights = value.split(',').map((weight) => weight.trim());
  return weights.every((weight) => {
    try {
      const bn = parseUnits(weight, 2);

      return bn.gte(0) && bn.lte(DEFAULT_100_PERCENT);
    } catch (e) {
      return false;
    }
  });
};

export default function ControllerSetRateConfigDialog({
  dialogOpen,
  networkName,
  token,
  rateController,
  decimals,
  ratePrefix,
  rateSuffix,
  controllerType,
  values,
  dataPoints,
}: SetRateConfigDialogProps) {
  const theme = useTheme();

  const copyConfigDialogOpen = useBoolean();
  const [copyingConfig, setCopyingConfig] = useState(false);
  const [copyFromContract, setCopyFromContract] = useState<string>();
  const [copyFromToken, setCopyFromToken] = useState<string>();
  const copyFromController = useMemo(() => {
    if (copyFromContract == null || rateController == null) {
      return undefined;
    }

    return new Contract(copyFromContract, rateController?.interface, rateController?.signer);
  }, [copyFromContract, rateController]);
  const copiedConfigCall = useCall(
    copyingConfig === true &&
      copyFromToken != null &&
      copyFromController != null && {
        contract: copyFromController,
        method: 'getConfig',
        args: [copyFromToken],
      },
    {
      chainId: config.chains[networkName]?.chainId,
    }
  );

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const setConfig = useContractFunction(rateController, 'setConfig');

  const { enqueueSnackbar } = useSnackbar();

  const rateLabel = dataPoints?.rate?.label ?? prudentiaGenericDataPoints.rate!.label;

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [base, setBase] = useState<BigNumber>();
  const [min, setMin] = useState<BigNumber>();
  const [max, setMax] = useState<BigNumber>();
  const [maxIncrease, setMaxIncrease] = useState<BigNumber>();
  const [maxDecrease, setMaxDecrease] = useState<BigNumber>();
  const [maxPercentIncrease, setMaxPercentIncrease] = useState<BigNumber>();
  const [maxPercentDecrease, setMaxPercentDecrease] = useState<BigNumber>();
  const [computerWeights, setComputerWeights] = useState<BigNumber[]>();
  const [computerAddresses, setComputerAddresses] = useState<string[]>();

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          base: Yup.mixed()
            .test(
              'bigNumber',
              'Base should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Base cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Base cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            ),
          min: Yup.mixed()
            .test(
              'bigNumber',
              'Min should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Min cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Min cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .notOneOf([''], 'Min is required.')
            .required('Min is required.'),
          max: Yup.mixed()
            .test(
              'bigNumber',
              'Max should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Max cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Max cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .notOneOf([''], 'Max is required.')
            .required('Max is required.'),
          maxIncrease: Yup.mixed()
            .test(
              'bigNumber',
              'Max increase should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Max increase cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Max increase cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .notOneOf([''], 'Max increase is required.')
            .required('Max increase is required.'),
          maxDecrease: Yup.mixed()
            .test(
              'bigNumber',
              'Max decrease should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', 'Max decrease cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              'Max decrease cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .notOneOf([''], 'Max decrease is required.')
            .required('Max decrease is required.'),
          maxPercentIncrease: Yup.mixed()
            .test(
              'bigNumber',
              'Max percent increase should be a number with at most ' + 2 + ' decimal places.',
              bigNumberValidator(2)
            )
            .test('min', 'Max percent increase cannot be negative.', bigNumberMinValidator(0, 2))
            .test(
              'max',
              'Max percent increase cannot be greater than ' +
                formatUnits(MAX_PERCENT_INCREASE, 2) +
                '.',
              bigNumberMaxValidator(MAX_PERCENT_INCREASE, 2)
            )
            .notOneOf([''], 'Max percent increase is required.')
            .required('Max percent increase is required.'),
          maxPercentDecrease: Yup.mixed()
            .test(
              'bigNumber',
              'Max percent decrease should be a number with at most ' + 2 + ' decimal places.',
              bigNumberValidator(2)
            )
            .test('min', 'Max percent decrease cannot be negative.', bigNumberMinValidator(0, 2))
            .test(
              'max',
              'Max percent decrease cannot be greater than ' +
                formatUnits(DEFAULT_100_PERCENT, 2) +
                '.',
              bigNumberMaxValidator(DEFAULT_100_PERCENT, 2)
            )
            .notOneOf([''], 'Max percent decrease is required.')
            .required('Max percent decrease is required.'),
          computerAddresses: Yup.string().test(
            'computerAddresses',
            'Invalid computer addresses.',
            computerAddressesValidator
          ),
          computerWeights: Yup.string()
            .test('computerWeights', 'Invalid computer weights.', computerWeightsValidator)
            .test(
              'computerWeightsRange',
              'Computer weights should be between 0 and 100.',
              computerWeightsRangeValidator
            ),
        })
        .required()
        .test(
          'computer-weights-addresses-length-match',
          'Computer addresses and weights must have the same number of comma-separated values.',
          (values) => {
            const { computerAddresses, computerWeights } = values;

            if (computerAddresses === '' && computerWeights === '') {
              return true;
            }

            if (computerAddresses == null || computerWeights == null) {
              return false;
            }

            const addresses = computerAddresses.split(',').map((address) => address.trim());
            const weights = computerWeights.split(',').map((weight) => parseFloat(weight.trim()));

            return addresses.length === weights.length;
          }
        )
        .test('min-lte-max', 'Min must be less than or equal to max.', (values) => {
          if (values.min == null || values.max == null) {
            return false;
          }

          try {
            const min = parseUnits(values.min.toString(), decimals);
            const max = parseUnits(values.max.toString(), decimals);

            return min.lte(max);
          } catch (e) {
            // One of the other validators will catch this
          }

          return true;
        }),
    [decimals]
  );
  const defaultValues = useMemo(
    () => ({
      base: formatUnits(values?.base ?? 0, decimals),
      min: formatUnits(values?.min ?? 0, decimals),
      max: formatUnits(values?.max ?? 0, decimals),
      maxIncrease: formatUnits(values?.maxIncrease ?? 0, decimals),
      maxDecrease: formatUnits(values?.maxDecrease ?? 0, decimals),
      maxPercentIncrease: formatUnits(values?.maxPercentIncrease ?? 0, 2),
      maxPercentDecrease: formatUnits(values?.maxPercentDecrease ?? 0, 2),
      computerAddresses: values?.computerAddresses?.join(', ') ?? '',
      computerWeights:
        values?.computerWeights
          ?.flatMap((weight) => (weight * 100) / DEFAULT_100_PERCENT)
          .join(', ') ?? '',
    }),
    [networkName, values, decimals]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const base = parseUnits(data.base?.toString() || '0', decimals);
    const min = parseUnits(data.min.toString(), decimals);
    const max = parseUnits(data.max.toString(), decimals);
    const maxIncrease = parseUnits(data.maxIncrease.toString(), decimals);
    const maxDecrease = parseUnits(data.maxDecrease.toString(), decimals);
    const maxPercentIncrease = parseUnits(data.maxPercentIncrease.toString(), 2);
    const maxPercentDecrease = parseUnits(data.maxPercentDecrease.toString(), 2);
    const computerAddresses = !data.computerAddresses
      ? []
      : data.computerAddresses.split(',').map((address) => address.trim());
    const computerWeights = !data.computerWeights
      ? []
      : data.computerWeights.split(',').map((weight) => parseUnits(weight.trim(), 2));

    // Set the values
    setBase(base);
    setMin(min);
    setMax(max);
    setMaxIncrease(maxIncrease);
    setMaxDecrease(maxDecrease);
    setMaxPercentIncrease(maxPercentIncrease);
    setMaxPercentDecrease(maxPercentDecrease);
    setComputerAddresses(computerAddresses);
    setComputerWeights(computerWeights);

    handleNext();
  });

  const handleConfigCopying = useCallback(() => {
    if (copyingConfig) {
      if (copiedConfigCall?.value?.[0] != null) {
        const copiedBase = formatUnits(copiedConfigCall.value[0].base, decimals);
        const copiedMin = formatUnits(copiedConfigCall.value[0].min, decimals);
        const copiedMax = formatUnits(copiedConfigCall.value[0].max, decimals);
        const copiedMaxIncrease = formatUnits(copiedConfigCall.value[0].maxIncrease, decimals);
        const copiedMaxDecrease = formatUnits(copiedConfigCall.value[0].maxDecrease, decimals);
        const copiedMaxPercentIncrease = formatUnits(
          copiedConfigCall.value[0].maxPercentIncrease,
          2
        );
        const copiedMaxPercentDecrease = formatUnits(
          copiedConfigCall.value[0].maxPercentDecrease,
          2
        );
        const copiedComputerAddresses = copiedConfigCall.value[0].components.join(', ');
        const copiedComputerWeights = copiedConfigCall.value[0].componentWeights
          .flatMap((weight: number) => (weight * 100) / DEFAULT_100_PERCENT)
          .join(', ');

        formMethods.setValue('base', copiedBase);
        formMethods.setValue('min', copiedMin);
        formMethods.setValue('max', copiedMax);
        formMethods.setValue('maxIncrease', copiedMaxIncrease);
        formMethods.setValue('maxDecrease', copiedMaxDecrease);
        formMethods.setValue('maxPercentIncrease', copiedMaxPercentIncrease);
        formMethods.setValue('maxPercentDecrease', copiedMaxPercentDecrease);
        formMethods.setValue('computerAddresses', copiedComputerAddresses);
        formMethods.setValue('computerWeights', copiedComputerWeights);

        formMethods.trigger();

        enqueueSnackbar('Successfully copied the config.', {
          variant: 'success',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setCopyingConfig(false);
      } else if (copiedConfigCall?.error != null) {
        console.log('Failed to copy config: ', copiedConfigCall.error);

        enqueueSnackbar('Failed to copy config. See console for more details.', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setCopyingConfig(false);
      }
    }
  }, [copyingConfig, copiedConfigCall]);

  useEffect(() => {
    handleConfigCopying();
  }, [copyingConfig, copiedConfigCall]);

  function copyConfigCallback(value: { contract: string; token: string }) {
    setCopyFromContract(value.contract);
    setCopyFromToken(value.token);
    setCopyingConfig(true);
  }

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      setConfig.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && setConfig?.state?.status === 'Mining') {
      setConfig.resetState();

      dialogOpen.onFalse();
    }
  }, [setConfig?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  const globalAlerts = (
    <>
      <Alert severity="info">
        {rateLabel}s are formatted to whole units with {decimals} decimals.
      </Alert>
    </>
  );

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Set {rateLabel.toLowerCase()} controller config</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {globalAlerts}
                </Box>
                {controllerType !== 'pidController' && (
                  <RHFTextField
                    fullWidth
                    name="base"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Base"
                    helperText={`The ${rateLabel?.toLowerCase()} to add to computer ${rateLabel?.toLowerCase()}s.`}
                    InputProps={{
                      startAdornment:
                        ratePrefix != null ? (
                          <InputAdornment position="start">{ratePrefix}</InputAdornment>
                        ) : undefined,
                      endAdornment: (
                        <>
                          {rateSuffix && (
                            <InputAdornment position="end">{rateSuffix}</InputAdornment>
                          )}
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue('base', formatUnits(MAX_RATE, decimals));
                              formMethods.trigger('base');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                )}
                <RHFTextField
                  fullWidth
                  name="min"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Min"
                  helperText={`The minimum ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('min', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('min');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="max"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max"
                  helperText={`The maximum ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('max', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('max');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="maxIncrease"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max increase (absolute)"
                  helperText={`The maximum absolute increase from the previously stored ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('maxIncrease', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('maxIncrease');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="maxDecrease"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max decrease (absolute)"
                  helperText={`The maximum absolute decrease from the previously stored ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('maxDecrease', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('maxDecrease');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="maxPercentIncrease"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max increase (relative)"
                  helperText={`The maximum relative increase from the previously stored ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">%</InputAdornment>
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue(
                              'maxPercentIncrease',
                              formatUnits(MAX_PERCENT_INCREASE, 2)
                            );
                            formMethods.trigger('maxPercentIncrease');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="maxPercentDecrease"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label="Max decrease (relative)"
                  helperText={`The maximum relative decrease from the previously stored ${rateLabel.toLowerCase()}.`}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">%</InputAdornment>
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue(
                              'maxPercentDecrease',
                              formatUnits(DEFAULT_100_PERCENT, 2)
                            );
                            formMethods.trigger('maxPercentDecrease');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                {controllerType !== 'pidController' && (
                  <>
                    <RHFTextField
                      fullWidth
                      name="computerAddresses"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      label="Computer addresses"
                      helperText="A comma-separated list of computer addresses."
                    />

                    <RHFTextField
                      fullWidth
                      name="computerWeights"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      label="Computer weights"
                      helperText="A comma-separated list of computer weights as percentages."
                    />
                  </>
                )}
                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={copyConfigDialogOpen.onTrue} variant="outlined" color="inherit">
                  Copy From
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Box
                  rowGap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {globalAlerts}
                </Box>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {controllerType !== 'pidController' && (
                      <StyledTableRow>
                        <TableCell>Base</TableCell>
                        <TableCell>{base?.toString()}</TableCell>
                        <TableCell>
                          {base?.eq(MAX_RATE)
                            ? 'Max (uint64)'
                            : ratePrefix + commify(formatUnits(base ?? 0, decimals)) + rateSuffix}
                        </TableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow>
                      <TableCell>Min</TableCell>
                      <TableCell>{min?.toString()}</TableCell>
                      <TableCell>
                        {min?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix + commify(formatUnits(min ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max</TableCell>
                      <TableCell>{max?.toString()}</TableCell>
                      <TableCell>
                        {max?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix + commify(formatUnits(max ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max increase</TableCell>
                      <TableCell>{maxIncrease?.toString()}</TableCell>
                      <TableCell>
                        {maxIncrease?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix +
                            commify(formatUnits(maxIncrease ?? 0, decimals)) +
                            rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max decrease</TableCell>
                      <TableCell>{maxDecrease?.toString()}</TableCell>
                      <TableCell>
                        {maxDecrease?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix +
                            commify(formatUnits(maxDecrease ?? 0, decimals)) +
                            rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max percent increase</TableCell>
                      <TableCell>{maxPercentIncrease?.toString()}</TableCell>
                      <TableCell>{commify(formatUnits(maxPercentIncrease ?? 0, 2))}%</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Max percent decrease</TableCell>
                      <TableCell>{maxPercentDecrease?.toString()}</TableCell>
                      <TableCell>{commify(formatUnits(maxPercentDecrease ?? 0, 2))}%</TableCell>
                    </StyledTableRow>
                    {controllerType !== 'pidController' && (
                      <>
                        <StyledTableRow>
                          <TableCell>Computer addresses</TableCell>
                          <TableCell>{computerAddresses?.join(', ')}</TableCell>
                          <TableCell>{'<<<'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableCell>Computer weights</TableCell>
                          <TableCell>{computerWeights?.join(', ')}</TableCell>
                          <TableCell>
                            {computerWeights?.map((weight) => formatUnits(weight, 2)).join(', ')}
                          </TableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          setConfig.send(token, {
                            base: base!,
                            min: min!,
                            max: max!,
                            maxIncrease: maxIncrease!,
                            maxDecrease: maxDecrease!,
                            maxPercentIncrease: maxPercentIncrease!,
                            maxPercentDecrease: maxPercentDecrease!,
                            components: computerAddresses!,
                            componentWeights: computerWeights!,
                          });
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
      <CopyConfigDialog
        dialogOpen={copyConfigDialogOpen}
        callback={copyConfigCallback}
        values={{
          contract: rateController?.address,
          token: token,
        }}
      />
    </Dialog>
  );
}
