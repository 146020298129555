// @mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';

import { Contract, utils } from 'ethers';

import { Navigate, useParams } from 'react-router';

import config from 'src/adrastia.config';

import { shortenIfAddress, useCall } from '@usedapp/core';

import { default as rateControllerAbi } from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/RateController.sol/RateController.json';
import { default as erc165Abi } from '@openzeppelin-v4/contracts/build/contracts/ERC165.json';

import ReactPlaceholder from 'react-placeholder/lib';
import { RateController } from 'typechain/adrastia-periphery';
import AddressDisplay from 'src/components/AddressDisplay';
import { isAddress } from 'ethers/lib/utils';
import ControllerCard from 'src/components/prices/ControllerCard';
import AccessControlCard from 'src/components/AccessControlCard';
import { useMemo } from 'react';
import { IERC165 } from 'typechain/openzeppelin-v4';
import { IACCESSCONTROLENUMERABLE_ID } from 'src/constants/interface-ids';

const pythiaProtocolMetadataAbi =
  '[{"inputs":[],"name":"ADRASTIA_CORE_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"ADRASTIA_PERIPHERY_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"ADRASTIA_PROTOCOL_VERSION","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"}]';

const pythiaProtocolMetadataInterface = new utils.Interface(pythiaProtocolMetadataAbi);
const rateControllerInterface = new utils.Interface(rateControllerAbi.abi);
const erc165Interface = new utils.Interface(erc165Abi.abi);

export default function ControllerView() {
  const { themeStretch } = useSettings();

  var { networkName, oracleAddress } = useParams();

  const contract = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, rateControllerInterface) as RateController),
    [oracleAddress]
  );

  const contractMetadata = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : new Contract(oracleAddress, pythiaProtocolMetadataInterface),
    [oracleAddress]
  );

  const contractErc165 = useMemo(
    () =>
      oracleAddress === undefined
        ? undefined
        : (new Contract(oracleAddress, erc165Interface) as IERC165),
    [oracleAddress]
  );

  const oracleName = useCall(
    contractMetadata && {
      contract: contractMetadata,
      method: 'name',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const coreVersion = useCall(
    contractMetadata && {
      contract: contractMetadata,
      method: 'ADRASTIA_CORE_VERSION',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const peripheryVersion = useCall(
    contractMetadata && {
      contract: contractMetadata,
      method: 'ADRASTIA_PERIPHERY_VERSION',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const protocolVersion = useCall(
    contractMetadata && {
      contract: contractMetadata,
      method: 'ADRASTIA_PROTOCOL_VERSION',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );

  const supportsAccessControlCall = useCall(
    contractErc165 && {
      contract: contractErc165,
      method: 'supportsInterface',
      args: [IACCESSCONTROLENUMERABLE_ID],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const supportsAccessControl = supportsAccessControlCall?.value?.[0] === true;

  const theme = useTheme();

  if (
    !networkName ||
    !oracleAddress ||
    !isAddress(oracleAddress) ||
    !config.chains[networkName!] ||
    !contract
  ) {
    return <Navigate to="/404" replace={true} />;
  }

  const oracle = config.chains[networkName!].controllers?.[oracleAddress!];

  const title =
    oracle?.name ||
    (oracleName?.value !== undefined && oracleName.value[0]) ||
    shortenIfAddress(oracleAddress);
  const oracleDesc = oracle?.desc || undefined;

  return (
    <Page
      title={title + ' | ' + config.chains[networkName!].name}
      contentTitle={title}
      contentSubtitle={config.chains[networkName!].name + (oracleDesc ? ' - ' + oracleDesc : '')}
    >
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="General information"
                sx={{
                  textAlign: 'center',
                }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Name
                    </Typography>
                    <ReactPlaceholder
                      showLoadingAnimation={true}
                      type="textRow"
                      ready={oracleName?.value != null || oracleName?.error != null}
                      style={{
                        width: '50%',
                        height: theme.typography.body1.fontSize,
                        marginTop: theme.typography.body1.fontSize,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={'light'}
                        component="div"
                        align="center"
                      >
                        {oracleName === undefined || oracleName.value === undefined
                          ? '-'
                          : oracleName.value[0]}
                      </Typography>
                    </ReactPlaceholder>
                  </Grid>
                  <Grid item xs={12} md={6} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Address
                    </Typography>
                    <Typography variant="body1" fontWeight={'light'} component="div" align="center">
                      <AddressDisplay
                        address={oracleAddress}
                        chainId={config.chains[networkName!].chainId}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Core version
                    </Typography>
                    <ReactPlaceholder
                      showLoadingAnimation={true}
                      type="textRow"
                      ready={coreVersion?.value != null || coreVersion?.error != null}
                      style={{
                        width: '50%',
                        height: theme.typography.body1.fontSize,
                        marginTop: theme.typography.body1.fontSize,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={'light'}
                        component="div"
                        align="center"
                      >
                        {coreVersion === undefined || coreVersion.value === undefined
                          ? '-'
                          : coreVersion.value[0]}
                      </Typography>
                    </ReactPlaceholder>
                  </Grid>
                  <Grid item xs={12} md={4} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Periphery version
                    </Typography>
                    <ReactPlaceholder
                      showLoadingAnimation={true}
                      type="textRow"
                      ready={peripheryVersion?.value != null || peripheryVersion?.error != null}
                      style={{
                        width: '50%',
                        height: theme.typography.body1.fontSize,
                        marginTop: theme.typography.body1.fontSize,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={'light'}
                        component="div"
                        align="center"
                      >
                        {peripheryVersion === undefined || peripheryVersion.value === undefined
                          ? '-'
                          : peripheryVersion.value[0]}
                      </Typography>
                    </ReactPlaceholder>
                  </Grid>
                  <Grid item xs={12} md={4} alignContent="center">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                      align="center"
                      fontWeight={600}
                    >
                      Protocol version
                    </Typography>
                    <ReactPlaceholder
                      showLoadingAnimation={true}
                      type="textRow"
                      ready={protocolVersion?.value != null || protocolVersion?.error != null}
                      style={{
                        width: '50%',
                        height: theme.typography.body1.fontSize,
                        marginTop: theme.typography.body1.fontSize,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={'light'}
                        component="div"
                        align="center"
                      >
                        {protocolVersion === undefined || protocolVersion.value === undefined
                          ? '-'
                          : protocolVersion.value[0]}
                      </Typography>
                    </ReactPlaceholder>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {supportsAccessControl && (
            <Grid item xs={12}>
              <AccessControlCard contractAddress={oracleAddress} networkName={networkName} />
            </Grid>
          )}
          {oracle !== undefined &&
            config.chains[networkName!].controllers?.[oracleAddress!]?.tokens != null && (
              <Grid item xs={12}>
                <Grid container spacing={2} marginTop={2}>
                  {Object.values(
                    config.chains[networkName!].controllers![oracleAddress!].tokens
                  ).map((token, i) => (
                    <Grid item xl={3} lg={6} xs={12} sm={12} md={6} key={i}>
                      <Box display="flex" justifyContent="center">
                        <ControllerCard
                          oracle={contract}
                          assetAddress={token}
                          assetName={config.chains[networkName!].tokens[token].name}
                          assetSymbol={config.chains[networkName!].tokens[token].symbol}
                          assetDecimals={config.chains[networkName!].tokens[token].decimals}
                          assetIcon={config.chains[networkName!].tokens[token].icon}
                          ratePrefix={
                            config.chains[networkName!].controllers![oracleAddress!].display
                              .ratePrefix
                          }
                          rateSuffix={
                            config.chains[networkName!].controllers![oracleAddress!].display
                              .rateSuffix
                          }
                          decimals={oracle?.decimals}
                          displayDecimals={oracle?.display?.decimals}
                          chainId={config.chains[networkName!].chainId}
                          dataPoints={
                            config.chains[networkName!].controllers![oracleAddress!].dataPoints
                          }
                          iconOverlay={
                            config.chains[networkName!].controllers![oracleAddress!].display
                              .iconOverlay
                          }
                          type={config.chains[networkName!].controllers![oracleAddress!].type}
                        ></ControllerCard>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
        </Grid>
      </Container>
    </Page>
  );
}
