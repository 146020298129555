import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { alpha, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import Logo from 'src/components/Logo';
import { bgBlur } from 'src/theme/css';
import Label from 'src/components/label';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { HEADER } from '../config-layout';
import Searchbar from '../common/searchbar';
import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { navConfig } from './config-navigation';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { PATH_BLOG, PATH_DISCORD, PATH_GITHUB, PATH_TWITTER } from 'src/routes/paths';

// ----------------------------------------------------------------------

const SOCIAL_LINKS = [
  {
    name: 'twitter',
    link: PATH_TWITTER,
    icon: 'akar-icons:twitter-fill',
  },
  {
    name: 'discord',
    link: PATH_DISCORD,
    icon: 'akar-icons:discord-fill',
  },
  {
    name: 'github',
    link: PATH_GITHUB,
    icon: 'akar-icons:github-fill',
  },
];

type Props = {
  headerOnDark: boolean;
};

export default function Header({ headerOnDark }: Props) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        backgroundColor: 'transparent',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(!headerOnDark && {
            color: 'text.primary',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo dark={headerOnDark && !offset} />
          </Box>

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            <Stack spacing={2} direction="row" alignItems="center">
              {SOCIAL_LINKS.map((social) => (
                <IconButton
                  key={social.name}
                  color="primary"
                  size="small"
                  href={social.link}
                  target="_blank"
                >
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Stack>

          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}
