// MUI imports
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme, alpha } from '@mui/material/styles';

// React imports
import { useParams } from 'react-router';

// Placeholder imports
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';

// useDApp imports
import { useCall } from '@usedapp/core';

// Adrastia imports
import { IOracle } from '../../../typechain/adrastia-core-v3/interfaces';
import { Box, Link } from '@mui/material';
import { parsePrice } from 'src/utils/formatPrices';
import useSettings from 'src/hooks/useSettings';
import { BigNumber, Contract, utils } from 'ethers';
import { commify, formatUnits, hexZeroPad } from 'ethers/lib/utils';
import { DataPointsConfig } from 'src/adrastia.config';
import { PATH_APP } from 'src/routes/paths';

// Adrastia Core v4 ABI imports
import { default as periodicOracleAbi } from 'adrastia-core-v4/artifacts/contracts/oracles/PeriodicOracle.sol/PeriodicOracle.json';

// Adrastia Core v4 type imports
import { IPeriodic, IValidationStrategy } from 'typechain/adrastia-core-v4';
import { RouterLink } from 'src/routes/components';
import { ObservationLibrary } from 'typechain/adrastia-core-v4/strategies/validation/IValidationStrategy';
import { RateController } from 'typechain/adrastia-periphery';
import { useMemo } from 'react';
import GetFontValue from 'src/utils/getFontValue';

// Adrastia Core v4 interfaces
const periodicOracleInterface = new utils.Interface(periodicOracleAbi.abi);

const ERROR_ZERO = BigNumber.from('1000000000000000000');

enum Status {
  NEVER_UPDATED,
  NEEDS_UPDATE_GRACE_PERIOD,
  NEEDS_UPDATE,
  UP_TO_DATE,
  FAILS_VALIDATION,
}

const linkHoverShadow = (color: string, isLight: boolean) =>
  `0 0 0 2px ${alpha(color, isLight ? 0.16 : 0.64)}`;

type ControllerCardProps = {
  oracle: RateController;
  assetAddress: string;
  assetName: string;
  assetSymbol: string;
  assetDecimals: number;
  assetIcon: string;
  ratePrefix?: string;
  rateSuffix?: string;
  decimals?: number;
  displayDecimals?: number;
  chainId: number;
  dataPoints: DataPointsConfig;
  iconOverlay?: string;
  type?:
    | 'supplyAndBorrow'
    | 'dexPriceAndLiquidity'
    | 'interestRate'
    | 'gasPrice'
    | 'volatility'
    | 'valueAndError'
    | 'rateController'
    | 'erc20MutationComputer'
    | 'oracleMutationComputer'
    | 'slopedOracleMutationComputer'
    | 'capController'
    | 'pidController'
    | 'rateComputer'
    | 'historicalRatesComputer';
  maxObservationAge?: number; // for status indication, in seconds
  validationStrategy?: IValidationStrategy;
};

const parseWholeUnits = (amount: BigNumber, decimals: number) => {
  return amount.div(BigNumber.from(10).pow(decimals)).toString();
};

export default function ControllerCard(props: ControllerCardProps) {
  var { networkName } = useParams();

  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  var consultation;

  consultation = useCall(
    {
      contract: props.oracle,
      method: 'getRateAt',
      args: [props.assetAddress, 0],
    },
    {
      chainId: props.chainId,
    }
  );

  const instantConsultation = useCall(
    consultation?.error !== undefined && {
      contract: props.oracle,
      method: 'computeRate',
      args: [props.assetAddress],
    },
    {
      chainId: props.chainId,
    }
  );

  var showAsterisk = false;
  var showStatus = true;

  if (
    props.type === 'erc20MutationComputer' ||
    props.type === 'oracleMutationComputer' ||
    props.type === 'slopedOracleMutationComputer' ||
    props.type === 'rateComputer' ||
    props.type === 'historicalRatesComputer'
  ) {
    showAsterisk = false;
    showStatus = false;
  } else {
    if (consultation?.error != null) {
      showAsterisk = true;
    }
  }

  const updateData = hexZeroPad(props.assetAddress, 32);

  const needsUpdate = useCall(
    {
      contract: props.oracle,
      method: 'needsUpdate',
      args: [updateData],
    },
    {
      chainId: props.chainId,
    }
  );

  const lastUpdateTime = useCall(
    {
      contract: props.oracle,
      method: 'lastUpdateTime',
      args: [updateData],
    },
    {
      chainId: props.chainId,
    }
  );

  const periodicContract = useMemo(() => {
    return new Contract(props.oracle.address, periodicOracleInterface) as IPeriodic;
  }, [props.oracle.address]);

  const period = useCall(
    {
      contract: periodicContract,
      method: 'period',
      args: [],
    },
    {
      chainId: props.chainId,
    }
  );

  var currentRate = consultation?.value?.[0]?.current ?? instantConsultation?.value?.[0];
  var targetRate = consultation?.value?.[0]?.target ?? instantConsultation?.value?.[0];

  var status = Status.NEVER_UPDATED;

  if (consultation?.value !== undefined) {
    if (needsUpdate?.value !== undefined) {
      if (needsUpdate.value[0] === true) {
        status = Status.NEEDS_UPDATE;

        if (lastUpdateTime?.value !== undefined) {
          const lastUpdateTimeSeconds = lastUpdateTime.value[0].toNumber();
          if (lastUpdateTimeSeconds === 0) {
            status = Status.NEVER_UPDATED;
          } else if (period?.value !== undefined) {
            const expectedUpdateTime = lastUpdateTimeSeconds + period.value[0].toNumber();
            const gracePeriod = 60;

            if (Date.now() / 1000 - expectedUpdateTime <= gracePeriod) {
              status = Status.NEEDS_UPDATE_GRACE_PERIOD;
            }
          }
        }
      } else {
        status = Status.UP_TO_DATE;

        if (props.maxObservationAge !== undefined && props.maxObservationAge > 0) {
          if (lastUpdateTime?.value !== undefined) {
            const lastUpdateTimeSeconds = lastUpdateTime.value[0].toNumber();
            if (lastUpdateTimeSeconds === 0) {
              status = Status.NEVER_UPDATED;
            } else {
              const expectedUpdateTime = lastUpdateTimeSeconds + props.maxObservationAge;
              const gracePeriod = 60;

              if (Date.now() / 1000 - expectedUpdateTime <= gracePeriod) {
                status = Status.NEEDS_UPDATE_GRACE_PERIOD;
              } else if (Date.now() / 1000 - expectedUpdateTime > gracePeriod) {
                status = Status.NEEDS_UPDATE;
              }
            }
          }
        }
      }
    }
  }

  const theme = useTheme();

  const body1 = GetFontValue('body1');
  const body2 = GetFontValue('body2');

  var currentRateDisplay =
    currentRate === undefined
      ? '-'
      : (props.ratePrefix || '') +
        parsePrice(currentRate, props.displayDecimals ?? props.decimals ?? 0) +
        (props.rateSuffix || '') +
        (showAsterisk ? '*' : '');

  var targetRateDisplay =
    targetRate === undefined
      ? '-'
      : (props.ratePrefix || '') +
        parsePrice(targetRate, props.displayDecimals ?? props.decimals ?? 0) +
        (props.rateSuffix || '') +
        (showAsterisk ? '*' : '');

  var dataDisplay;

  dataDisplay = (
    <>
      <ReactPlaceholder
        showLoadingAnimation={true}
        type="textRow"
        ready={
          (consultation !== undefined && consultation.value !== undefined) ||
          (consultation?.error !== undefined &&
            instantConsultation !== undefined &&
            (instantConsultation.value !== undefined || instantConsultation.error !== undefined))
        }
        style={{
          width: '50%',
          margin: 0,
          height: body1.lineHeight,
        }}
      >
        <Typography variant="body1" fontWeight={'light'} component="span">
          Current:{' '}
        </Typography>
        <Typography variant="body1" fontWeight={'medium'} component="span">
          {currentRateDisplay}
        </Typography>
      </ReactPlaceholder>

      {(props.type === 'rateController' ||
        props.type === 'capController' ||
        props.type === 'pidController') && (
        <>
          <div></div>

          <ReactPlaceholder
            showLoadingAnimation={true}
            type="textRow"
            ready={
              (consultation !== undefined && consultation.value !== undefined) ||
              (consultation?.error !== undefined &&
                instantConsultation !== undefined &&
                (instantConsultation.value !== undefined ||
                  instantConsultation.error !== undefined))
            }
            style={{
              width: '50%',
              marginTop: 2,
              height: body2.lineHeight,
            }}
          >
            <Typography variant="body2" fontWeight={200} component="span">
              Target:{' '}
            </Typography>
            <Typography variant="body2" fontWeight={200} component="span">
              {targetRateDisplay}
            </Typography>
          </ReactPlaceholder>
        </>
      )}
    </>
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Link
        href={
          PATH_APP.controllers +
          '/' +
          networkName +
          '/' +
          props.oracle.address +
          '/' +
          props.assetAddress
        }
        style={{ textDecoration: 'none' }}
        component={RouterLink}
      >
        <Card
          sx={{
            '&:hover': {
              boxShadow:
                linkHoverShadow(theme.palette.primary.main, isLight) +
                  ', ' +
                  (theme.components?.MuiCard?.styleOverrides?.root as any)?.boxShadow || '',
            },
            minWidth: '250px',
          }}
        >
          <CardContent
            sx={{
              ':before': {
                content: '""',
                backgroundImage:
                  `url('/assets/icons/coins/${props.assetIcon}.svg'), ` +
                  (props.iconOverlay ??
                    'linear-gradient(rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 100%)'),
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 16px bottom -32px',
                backgroundSize: 'contain',
                opacity: '0.25',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                position: 'absolute',
                zIndex: '-1',
                backgroundBlendMode: 'lighten',
                mask: `url('/assets/icons/coins/${props.assetIcon}.svg')`,
                maskRepeat: 'no-repeat',
                maskPosition: 'right 16px bottom -32px',
                maskSize: 'contain',
                WebkitMask: `url('/assets/icons/coins/${props.assetIcon}.svg')`,
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskPosition: 'right 16px bottom -32px',
                WebkitMaskSize: 'contain',
              },
            }}
          >
            <div style={{ marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="text.secondary" component="span">
                {props.assetName} {props.assetSymbol ? '(' + props.assetSymbol + ')' : ''}
              </Typography>
              {showStatus && (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    top: '8px',
                    marginLeft: '2px',
                    position: 'relative',
                    opacity: '0.5',
                    fontSize: '42px',
                    lineHeight: '0',
                  }}
                  color={
                    status === Status.NEVER_UPDATED
                      ? 'grey'
                      : status === Status.NEEDS_UPDATE
                      ? 'error.main'
                      : 'success.main'
                  }
                >
                  •
                </Typography>
              )}
            </div>
            {dataDisplay}
          </CardContent>
        </Card>
      </Link>
    </Box>
  );
}
