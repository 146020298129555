// @mui
import {
  Box,
  LinkProps,
  SxProps,
  Link,
  IconButton,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import { shortenIfAddress } from '@usedapp/core';
import Iconify from './Iconify';
import { getExplorerAddressLink } from 'src/web3-config';
import { ElementType, useMemo } from 'react';
import { RouterLink } from 'src/routes/components';
import config, { ChainConfig, DataPointsConfig } from 'src/adrastia.config';
import CustomPopover, { usePopover } from './custom-popover';

// ----------------------------------------------------------------------

interface Props extends LinkProps {
  networkName?: string;
  address: string;
  chainId: number;
  link?: string;
  sx?: SxProps;
  component?: ElementType<any>;
  resolveAddress?: boolean;
  hideAddressIfResolved?: boolean;
  infoDatapoints?: DataPointsConfig;
}

type ResolvedAddress = {
  name: string;
  desc?: string;
};

function resolveAddressInfo(
  chainId: number | undefined,
  networkName: string | undefined,
  address: string
): ResolvedAddress | undefined {
  var chainConfig: ChainConfig | undefined;
  if (networkName == null) {
    chainConfig = Object.values(config.chains).find((chain) => chain.chainId === chainId);
  } else {
    chainConfig = config.chains[networkName];
  }
  if (chainConfig == null) {
    return undefined;
  }

  const nameFromAddresses = chainConfig.addresses?.[address]?.name;
  if (nameFromAddresses != null) {
    return {
      name: nameFromAddresses,
    };
  }
  const nameFromUpdaters = chainConfig.updaters?.[address]?.name;
  if (nameFromUpdaters != null) {
    return {
      name: nameFromUpdaters,
    };
  }
  const nameFromOracles = chainConfig.oracles?.[address]?.name;
  if (nameFromOracles != null) {
    return {
      name: nameFromOracles,
    };
  }
  const nameFromControllers = chainConfig.controllers?.[address]?.name;
  if (nameFromControllers != null) {
    return {
      name: nameFromControllers,
    };
  }
  const validationStrategy = chainConfig.strategies?.validation?.[address];
  if (validationStrategy != null) {
    return {
      name: validationStrategy.name,
      desc: validationStrategy.desc,
    };
  }
  const aggregationStrategy = chainConfig.strategies?.aggregation?.[address];
  if (aggregationStrategy != null) {
    return {
      name: aggregationStrategy.name,
      desc: aggregationStrategy.desc,
    };
  }
  const averagingStrategy = chainConfig.strategies?.averaging?.[address];
  if (averagingStrategy != null) {
    return {
      name: averagingStrategy.name,
      desc: averagingStrategy.desc,
    };
  }
  const nameFromTokens = chainConfig.tokens?.[address]?.name;
  if (nameFromTokens != null) {
    const symbol = chainConfig.tokens?.[address]?.symbol;

    return {
      name: nameFromTokens + (symbol ? ` (${symbol})` : ''),
    };
  }

  const inputAndErrorTransformer = chainConfig.transformers?.inputAndError?.[address];
  if (inputAndErrorTransformer != null) {
    return {
      name: inputAndErrorTransformer.name,
      desc: inputAndErrorTransformer.desc,
    };
  }

  return undefined;
}

function resolveFormattedAddressInfo(
  chainId: number,
  networkName: string | undefined,
  address: string,
  infoDatapoints: DataPointsConfig | undefined
) {
  const addressInfo = resolveAddressInfo(chainId, networkName, address);

  if (infoDatapoints && addressInfo?.desc) {
    let priceLabel = infoDatapoints.price?.label?.toLowerCase() || '0 (slot not used)';
    // Replace %PRICE_SLOT% with price label
    addressInfo.desc = addressInfo.desc.replace(/%PRICE_SLOT%/g, priceLabel);

    let tokenLiquidityLabel =
      infoDatapoints.tokenLiquidity?.label?.toLowerCase() || '0 (slot not used)';
    // Replace %TOKEN_LIQUIDITY_SLOT% with liquidity label
    addressInfo.desc = addressInfo.desc.replace(/%TOKEN_LIQUIDITY_SLOT%/g, tokenLiquidityLabel);

    let quoteTokenLiquidityLabel =
      infoDatapoints.quoteTokenLiquidity?.label?.toLowerCase() || '0 (slot not used)';
    // Replace %QUOTE_TOKEN_LIQUIDITY_SLOT% with quote liquidity label
    addressInfo.desc = addressInfo.desc.replace(
      /%QUOTE_TOKEN_LIQUIDITY_SLOT%/g,
      quoteTokenLiquidityLabel
    );

    let rateLabel = infoDatapoints.rate?.label?.toLowerCase() || '0 rate';
    // Replace %RATE_SLOT% with rate label
    addressInfo.desc = addressInfo.desc.replace(/%RATE_SLOT%/g, rateLabel);
  }

  return addressInfo;
}

export default function AddressDisplay({
  networkName,
  address,
  chainId,
  link,
  sx,
  component,
  resolveAddress,
  hideAddressIfResolved,
  infoDatapoints,
  ...other
}: Props) {
  const theme = useTheme();

  const infoPopover = usePopover();

  let addressInfo: ResolvedAddress | undefined = useMemo(() => {
    if (resolveAddress) {
      return resolveFormattedAddressInfo(chainId, networkName, address, infoDatapoints);
    }
  }, [chainId, networkName, address, resolveAddress, infoDatapoints]);

  let addressDisplay: string;
  if (addressInfo != null) {
    // Show name
    if (hideAddressIfResolved) {
      // Hide address
      addressDisplay = addressInfo.name;
    } else {
      // Show address and name
      addressDisplay = `${shortenIfAddress(address)} (${addressInfo.name})`;
    }
  } else {
    // Show address
    addressDisplay = shortenIfAddress(address);
  }

  return (
    <Box component={component}>
      {link ? (
        <Link href={link} sx={{ ...sx }} {...other} component={RouterLink}>
          {addressDisplay}
        </Link>
      ) : (
        <Typography
          component="span"
          variant="body1"
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            fontFamily: 'inherit',
            color: 'inherit',
          }}
        >
          {addressDisplay}
        </Typography>
      )}
      <Stack
        sx={{ display: 'inline-block', marginX: theme.spacing(1) }}
        direction="row"
        spacing={theme.spacing(0.5)}
      >
        {addressInfo?.desc && (
          <>
            <IconButton
              color="primary"
              aria-label="info"
              size="small"
              sx={{
                padding: 0,
                margin: 0,
              }}
              onClick={infoPopover.onOpen}
            >
              <Iconify icon="mdi:information-outline" />
            </IconButton>
            <CustomPopover
              open={infoPopover.open}
              onClose={infoPopover.onClose}
              arrow="bottom-center"
            >
              <Typography variant="body2">{addressInfo.desc}</Typography>
            </CustomPopover>
          </>
        )}
        <IconButton
          color="primary"
          aria-label="copy"
          size="small"
          sx={{
            padding: 0,
            margin: 0,
          }}
          onClick={() => {
            navigator.clipboard.writeText(address);
          }}
        >
          <Iconify icon="mdi:content-copy" />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="view on explorer"
          size="small"
          sx={{
            padding: 0,
            margin: 0,
          }}
          href={getExplorerAddressLink(address, chainId)}
          target="_blank"
        >
          <Iconify icon="mdi:exit-to-app" />
        </IconButton>
      </Stack>
    </Box>
  );
}
