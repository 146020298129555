import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { BigNumber, Contract, utils } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import config from 'src/adrastia.config';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { PidController } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import {
  bigNumberMaxValidator,
  bigNumberMinValidator,
  bigNumberValidator,
} from 'src/forms/validation/big-number';
import { addressValidator } from 'src/forms/validation/address';
import {
  MAX_GAINS_DENOMINATOR,
  MAX_GAINS_NUMERATOR,
  MIN_GAINS_DENOMINATOR,
  MIN_GAINS_NUMERATOR,
} from 'src/constants/pid-controller';
import { AddressZero } from '@ethersproject/constants';
import AddressDisplay from 'src/components/AddressDisplay';
import { PATH_APP } from 'src/routes/paths';

// Adrastia Core v4 ABI imports
import { default as oracleAbi } from 'adrastia-core-v4/artifacts/contracts/interfaces/IOracle.sol/IOracle.json';

const oracleInterface = new utils.Interface(oracleAbi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type CurrentValues = {
  inputAndErrorOracle?: string;
  kPNumerator?: BigNumber;
  kPDenominator?: BigNumber;
  kINumerator?: BigNumber;
  kIDenominator?: BigNumber;
  kDNumerator?: BigNumber;
  kDDenominator?: BigNumber;
  transformer?: string;
  proportionalOnMeasurement?: boolean;
  derivativeOnMeasurement?: boolean;
};

type ControllerSetPidConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  token: string;
  rateController?: PidController;
  decimals: number;
  defaultInputAndErrorOracle?: string;
  defaultInputAndErrorOracleDecimals?: number;
  values?: CurrentValues;
};

const steps = ['Define parameters', 'Review and submit'];

export default function ControllerSetPidConfigDialog({
  dialogOpen,
  networkName,
  token,
  rateController,
  decimals,
  defaultInputAndErrorOracle,
  defaultInputAndErrorOracleDecimals,
  values,
}: ControllerSetPidConfigDialogProps) {
  const theme = useTheme();

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const setConfig = useContractFunction(rateController, 'setPidConfig');

  const { enqueueSnackbar } = useSnackbar();

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [inputAndErrorOracle, setInputAndErrorOracle] = useState<string>();
  const [kPNumerator, setKPNumerator] = useState<BigNumber>();
  const [kPDenominator, setKPDenominator] = useState<BigNumber>();
  const [kINumerator, setKINumerator] = useState<BigNumber>();
  const [kIDenominator, setKIDenominator] = useState<BigNumber>();
  const [kDNumerator, setKDNumerator] = useState<BigNumber>();
  const [kDDenominator, setKDDenominator] = useState<BigNumber>();
  const [transformer, setTransformer] = useState<string>();
  const [proportionalOnMeasurement, setProportionalOnMeasurement] = useState<boolean>();
  const [derivativeOnMeasurement, setDerivativeOnMeasurement] = useState<boolean>();

  const inputAndErrorOracleBeingUsed =
    inputAndErrorOracle == null || inputAndErrorOracle === AddressZero
      ? defaultInputAndErrorOracle
      : inputAndErrorOracle;

  const contractInputAndErrorOracle = useMemo(() => {
    return new Contract(inputAndErrorOracleBeingUsed!, oracleInterface);
  }, [inputAndErrorOracleBeingUsed]);

  const inputAndErrorOracleDecimalsCall = useCall(
    inputAndErrorOracleBeingUsed && {
      contract: contractInputAndErrorOracle,
      method: 'liquidityDecimals',
      args: [],
    },
    {
      chainId: config.chains[networkName!].chainId,
    }
  );
  const inputAndErrorOracleDecimals =
    inputAndErrorOracle === AddressZero
      ? defaultInputAndErrorOracleDecimals
      : inputAndErrorOracleDecimalsCall?.value?.[0];

  const gainsNormalizationDivisor =
    inputAndErrorOracleDecimals != null && decimals > inputAndErrorOracleDecimals
      ? BigNumber.from(10).pow(decimals - (inputAndErrorOracleDecimals ?? 0))
      : BigNumber.from(1);
  const gainsNormalizationMultiplier =
    inputAndErrorOracleDecimals != null && decimals < inputAndErrorOracleDecimals
      ? BigNumber.from(10).pow((inputAndErrorOracleDecimals ?? 0) - decimals)
      : BigNumber.from(1);

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          inputAndErrorOracle: Yup.mixed()
            .test(
              'address',
              'Value and error oracle should be a valid address.',
              addressValidator()
            )
            .required('Value and error oracle is required.'),
          kPNumerator: Yup.mixed()
            .test(
              'bigNumber',
              'kP numerator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kP numerator cannot be less than ' + MIN_GAINS_NUMERATOR,
              bigNumberMinValidator(MIN_GAINS_NUMERATOR, 0)
            )
            .test(
              'max',
              'kP numerator cannot be greater than ' + formatUnits(MAX_GAINS_NUMERATOR, 0) + '.',
              bigNumberMaxValidator(MAX_GAINS_NUMERATOR, 0)
            )
            .required('kP numerator is required.'),
          kPDenominator: Yup.mixed()
            .test(
              'bigNumber',
              'kP denominator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kP denominator cannot be less than ' + formatUnits(MIN_GAINS_DENOMINATOR, 0) + '.',
              bigNumberMinValidator(MIN_GAINS_DENOMINATOR, 0)
            )
            .test(
              'max',
              'kP denominator cannot be greater than ' +
                formatUnits(MAX_GAINS_DENOMINATOR, 0) +
                '.',
              bigNumberMaxValidator(MAX_GAINS_DENOMINATOR, 0)
            )
            .required('kP denominator is required.'),
          kINumerator: Yup.mixed()
            .test(
              'bigNumber',
              'kI numerator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kI numerator cannot be less than ' + MIN_GAINS_NUMERATOR,
              bigNumberMinValidator(MIN_GAINS_NUMERATOR, 0)
            )
            .test(
              'max',
              'kI numerator cannot be greater than ' + formatUnits(MAX_GAINS_NUMERATOR, 0) + '.',
              bigNumberMaxValidator(MAX_GAINS_NUMERATOR, 0)
            )
            .required('kI numerator is required.'),
          kIDenominator: Yup.mixed()
            .test(
              'bigNumber',
              'kI denominator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kI denominator cannot be less than ' + formatUnits(MIN_GAINS_DENOMINATOR, 0) + '.',
              bigNumberMinValidator(MIN_GAINS_DENOMINATOR, 0)
            )
            .test(
              'max',
              'kI denominator cannot be greater than ' +
                formatUnits(MAX_GAINS_DENOMINATOR, 0) +
                '.',
              bigNumberMaxValidator(MAX_GAINS_DENOMINATOR, 0)
            )
            .required('kI denominator is required.'),
          kDNumerator: Yup.mixed()
            .test(
              'bigNumber',
              'kD numerator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kD numerator cannot be less than ' + MIN_GAINS_NUMERATOR,
              bigNumberMinValidator(MIN_GAINS_NUMERATOR, 0)
            )
            .test(
              'max',
              'kD numerator cannot be greater than ' + formatUnits(MAX_GAINS_NUMERATOR, 0) + '.',
              bigNumberMaxValidator(MAX_GAINS_NUMERATOR, 0)
            )
            .required('kD numerator is required.'),
          kDDenominator: Yup.mixed()
            .test(
              'bigNumber',
              'kD denominator should be a number with 0 decimal places.',
              bigNumberValidator(0)
            )
            .test(
              'min',
              'kD denominator cannot be less than ' + formatUnits(MIN_GAINS_DENOMINATOR, 0) + '.',
              bigNumberMinValidator(MIN_GAINS_DENOMINATOR, 0)
            )
            .test(
              'max',
              'kD denominator cannot be greater than ' +
                formatUnits(MAX_GAINS_DENOMINATOR, 0) +
                '.',
              bigNumberMaxValidator(MAX_GAINS_DENOMINATOR, 0)
            )
            .required('kD denominator is required.'),
          transformer: Yup.mixed()
            .test('address', 'Transformer should be a valid address.', addressValidator())
            .required('Transformer is required.'),
          proportionalOnMeasurement: Yup.boolean().required(
            'Proportional on measurement is required.'
          ),
          derivativeOnMeasurement: Yup.boolean().required('Derivative on measurement is required.'),
        })
        .required(),
    []
  );
  const defaultValues = useMemo(
    () => ({
      inputAndErrorOracle: values?.inputAndErrorOracle ?? AddressZero,
      kPNumerator: formatUnits(values?.kPNumerator ?? MIN_GAINS_NUMERATOR, 0),
      kPDenominator: values?.kPDenominator?.lt(MIN_GAINS_DENOMINATOR)
        ? formatUnits(MIN_GAINS_DENOMINATOR, 0)
        : formatUnits(values?.kPDenominator ?? MIN_GAINS_DENOMINATOR, 0),
      kINumerator: formatUnits(values?.kINumerator ?? MIN_GAINS_NUMERATOR, 0),
      kIDenominator: values?.kIDenominator?.lt(MIN_GAINS_DENOMINATOR)
        ? formatUnits(MIN_GAINS_DENOMINATOR, 0)
        : formatUnits(values?.kIDenominator ?? MIN_GAINS_DENOMINATOR, 0),
      kDNumerator: formatUnits(values?.kDNumerator ?? MIN_GAINS_NUMERATOR, 0),
      kDDenominator: values?.kDDenominator?.lt(MIN_GAINS_DENOMINATOR)
        ? formatUnits(MIN_GAINS_DENOMINATOR, 0)
        : formatUnits(values?.kDDenominator ?? MIN_GAINS_DENOMINATOR, 0),
      transformer: values?.transformer ?? AddressZero,
      proportionalOnMeasurement: values?.proportionalOnMeasurement ?? false,
      derivativeOnMeasurement: values?.derivativeOnMeasurement ?? false,
    }),
    [values]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const inputAndErrorOracle = data.inputAndErrorOracle.toString();
    const kPNumerator = parseUnits(data.kPNumerator.toString(), 0);
    const kPDenominator = parseUnits(data.kPDenominator.toString(), 0);
    const kINumerator = parseUnits(data.kINumerator.toString(), 0);
    const kIDenominator = parseUnits(data.kIDenominator.toString(), 0);
    const kDNumerator = parseUnits(data.kDNumerator.toString(), 0);
    const kDDenominator = parseUnits(data.kDDenominator.toString(), 0);
    const transformer = data.transformer.toString();
    const proportionalOnMeasurement = data.proportionalOnMeasurement;
    const derivativeOnMeasurement = data.derivativeOnMeasurement;

    // Set the values
    setInputAndErrorOracle(inputAndErrorOracle);
    setKPNumerator(kPNumerator);
    setKPDenominator(kPDenominator);
    setKINumerator(kINumerator);
    setKIDenominator(kIDenominator);
    setKDNumerator(kDNumerator);
    setKDDenominator(kDDenominator);
    setTransformer(transformer);
    setProportionalOnMeasurement(proportionalOnMeasurement);
    setDerivativeOnMeasurement(derivativeOnMeasurement);

    handleNext();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      setConfig.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && setConfig?.state?.status === 'Mining') {
      setConfig.resetState();

      dialogOpen.onFalse();
    }
  }, [setConfig?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Set PID config</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <>
              <FormProvider methods={formMethods} onSubmit={onSubmit}>
                <DialogContent>
                  <Box marginTop={theme.spacing(1)} marginBottom={theme.spacing(2)}>
                    <Typography variant="body2" component="span">
                      <strong>Default input and error oracle</strong>:{' '}
                      <AddressDisplay
                        component={'span'}
                        address={defaultInputAndErrorOracle!}
                        chainId={config.chains[networkName]?.chainId ?? 0}
                        link={`${PATH_APP.dataFeeds}/${networkName}/${defaultInputAndErrorOracle}/${token}`}
                        target="_blank"
                        hideAddressIfResolved
                        resolveAddress
                      ></AddressDisplay>{' '}
                      with {defaultInputAndErrorOracleDecimals} decimals.
                    </Typography>
                    <Typography variant="body2" marginTop={theme.spacing(1)}>
                      <strong>Controller decimals</strong>: {decimals}
                    </Typography>
                    <Typography variant="body2" marginTop={theme.spacing(1)}>
                      Please note the difference between the controller and oracle decimals when
                      entering the gains.
                    </Typography>
                    <Typography variant="body2" marginTop={theme.spacing(1)}>
                      Avoid changing the oracle to one with a different number of decimals if the
                      controller has been initialized for the token.
                    </Typography>
                  </Box>
                  <RHFTextField
                    fullWidth
                    name="inputAndErrorOracle"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Input and error oracle"
                    helperText="The address of the input and error oracle. Specifying the zero address will use the default."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue('inputAndErrorOracle', AddressZero);
                              formMethods.trigger('inputAndErrorOracle');
                            }}
                            tabIndex={-1}
                          >
                            Use default
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kPNumerator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kP numerator"
                    helperText="The numerator of the proportional gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kPNumerator',
                                formatUnits(MIN_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kPNumerator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kPNumerator',
                                formatUnits(MAX_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kPNumerator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kPDenominator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kP denominator"
                    helperText="The denominator of the proportional gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kPDenominator',
                                formatUnits(MIN_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kPDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kPDenominator',
                                formatUnits(MAX_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kPDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kINumerator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kI numerator"
                    helperText="The numerator of the integral gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kINumerator',
                                formatUnits(MIN_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kINumerator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kINumerator',
                                formatUnits(MAX_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kINumerator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kIDenominator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kI denominator"
                    helperText="The denominator of the integral gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kIDenominator',
                                formatUnits(MIN_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kIDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kIDenominator',
                                formatUnits(MAX_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kIDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kDNumerator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kD numerator"
                    helperText="The numerator of the derivative gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kDNumerator',
                                formatUnits(MIN_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kDNumerator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kDNumerator',
                                formatUnits(MAX_GAINS_NUMERATOR, 0)
                              );
                              formMethods.trigger('kDNumerator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="kDDenominator"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="kD denominator"
                    helperText="The denominator of the derivative gain."
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kDDenominator',
                                formatUnits(MIN_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kDDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Min
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue(
                                'kDDenominator',
                                formatUnits(MAX_GAINS_DENOMINATOR, 0)
                              );
                              formMethods.trigger('kDDenominator');
                            }}
                            tabIndex={-1}
                          >
                            Max
                          </Button>
                        </>
                      ),
                    }}
                  />
                  <RHFTextField
                    fullWidth
                    name="transformer"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Transformer"
                    helperText="The address of the transformer."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="text"
                            onClick={() => {
                              formMethods.setValue('transformer', AddressZero);
                              formMethods.trigger('transformer');
                            }}
                            tabIndex={-1}
                          >
                            None
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFCheckbox
                    name="proportionalOnMeasurement"
                    label="Proportional on measurement"
                    helperText="Select for proportional on measurement. Otherwise, it will be on error (default)."
                  />
                  <RHFCheckbox
                    name="derivativeOnMeasurement"
                    label="Derivative on measurement"
                    helperText="Select for derivative on measurement. Otherwise, it will be on error (default)."
                  />
                  {globalFormErrors && (
                    <Box marginTop={theme.spacing(2)}>
                      <Typography color="error" variant="caption">
                        {globalFormErrors.message}
                      </Typography>
                    </Box>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Next
                  </Button>
                </DialogActions>
              </FormProvider>
            </>
          ) : (
            <>
              <DialogContent>
                <Box marginTop={theme.spacing(1)} marginBottom={theme.spacing(2)}>
                  <Typography variant="body2">
                    <strong>Controller decimals</strong>: {decimals}
                  </Typography>
                </Box>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>Input and error oracle</TableCell>
                      <TableCell>
                        {inputAndErrorOracle === AddressZero ? (
                          <>
                            {'Default ('}
                            <AddressDisplay
                              component={'span'}
                              address={defaultInputAndErrorOracle as string}
                              chainId={config.chains[networkName]?.chainId ?? 0}
                              link={`${PATH_APP.dataFeeds}/${networkName}/${defaultInputAndErrorOracle}/${token}`}
                              target="_blank"
                              hideAddressIfResolved
                              resolveAddress
                            />
                            {')'}
                          </>
                        ) : (
                          <AddressDisplay
                            component={'span'}
                            address={inputAndErrorOracle as string}
                            chainId={config.chains[networkName]?.chainId ?? 0}
                            link={`${PATH_APP.dataFeeds}/${networkName}/${inputAndErrorOracle}/${token}`}
                            target="_blank"
                            hideAddressIfResolved
                            resolveAddress
                          />
                        )}{' '}
                        with {inputAndErrorOracleDecimals} decimals
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Proportional gains (kP)</TableCell>
                      <TableCell>
                        {formatUnits(
                          kPNumerator?.mul(1_000_000_000)?.div(kPDenominator ?? 1) ?? 0,
                          9
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <em>Normalized proportional gains (kP)</em>
                      </TableCell>
                      <TableCell>
                        <em>
                          {formatUnits(
                            kPNumerator?.mul(1_000_000_000)?.div(kPDenominator ?? 1) ?? 0,
                            9 - (inputAndErrorOracleDecimals - decimals)
                          )}
                        </em>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Integral gains (kI)</TableCell>
                      <TableCell>
                        {formatUnits(
                          kINumerator?.mul(1_000_000_000)?.div(kIDenominator ?? 1) ?? 0,
                          9
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <em>Normalized integral gains (kI)</em>
                      </TableCell>
                      <TableCell>
                        <em>
                          {formatUnits(
                            kINumerator?.mul(1_000_000_000)?.div(kIDenominator ?? 1) ?? 0,
                            9 - (inputAndErrorOracleDecimals - decimals)
                          )}
                        </em>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Derivative gains (kD)</TableCell>
                      <TableCell>
                        {formatUnits(
                          kDNumerator?.mul(1_000_000_000)?.div(kDDenominator ?? 1) ?? 0,
                          9
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <em>Normalized derivative gains (kD)</em>
                      </TableCell>
                      <TableCell>
                        <em>
                          {formatUnits(
                            kDNumerator?.mul(1_000_000_000)?.div(kDDenominator ?? 1) ?? 0,
                            9 - (inputAndErrorOracleDecimals - decimals)
                          )}
                        </em>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Transformer</TableCell>
                      <TableCell>
                        {transformer === AddressZero ? (
                          <>None</>
                        ) : (
                          <AddressDisplay
                            component={'span'}
                            address={transformer as string}
                            chainId={config.chains[networkName]?.chainId ?? 0}
                            target="_blank"
                            hideAddressIfResolved
                            resolveAddress
                          />
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Proportional</TableCell>
                      <TableCell>
                        {proportionalOnMeasurement ? 'On measurement' : 'On error'}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Derivative</TableCell>
                      <TableCell>
                        {derivativeOnMeasurement ? 'On measurement' : 'On error'}
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          setConfig.send(token, {
                            inputAndErrorOracle: inputAndErrorOracle!,
                            kPNumerator: kPNumerator!,
                            kPDenominator: kPDenominator!,
                            kINumerator: kINumerator!,
                            kIDenominator: kIDenominator!,
                            kDNumerator: kDNumerator!,
                            kDDenominator: kDDenominator!,
                            transformer: transformer!,
                            proportionalOnMeasurement: proportionalOnMeasurement!,
                            derivativeOnMeasurement: derivativeOnMeasurement!,
                          });
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
