import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link } from '@mui/material';
import PriceCard from './PriceCard';

import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';

import abi from '@adrastia-oracle/adrastia-periphery/artifacts/contracts/rates/RateController.sol/RateController.json';
import { default as config, OracleConfig } from 'src/adrastia.config';
import { PATH_APP } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import ControllerCard from './ControllerCard';
import { RateController } from 'typechain/adrastia-periphery';
import { useMemo } from 'react';

const oracleInterface = new utils.Interface(abi.abi);

type ControllerFeedProps = {
  chainName: string;
  oracleAddress: string;
  oracleConfig: OracleConfig;
};

export default function ControllerFeed({
  chainName,
  oracleAddress,
  oracleConfig,
}: ControllerFeedProps) {
  const contract = useMemo(() => {
    return new Contract(oracleAddress, oracleInterface) as RateController;
  }, [oracleAddress]);

  const tokens = config.chains[chainName].tokens;

  var xlSize = 3;
  var lgSize = 4;
  var mdSize = 6;
  var smSize = 12;
  var xsSize = 12;

  const tokensLength = Object.keys(oracleConfig.tokens).length;

  if (tokensLength == 1) {
    xlSize = 12;
    lgSize = 12;
    mdSize = 12;
    smSize = 12;
    xsSize = 12;
  }

  return (
    <Card>
      <CardHeader title={oracleConfig.name} subheader={oracleConfig.desc} />
      <CardContent>
        <Grid container spacing={2}>
          {Object.values(oracleConfig.tokens).map((token, i) => (
            <Grid item xl={xlSize} lg={lgSize} md={mdSize} sm={smSize} xs={xsSize} key={i}>
              <Box display="flex" justifyContent="center">
                <ControllerCard
                  oracle={contract}
                  assetAddress={token}
                  assetName={tokens[token].name}
                  assetSymbol={tokens[token].symbol}
                  assetDecimals={tokens[token].decimals}
                  assetIcon={tokens[token].icon}
                  ratePrefix={oracleConfig.display.ratePrefix}
                  rateSuffix={oracleConfig.display.rateSuffix}
                  decimals={oracleConfig.decimals}
                  displayDecimals={oracleConfig.display.decimals}
                  chainId={config.chains[chainName].chainId}
                  dataPoints={oracleConfig.dataPoints}
                  iconOverlay={oracleConfig.display.iconOverlay}
                  type={oracleConfig.type}
                ></ControllerCard>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions>
        <Link
          href={PATH_APP.controllers + '/' + chainName + '/' + oracleAddress}
          underline="none"
          component={RouterLink}
        >
          <Button size="medium" variant="text">
            Learn more
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
