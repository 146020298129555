import { useCallback, useState, useRef } from 'react';

// ----------------------------------------------------------------------

interface Listener {
  (value: boolean): void;
}

interface ReturnType {
  value: boolean;
  onTrue: () => void;
  onFalse: () => void;
  onToggle: () => void;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  addListener: (listener: Listener) => void;
  removeListener: (listener: Listener) => void;
}

export function useBoolean(defaultValue?: boolean): ReturnType {
  const [value, setValue] = useState(!!defaultValue);
  const listenersRef = useRef<Listener[]>([]);

  const notifyListeners = useCallback((newValue: boolean) => {
    listenersRef.current.forEach((listener) => listener(newValue));
  }, []);

  const modifiedSetValue = useCallback(
    (valueOrUpdater: React.SetStateAction<boolean>) => {
      setValue((prev) => {
        const newValue =
          typeof valueOrUpdater === 'function' ? valueOrUpdater(prev) : valueOrUpdater;
        notifyListeners(newValue);
        return newValue;
      });
    },
    [notifyListeners]
  );

  const onTrue = useCallback(() => {
    modifiedSetValue(true);
  }, [modifiedSetValue]);

  const onFalse = useCallback(() => {
    modifiedSetValue(false);
  }, [modifiedSetValue]);

  const onToggle = useCallback(() => {
    modifiedSetValue((prev) => !prev);
  }, [modifiedSetValue]);

  const addListener = useCallback((listener: Listener) => {
    listenersRef.current.push(listener);
  }, []);

  const removeListener = useCallback((listener: Listener) => {
    listenersRef.current = listenersRef.current.filter((l) => l !== listener);
  }, []);

  return {
    value,
    onTrue,
    onFalse,
    onToggle,
    setValue: modifiedSetValue,
    addListener,
    removeListener,
  };
}
