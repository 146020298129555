import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import config, { DataPointsConfig, prudentiaGenericDataPoints } from 'src/adrastia.config';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatUnits, parseUnits, commify } from '@ethersproject/units';
import { MAX_RATE } from 'src/constants/rate-controller';
import { Box } from '@mui/material';
import { useCall, useContractFunction, useEthers } from '@usedapp/core';
import { RateController } from 'typechain/adrastia-periphery';
import { useSnackbar } from 'notistack';
import {
  bigNumberMaxValidator,
  bigNumberMinValidator,
  bigNumberValidator,
} from 'src/forms/validation/big-number';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type ControllerManuallyPushRateDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  networkName: string;
  token: string;
  controller?: RateController;
  decimals: number;
  ratePrefix?: string;
  rateSuffix?: string;
  dataPoints?: DataPointsConfig;
};

const steps = ['Define parameters', 'Review and submit'];

export default function ControllerManuallyPushRateDialog({
  dialogOpen,
  networkName,
  token,
  controller,
  decimals,
  ratePrefix,
  rateSuffix,
  dataPoints,
}: ControllerManuallyPushRateDialogProps) {
  const theme = useTheme();

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const controllerFunction = useContractFunction(controller, 'manuallyPushRate');

  const chainId = config.chains[networkName!]?.chainId;

  const rateConfigCall = useCall(
    controller && {
      contract: controller,
      method: 'getConfig',
      args: [token],
    },
    {
      chainId: chainId,
    }
  );
  const rateConfig = rateConfigCall?.value?.[0];

  const { enqueueSnackbar } = useSnackbar();

  const rateLabel = dataPoints?.rate?.label ?? prudentiaGenericDataPoints.rate!.label;

  // Step handling
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [rate, setRate] = useState<BigNumber>();
  const [amount, setAmount] = useState<number>();

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          rate: Yup.mixed()
            .test(
              'bigNumber',
              rateLabel + ' should be a number with at most ' + decimals + ' decimal places.',
              bigNumberValidator(decimals)
            )
            .test('min', rateLabel + ' cannot be negative.', bigNumberMinValidator(0, decimals))
            .test(
              'max',
              rateLabel + ' cannot be greater than ' + formatUnits(MAX_RATE, decimals) + '.',
              bigNumberMaxValidator(MAX_RATE, decimals)
            )
            .notOneOf([''], rateLabel + ' is required.')
            .required(rateLabel + ' is required.'),
          amount: Yup.number()
            .typeError('Amount must be a number.')
            .min(1, 'Amount must be at least 1.')
            .test('decimal', 'Amount must be an integer.', (value) => Number.isInteger(value))
            .required('Amount is required.'),
        })
        .required(),
    [decimals]
  );
  const defaultValues = useMemo(
    () => ({
      rate: BigNumber.from(0),
      amount: 1,
    }),
    []
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    const rate = parseUnits(data.rate.toString(), decimals);
    const amount = data.amount;

    // Set the values
    setRate(rate);
    setAmount(amount);

    handleNext();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      controllerFunction.resetState();
      reset(defaultValues);
      setActiveStep(0);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && controllerFunction?.state?.status === 'Mining') {
      controllerFunction.resetState();

      dialogOpen.onFalse();
    }
  }, [controllerFunction?.state]);

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  const globalAlerts = (
    <>
      <Alert severity="info">
        {rateLabel}s are formatted to whole units with {decimals} decimals.
      </Alert>
    </>
  );

  const newRateOutsideBounds = rate?.lt(rateConfig?.min ?? 0) || rate?.gt(rateConfig?.max ?? 0);

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Manually push {rateLabel.toLowerCase()}</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box marginTop={theme.spacing(2)}>
          {activeStep === 0 ? (
            <FormProvider methods={formMethods} onSubmit={onSubmit}>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {globalAlerts}
                </Box>
                <RHFTextField
                  fullWidth
                  name="rate"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  label={rateLabel}
                  helperText={`The ${rateLabel.toLowerCase()} to push.`}
                  InputProps={{
                    startAdornment:
                      ratePrefix != null ? (
                        <InputAdornment position="start">{ratePrefix}</InputAdornment>
                      ) : undefined,
                    endAdornment: (
                      <>
                        {rateSuffix && <InputAdornment position="end">{rateSuffix}</InputAdornment>}
                        <Button
                          variant="text"
                          onClick={() => {
                            formMethods.setValue('rate', formatUnits(MAX_RATE, decimals));
                            formMethods.trigger('rate');
                          }}
                          tabIndex={-1}
                        >
                          Max
                        </Button>
                      </>
                    ),
                  }}
                />
                <RHFTextField
                  fullWidth
                  name="amount"
                  type="number"
                  margin="normal"
                  variant="outlined"
                  label="Amount"
                  helperText={`The amount of times to push the ${rateLabel.toLowerCase()} to the buffer.`}
                />
                {globalFormErrors && (
                  <Box marginTop={theme.spacing(2)}>
                    <Typography color="error" variant="caption">
                      {globalFormErrors.message}
                    </Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Next
                </Button>
              </DialogActions>
            </FormProvider>
          ) : (
            <>
              <DialogContent>
                <Box
                  display="flex"
                  flexDirection={'column'}
                  gap={theme.spacing(1)}
                  marginTop={theme.spacing(1)}
                  marginBottom={theme.spacing(2)}
                >
                  {newRateOutsideBounds && (
                    <Alert severity="error" variant="outlined">
                      The new {rateLabel.toLowerCase()} is outside the bounds of the controller.
                      Please ensure this is what you want.
                    </Alert>
                  )}
                  <Alert severity="warning" variant="outlined">
                    This {rateLabel.toLowerCase()} won't be subject to the rate limiting of the
                    controller.
                  </Alert>
                  {globalAlerts}
                </Box>
                <Table
                  size="small"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Raw value</TableCell>
                      <TableCell>Formatted value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>{rateLabel}</TableCell>
                      <TableCell>{rate?.toString()}</TableCell>
                      <TableCell>
                        {rate?.eq(MAX_RATE)
                          ? 'Max (uint64)'
                          : ratePrefix + commify(formatUnits(rate ?? 0, decimals)) + rateSuffix}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Amount</TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{amount}</TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleBack} variant="outlined" color="inherit">
                  Back
                </Button>
                {account == null ? (
                  <Button
                    onClick={() => {
                      activateBrowserWallet();
                    }}
                    variant="contained"
                  >
                    Connect
                  </Button>
                ) : walletChainId !== config.chains[networkName!]?.chainId ? (
                  <Button
                    onClick={async () => {
                      try {
                        switchNetwork(config.chains[networkName!]?.chainId ?? 1);
                      } catch (e) {
                        enqueueSnackbar('Failed to switch network. Please reconnect.', {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          autoHideDuration: 5000,
                        });

                        return;
                      }
                    }}
                    variant="contained"
                  >
                    Switch network
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (account === null || account === undefined) {
                        return;
                      }

                      if (walletChainId !== config.chains[networkName!]?.chainId) {
                        return;
                      }

                      if (walletChainId === config.chains[networkName!]?.chainId) {
                        try {
                          controllerFunction.send(token, rate!, rate!, amount!);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
