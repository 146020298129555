// theme
import palette from '../theme/palette';
// @type
import { ThemeColorPresets } from '../components/settings/type';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#E1BEE7',
    light: '#BA68C8',
    main: '#9C27B0',
    dark: '#7B1FA2',
    darker: '#4A148C',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#B2EBF2',
    light: '#4DD0E1',
    main: '#00BCD4',
    dark: '#0097A7',
    darker: '#006064',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#BBDEFB',
    light: '#64B5F6',
    main: '#2196F3',
    dark: '#1976D2',
    darker: '#0D47A1',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FFE0B2',
    light: '#FFB74D',
    main: '#FF9800',
    dark: '#F57C00',
    darker: '#E65100',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFCDD2',
    light: '#E57373',
    main: '#F44336',
    dark: '#D32F2F',
    darker: '#B71C1C',
    contrastText: '#fff',
  },
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];

export default function getColorPresets(presetsKey: ThemeColorPresets) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    default: defaultPreset,
  }[presetsKey];
}
