import { Box, Container, useTheme } from '@mui/material';
import Carousel, { useCarousel, CarouselDots, CarouselArrows } from '../carousel';
import HistoricalRatesItem from './historical-rates-item';
import { useResponsive } from 'src/hooks/use-responsive';

type Props = {
  rates: (number | undefined)[];
  selectedIndex?: number;
  nextIndex?: number;
  ratePrefix?: string;
  rateSuffix?: string;
};

function CarouselArrowsIfMany({
  children,
  carousel,
  showArrows,
}: {
  children: React.ReactNode;
  carousel: ReturnType<typeof useCarousel>;
  showArrows: boolean;
}) {
  if (!showArrows) {
    return <>{children}</>;
  }

  return (
    <CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev}>
      {children}
    </CarouselArrows>
  );
}

export default function HistoricalRatesQueue({
  rates,
  ratePrefix,
  rateSuffix,
  selectedIndex,
  nextIndex,
}: Props) {
  const theme = useTheme();

  const smDown = useResponsive('down', 'sm');
  const mdDown = useResponsive('down', 'md');
  const xlDown = useResponsive('down', 'xl');

  let slidesVisible = undefined;
  if (smDown) {
    slidesVisible = Math.min(1, rates.length);
  } else if (mdDown) {
    slidesVisible = Math.min(2, rates.length);
  } else if (xlDown) {
    slidesVisible = Math.min(3, rates.length);
  } else {
    slidesVisible = Math.min(5, rates.length);
  }

  const carousel = useCarousel({
    slidesToShow: slidesVisible,
    slidesToScroll: 1,
    ...CarouselDots(),
  });

  if (rates.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <Box>
        <CarouselArrowsIfMany carousel={carousel} showArrows={slidesVisible < rates.length}>
          <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
            {rates.map((rate, index) => (
              <Box
                key={index}
                sx={{
                  px: 2,
                  pt: { xs: 8, md: 10 },
                  mb: { xs: 8, md: 10 },
                }}
              >
                <HistoricalRatesItem
                  selectedIndex={selectedIndex}
                  nextIndex={nextIndex}
                  index={index}
                  rate={rate}
                  ratePrefix={ratePrefix}
                  rateSuffix={rateSuffix}
                />
              </Box>
            ))}
          </Carousel>
        </CarouselArrowsIfMany>
      </Box>
    </Container>
  );
}
