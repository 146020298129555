import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Contract } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { Box } from '@mui/material';
import { ChainId, useCall, useContractFunction, useEthers } from '@usedapp/core';
import { default as accessControlAbi } from '@openzeppelin-v4/contracts/build/contracts/AccessControlEnumerable.json';
import { Interface } from '@ethersproject/abi';
import { IAccessControl } from 'typechain/openzeppelin-v4';
import { useSnackbar } from 'notistack';
import { ROLES } from 'src/constants/roles';
import AddressDisplay from 'src/components/AddressDisplay';

const accessControlInterface = new Interface(accessControlAbi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type RenounceRoleDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  contractAddress: string;
  chainId: ChainId;
  role: string;
};

export default function RenounceRoleDialog({
  dialogOpen,
  contractAddress,
  chainId,
  role,
}: RenounceRoleDialogProps) {
  const contract = useMemo(() => {
    return new Contract(contractAddress, accessControlInterface) as IAccessControl;
  }, [contractAddress]);

  const theme = useTheme();

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const renounceRole = useContractFunction(contract, 'renounceRole');

  const { enqueueSnackbar } = useSnackbar();

  const adminRole = useCall(
    role && {
      contract: contract,
      method: 'getRoleAdmin',
      args: [role],
    },
    {
      chainId: chainId,
    }
  );
  const adminRoleLabel = useMemo(() => {
    if (adminRole?.value?.[0] == null) {
      return 'Loading...';
    }

    const matchedRole = ROLES[adminRole?.value?.[0]];
    if (matchedRole == null) {
      return 'Unsupported';
    }

    return matchedRole.name;
  }, [adminRole]);
  const supportedRole = adminRoleLabel !== 'Unsupported';

  const hasRoleCall = useCall(
    account && {
      contract: contract,
      method: 'hasRole',
      args: [role, account],
    },
    {
      chainId: chainId,
    }
  );
  const hasRole = {
    loading: hasRoleCall?.value?.[0] == null,
    value: hasRoleCall?.value?.[0],
  };

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      renounceRole.resetState();
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && renounceRole?.state?.status === 'Mining') {
      renounceRole.resetState();

      dialogOpen.onFalse();
    }
  }, [renounceRole?.state]);

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Renounce role</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection={'column'}
            gap={theme.spacing(1)}
            marginTop={theme.spacing(1)}
            marginBottom={theme.spacing(2)}
          >
            {!supportedRole ? (
              <Alert severity="error" variant="outlined">
                The role is not supported.
              </Alert>
            ) : (
              !hasRole.loading &&
              !hasRole.value && (
                <Alert severity="error" variant="outlined">
                  You do not have have this role.
                </Alert>
              )
            )}
          </Box>
          <Table
            size="small"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Raw value</TableCell>
                <TableCell>Formatted value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell>Role</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{ROLES[role]?.name}</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  {account == null ? (
                    <Typography color="textSecondary">Not connected</Typography>
                  ) : (
                    <AddressDisplay
                      address={account ?? ''}
                      chainId={chainId}
                      target="_blank"
                      resolveAddress
                      hideAddressIfResolved
                    />
                  )}
                </TableCell>
                <TableCell>{'<<<'}</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
            Cancel
          </Button>
          {account == null ? (
            <Button
              onClick={() => {
                activateBrowserWallet();
              }}
              variant="contained"
            >
              Connect
            </Button>
          ) : walletChainId !== chainId ? (
            <Button
              onClick={async () => {
                try {
                  switchNetwork(chainId ?? 1);
                } catch (e) {
                  enqueueSnackbar('Failed to switch network. Please reconnect.', {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    autoHideDuration: 5000,
                  });

                  return;
                }
              }}
              variant="contained"
            >
              Switch network
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (account === null || account === undefined) {
                  return;
                }

                if (walletChainId !== chainId) {
                  return;
                }

                if (walletChainId === chainId) {
                  try {
                    renounceRole.send(role!, account!);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              disabled={hasRole.loading || !hasRole.value || !supportedRole}
              variant="contained"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
}
