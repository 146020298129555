import { Theme } from '@mui/material/styles';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  return {
    MuiLink: {
      defaultProps: {
        color: isLight ? theme.palette.primary.main : theme.palette.primary.light,
        underline: 'hover',
      },
    },
  };
}
