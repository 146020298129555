import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Contract } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { Box } from '@mui/material';
import { ChainId, useCall, useContractFunction, useEthers } from '@usedapp/core';
import { default as accessControlAbi } from '@openzeppelin-v4/contracts/build/contracts/AccessControlEnumerable.json';
import { Interface } from '@ethersproject/abi';
import { IAccessControl } from 'typechain/openzeppelin-v4';
import { useSnackbar } from 'notistack';
import { ROLES } from 'src/constants/roles';
import AddressDisplay from 'src/components/AddressDisplay';

const accessControlInterface = new Interface(accessControlAbi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type RevokeRoleDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  contractAddress: string;
  chainId: ChainId;
  role: string;
  address: string;
};

export default function RevokeRoleDialog({
  dialogOpen,
  contractAddress,
  chainId,
  role,
  address,
}: RevokeRoleDialogProps) {
  const contract = useMemo(() => {
    return new Contract(contractAddress, accessControlInterface) as IAccessControl;
  }, [contractAddress]);

  const theme = useTheme();

  // Ethers and transaction handling
  const withEthers = useEthers();
  const switchNetwork = withEthers.switchNetwork;
  const walletChainId = withEthers.chainId;
  const activateBrowserWallet = withEthers.activateBrowserWallet;
  const account = withEthers.account;
  const revokeRole = useContractFunction(contract, 'revokeRole');

  const { enqueueSnackbar } = useSnackbar();

  const adminRole = useCall(
    role && {
      contract: contract,
      method: 'getRoleAdmin',
      args: [role],
    },
    {
      chainId: chainId,
    }
  );
  const adminRoleLabel = useMemo(() => {
    if (adminRole?.value?.[0] == null) {
      return 'Loading...';
    }

    const matchedRole = ROLES[adminRole?.value?.[0]];
    if (matchedRole == null) {
      return 'Unsupported';
    }

    return matchedRole.name;
  }, [adminRole]);
  const supportedRole = adminRoleLabel !== 'Unsupported';

  const hasAdminRoleCall = useCall(
    adminRole?.value?.[0] &&
      account && {
        contract: contract,
        method: 'hasRole',
        args: [adminRole?.value?.[0], account!],
      },
    {
      chainId: chainId,
    }
  );
  const hasAdminRole = {
    loading: hasAdminRoleCall?.value?.[0] == null,
    value: hasAdminRoleCall?.value?.[0],
  };

  const addressHasRoleCall = useCall(
    {
      contract: contract,
      method: 'hasRole',
      args: [role, address],
    },
    {
      chainId: chainId,
    }
  );
  const addressHasRole = {
    loading: addressHasRoleCall?.value?.[0] == null,
    value: addressHasRoleCall?.value?.[0],
  };

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      revokeRole.resetState();
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  useEffect(() => {
    if (dialogOpen.value === true && revokeRole?.state?.status === 'Mining') {
      revokeRole.resetState();

      dialogOpen.onFalse();
    }
  }, [revokeRole?.state]);

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Revoke role</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection={'column'}
            gap={theme.spacing(1)}
            marginTop={theme.spacing(1)}
            marginBottom={theme.spacing(2)}
          >
            {!supportedRole ? (
              <Alert severity="error" variant="outlined">
                The role is not supported.
              </Alert>
            ) : (
              !hasAdminRole.loading &&
              !hasAdminRole.value && (
                <Alert severity="error" variant="outlined">
                  You do not have the required admin role to revoke this role.
                </Alert>
              )
            )}
            {!addressHasRole.loading && !addressHasRole.value && (
              <Alert
                severity="warning"
                variant="outlined"
                sx={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                }}
              >
                The address doesn't have the role.
              </Alert>
            )}
          </Box>
          <Box
            marginTop={theme.spacing(1)}
            marginBottom={theme.spacing(2)}
            marginLeft={theme.spacing(2)}
          >
            <Typography variant="body2">
              <strong>Admin role</strong>: {adminRoleLabel}
            </Typography>
          </Box>
          <Table
            size="small"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Raw value</TableCell>
                <TableCell>Formatted value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell>Role</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{ROLES[role]?.name}</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  <AddressDisplay
                    address={address as string}
                    chainId={chainId}
                    target="_blank"
                    resolveAddress
                    hideAddressIfResolved
                  />
                </TableCell>
                <TableCell>{'<<<'}</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
            Cancel
          </Button>
          {account == null ? (
            <Button
              onClick={() => {
                activateBrowserWallet();
              }}
              variant="contained"
            >
              Connect
            </Button>
          ) : walletChainId !== chainId ? (
            <Button
              onClick={async () => {
                try {
                  switchNetwork(chainId ?? 1);
                } catch (e) {
                  enqueueSnackbar('Failed to switch network. Please reconnect.', {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    autoHideDuration: 5000,
                  });

                  return;
                }
              }}
              variant="contained"
            >
              Switch network
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (account === null || account === undefined) {
                  return;
                }

                if (walletChainId !== chainId) {
                  return;
                }

                if (walletChainId === chainId) {
                  try {
                    revokeRole.send(role!, address!);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              disabled={hasAdminRole.loading || !hasAdminRole.value || !supportedRole}
              variant="contained"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
}
