import { Network, WebSocketProvider } from '@ethersproject/providers';
import { WebSocketLike } from '@ethersproject/providers/lib/websocket-provider';

export default class StaticWebsocketProvider extends WebSocketProvider {
  private _staticNetwork: Network | undefined;

  private _currentWebSocket: WebSocket | undefined = undefined;

  private _parentOnclose: ((ev: CloseEvent) => void) | null = null;

  private _parentOnopen: ((ev: Event) => void) | null = null;

  private _isDestroyed = false;

  private _initialReconnectDelay = 1000; // Start with a 1 second delay

  private _reconnectDelay;

  private _maxReconnectDelay = 60000; // Maximum delay of 60 seconds

  constructor(url: string, network: Network | undefined, onOpenCallback?: () => void) {
    super(url, network);

    this._reconnectDelay = this._initialReconnectDelay;

    this._staticNetwork = network;

    this._currentWebSocket = this.websocket as WebSocket;

    this._parentOnclose = this._currentWebSocket.onclose;

    // Call reconnect when the websocket is closed
    this._currentWebSocket.onclose = (ev) => {
      this._wsReady = false;

      try {
        if (this._parentOnclose) {
          this._parentOnclose(ev);
        }
      } catch (e) {
        console.error('Error in websocket onclose callback:', e);
      }

      this.reconnect();
    };

    this._parentOnopen = this._currentWebSocket.onopen;

    // Reset the reconnect delay when the websocket is opened
    this._currentWebSocket.onopen = (ev) => {
      this._reconnectDelay = this._initialReconnectDelay;

      if (onOpenCallback) {
        onOpenCallback();
      }

      try {
        if (this._parentOnopen) {
          this._parentOnopen(ev);
        }
      } catch (e) {
        console.error('Error in websocket onopen callback:', e);
      }
    };
  }

  get websocket(): WebSocketLike {
    if (this._currentWebSocket == null) {
      return this._websocket;
    }

    return this._currentWebSocket;
  }

  reconnect(): void {
    if (this._isDestroyed) {
      return;
    }

    if (this._currentWebSocket == null) {
      console.error('No websocket to reconnect');

      return;
    }

    console.log(`Attempting to reconnect websocket in ${this._reconnectDelay} ms...`);

    setTimeout(() => {
      if (this._isDestroyed) {
        return;
      }

      const oldWebSocket = this._currentWebSocket;
      if (oldWebSocket == null) {
        console.log('No websocket to reconnect');

        return;
      }

      if (oldWebSocket.readyState !== WebSocket.CLOSED) {
        console.log('Old websocket already open. Attempting to close it.');

        try {
          oldWebSocket.close();
        } catch (e) {
          console.error('Error closing websocket:', e);

          return; // Avoid memory leak - don't create a new websocket if we can't close the old one
        }
      }

      this._currentWebSocket = new WebSocket(this.connection.url);

      this._currentWebSocket.onopen = oldWebSocket.onopen;
      this._currentWebSocket.onmessage = oldWebSocket.onmessage;
      this._currentWebSocket.onerror = oldWebSocket.onerror;
      this._currentWebSocket.onclose = oldWebSocket.onclose;
    }, this._reconnectDelay);

    // Increment the delay for the next attempt
    this._reconnectDelay = Math.min(this._maxReconnectDelay, this._reconnectDelay * 2);
  }

  detectNetwork(): Promise<Network> {
    if (this._staticNetwork == null) {
      return super.detectNetwork();
    }

    return Promise.resolve(this._staticNetwork);
  }

  async destroy(): Promise<void> {
    this._isDestroyed = true;

    await super.destroy();

    if (this._currentWebSocket != null) {
      if (this._currentWebSocket.readyState !== WebSocket.CLOSED) {
        try {
          this._currentWebSocket.close();
        } catch (e) {
          console.error('Error closing websocket:', e);
        }
      }

      this._currentWebSocket = undefined;
    }
  }
}
