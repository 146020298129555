import {
  Arbitrum,
  Avalanche,
  BSC,
  Boba,
  ChainId,
  Config,
  Fantom,
  Mainnet,
  Moonbeam,
  Optimism,
  Polygon,
  Base,
  Gnosis,
  MetamaskConnector,
  Linea,
  NodeUrls,
} from '@usedapp/core';
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector';
import {
  ArbitrumSepolia,
  Blast,
  Filecoin,
  Gravity,
  Hardhat,
  Lisk,
  MantaPacific,
  Mantle,
  Metis,
  Mode,
  PolygonZkEVM,
  Rootstock,
  Scroll,
  Sei,
  Taiko,
  ZkSync,
} from './custom-networks';
import { getBlockchainProvider, getPrimaryJsonRpcUrl } from './rpc-config';

// ----------------------------------------------------------------------

BSC.chainName = 'BNB Smart Chain';
Mainnet.chainName = 'Ethereum';

export const rpcUrls: NodeUrls = {
  [Polygon.chainId]: getBlockchainProvider(Polygon.chainId, 'client', process.env)!,
  [Optimism.chainId]: getBlockchainProvider(Optimism.chainId, 'client', process.env)!,
  [Arbitrum.chainId]: getBlockchainProvider(Arbitrum.chainId, 'client', process.env)!,
  [PolygonZkEVM.chainId]: getBlockchainProvider(PolygonZkEVM.chainId, 'client', process.env)!,
  [Mainnet.chainId]: getBlockchainProvider(Mainnet.chainId, 'client', process.env)!,
  [Moonbeam.chainId]: getBlockchainProvider(Moonbeam.chainId, 'client', process.env)!,
  [Boba.chainId]: getBlockchainProvider(Boba.chainId, 'client', process.env)!,
  [ZkSync.chainId]: getBlockchainProvider(ZkSync.chainId, 'client', process.env)!,
  [Filecoin.chainId]: getBlockchainProvider(Filecoin.chainId, 'client', process.env)!,
  [Rootstock.chainId]: getBlockchainProvider(Rootstock.chainId, 'client', process.env)!,
  [Scroll.chainId]: getBlockchainProvider(Scroll.chainId, 'client', process.env)!,
  [Base.chainId]: getBlockchainProvider(Base.chainId, 'client', process.env)!,
  [Gnosis.chainId]: getBlockchainProvider(Gnosis.chainId, 'client', process.env)!,
  [BSC.chainId]: getBlockchainProvider(BSC.chainId, 'client', process.env)!,
  [Metis.chainId]: getBlockchainProvider(Metis.chainId, 'client', process.env)!,
  [Avalanche.chainId]: getBlockchainProvider(Avalanche.chainId, 'client', process.env)!,
  [MantaPacific.chainId]: getBlockchainProvider(MantaPacific.chainId, 'client', process.env)!,
  [Blast.chainId]: getBlockchainProvider(Blast.chainId, 'client', process.env)!,
  [Mode.chainId]: getBlockchainProvider(Mode.chainId, 'client', process.env)!,
  [Linea.chainId]: getBlockchainProvider(Linea.chainId, 'client', process.env)!,
  [Taiko.chainId]: getBlockchainProvider(Taiko.chainId, 'client', process.env)!,
  [ArbitrumSepolia.chainId]: getBlockchainProvider(ArbitrumSepolia.chainId, 'client', process.env)!,
  [Sei.chainId]: getBlockchainProvider(Sei.chainId, 'client', process.env)!,
  [Mantle.chainId]: getBlockchainProvider(Mantle.chainId, 'client', process.env)!,
  [Lisk.chainId]: getBlockchainProvider(Lisk.chainId, 'client', process.env)!,
  [Gravity.chainId]: getBlockchainProvider(Gravity.chainId, 'client', process.env)!,
};

const networks = [
  Polygon,
  Arbitrum,
  Optimism,
  PolygonZkEVM,
  Moonbeam,
  Boba,
  ZkSync,
  Filecoin,
  Rootstock,
  Scroll,
  Mainnet,
  Base,
  Gnosis,
  BSC,
  Metis,
  Avalanche,
  MantaPacific,
  Blast,
  Mode,
  Linea,
  Taiko,
  Sei,
  Mantle,
  Lisk,
  ArbitrumSepolia,
  Gravity,
];

if (process.env.REACT_APP_ENV === 'development') {
  networks.push(Hardhat);
  rpcUrls[Hardhat.chainId] = 'http://localhost:8545';
}

export const WALLET_CONNECT_PROJECT_ID = '62a6ea713307a01d23b4330690e9fe2f';

export const web3Config: Config = {
  readOnlyUrls: rpcUrls,
  networks: networks,
  pollingInterval: 5000,
  multicallVersion: 2,
  multicallAddresses: {
    [Polygon.chainId]: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
    [Arbitrum.chainId]: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
    [Avalanche.chainId]: '0x29b6603D17B9D8f021EcB8845B6FD06E1Adf89DE',
    [BSC.chainId]: '0x8141e6150BeD021Eef98482bdf5C70d257146DCA',
    [Optimism.chainId]: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    [Fantom.chainId]: '0xDfb43D878Cf615A3D25b4227Cce375edAe9a6e9B',
    [Mainnet.chainId]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    [PolygonZkEVM.chainId]: '0x4dd2886836eB5966dd2F5a223182E7889CD7F8a6',
    [Moonbeam.chainId]: '0x4dd2886836eB5966dd2F5a223182E7889CD7F8a6',
    [Boba.chainId]: '0x4dd2886836eB5966dd2F5a223182E7889CD7F8a6',
    [ZkSync.chainId]: '0x77Fce0B11B6F342B5f7a68114A03cAbb808a77e5',
    [Filecoin.chainId]: '0x732f5baac411e427d130fed03c2e82a3e0d64d35',
    [Rootstock.chainId]: '0x996a9858cDfa45Ad68E47c9A30a7201E29c6a386',
    [Scroll.chainId]: '0x3b615B1AC55bc34e51a81D3dea67467F32bcb8C2',
    [Base.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Gnosis.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [BSC.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Metis.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [MantaPacific.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Blast.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Mode.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Linea.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Hardhat.chainId]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    [Taiko.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [ArbitrumSepolia.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Sei.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Mantle.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
    [Lisk.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [Gravity.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3
  },
  notifications: {
    checkInterval: 500,
    expirationPeriod: 0,
  },
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnectV2_ethereum: new WalletConnectV2Connector({
      projectId: WALLET_CONNECT_PROJECT_ID,
      chains: [Mainnet],
      rpcMap: {
        [Mainnet.chainId]: getPrimaryJsonRpcUrl(Mainnet.chainId, 'client', process.env)!,
      },
    }),
    walletConnectV2_polygon: new WalletConnectV2Connector({
      projectId: WALLET_CONNECT_PROJECT_ID,
      chains: [Polygon],
      rpcMap: {
        [Polygon.chainId]: getPrimaryJsonRpcUrl(Polygon.chainId, 'client', process.env)!,
      },
    }),
    walletConnectV2_optimism: new WalletConnectV2Connector({
      projectId: WALLET_CONNECT_PROJECT_ID,
      chains: [Optimism],
      rpcMap: {
        [Optimism.chainId]: getPrimaryJsonRpcUrl(Optimism.chainId, 'client', process.env)!,
      },
    }),
    walletConnectV2_arbitrumOne: new WalletConnectV2Connector({
      projectId: WALLET_CONNECT_PROJECT_ID,
      chains: [Arbitrum],
      rpcMap: {
        [Arbitrum.chainId]: getPrimaryJsonRpcUrl(Arbitrum.chainId, 'client', process.env)!,
      },
    }),
  },
};

export const getChainById = (chainId: ChainId) =>
  web3Config.networks?.find((network) => network.chainId === chainId);

export const getExplorerAddressLink = (address: string, chainId: ChainId): string | '' => {
  return getChainById(chainId)?.getExplorerAddressLink(address) || '';
};

export const getExplorerTransactionLink = (
  transactionHash: string,
  chainId: ChainId
): string | '' => {
  return getChainById(chainId)?.getExplorerTransactionLink(transactionHash) || '';
};
