import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  dark?: boolean;
}

export default function Logo({ disabledLink = false, dark = undefined, sx }: Props) {
  const settings = useSettings();

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      {!dark && settings.themeMode == 'light' ? (
        <img src="/logo/logomark-light.svg" width="100%" height="100%" alt="Adrastia Logo" />
      ) : (
        <img src="/logo/logomark-dark.svg" width="100%" height="100%" alt="Adrastia Logo" />
      )}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
