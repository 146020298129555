import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useBoolean } from 'src/hooks/use-boolean';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { addressValidator } from 'src/forms/validation/address';

type CurrentValues = {
  contract?: string;
  token?: string;
};

export type CopyConfigCallback = (value: { contract: string; token: string }) => void;

type CopyConfigDialogProps = {
  dialogOpen: ReturnType<typeof useBoolean>;
  callback: CopyConfigCallback;
  values?: CurrentValues;
};

export default function CopyConfigDialog({ dialogOpen, callback, values }: CopyConfigDialogProps) {
  const theme = useTheme();

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          contract: Yup.mixed()
            .test('address', 'Contract should be an EVM address.', addressValidator())
            .notOneOf([''], 'Contract is required.')
            .required('Contract is required.'),
          token: Yup.mixed()
            .test('address', 'Token should be an EVM address.', addressValidator())
            .notOneOf([''], 'Token is required.')
            .required('Token is required.'),
        })
        .required(),
    []
  );
  const defaultValues = useMemo(
    () => ({
      contract: values?.contract ?? '',
      token: values?.token ?? '',
    }),
    [values]
  );
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { reset, handleSubmit } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    // Convert the values to BigNumber
    callback({
      contract: data.contract as string,
      token: data.token as string,
    });

    dialogOpen.onFalse();
  });

  // Define a listener function that does something when the value changes
  const handleDialogOpen = (open: boolean) => {
    // Reset the form when the dialog opens
    if (open) {
      reset(defaultValues);
    }
  };

  useEffect(() => {
    // Attach the listener when the component mounts
    dialogOpen.addListener(handleDialogOpen);

    // Return a cleanup function to remove the listener when the component unmounts
    return () => dialogOpen.removeListener(handleDialogOpen);
  }, [dialogOpen]); // Dependencies list ensures the effect hook runs only once

  const globalFormErrors = (formMethods.formState.errors as any)[''];

  return (
    <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Copy config</DialogTitle>

      <Box sx={{ width: '100%' }} paddingX={theme.spacing(2)}>
        <FormProvider methods={formMethods} onSubmit={onSubmit}>
          <DialogContent>
            <RHFTextField
              fullWidth
              name="contract"
              type="text"
              margin="normal"
              variant="outlined"
              label="Contract"
              helperText={`The contract address to copy the config from.`}
            />
            <RHFTextField
              fullWidth
              name="token"
              type="text"
              margin="normal"
              variant="outlined"
              label="Token"
              helperText={`The token address that contains the config to be copied.`}
            />
            {globalFormErrors && (
              <Box marginTop={theme.spacing(2)}>
                <Typography color="error" variant="caption">
                  {globalFormErrors.message}
                </Typography>
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={dialogOpen.onFalse} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Copy
            </Button>
          </DialogActions>
        </FormProvider>
      </Box>
    </Dialog>
  );
}
